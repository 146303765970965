import { Component, computed, input } from '@angular/core';
import { CategoryColumnSetting, CategoryItem, ColumnDefinition, ColumnType } from '../../data/categories';
import { FieldHelper } from '../../utils/field-helper';
import { NgIf } from '@angular/common';
import { MarkdownComponent } from 'ngx-markdown';
@Component({
    selector: 'dir-category-tag',
    templateUrl: './category-tag.component.html',
    styleUrls: ['./category-tag.component.scss'],
    imports: [NgIf, MarkdownComponent]
})
export class CategoryTagComponent {
    columnSetting = input<CategoryColumnSetting|null>(null);
    columnDefinitions = input<ColumnDefinition[]>([]);
    categoryItem = input<CategoryItem|null>(null);
    columnType = ColumnType;
    columnDefinition = computed<ColumnDefinition|null>(() => {
        if (this.columnSetting() && this.columnDefinitions().length > 0) {
            return this.columnDefinitions().find((x) => x.name === this.columnSetting().originalName);
        }
        return null;
    });
    itemValue = computed<string>(() => {
        if (
            this.categoryItem()?.columnValues &&
            this.columnSetting() &&
            this.columnDefinitions().findIndex((x) => x.name === this.columnSetting()?.originalName) !== -1
        ) {
            return this.categoryItem().columnValues[this.columnSetting().originalName];
        }
        return '';
    });
    managerCount = computed<number>(() => {
        return FieldHelper.getManagerCount(this.categoryItem());
    });
    showValue = computed<boolean>(() => {
        if (this.columnDefinition() == null) {
            return false;
        }
        if (
            this.columnDefinition().type === ColumnType.ItemCount ||
            this.columnDefinition().type == ColumnType.ManagerCount
        ) {
            return true;
        }

        if (this.columnDefinition().type === ColumnType.String) {
            return this.itemValue() !== '';
        }

        return false;
    });
}
