import { Component, computed, OnDestroy, OnInit, signal } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, filter, Observable, Subject, takeUntil } from 'rxjs';
import { SubscriptionStatus } from '../../auth/auth.models';
import * as fromRoot from '../../reducers';
import { ChatHelper } from './chat-helper';
import * as uiActions from '../../ui/ui.actions';
import * as availabilityActions from '../../userAvailability/availability.actions';
import { DirectoryUser } from '../../data/data.models';
import { UserAvailability } from '../../userAvailability/availability.models';
import { UISetting, UISettingsService } from '../../services/ui-settings.service';
import { FeatureService } from '../../shared/services/feature.service';
import { Feature } from '../../shared/components/features/features.models';
import { uiFeature } from 'src/app/ui/ui.reducer';
import { settingsFeature } from 'src/app/settings/settings.reducer';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { UserPresenceComponent } from '../../userAvailability/components/user-presence.component';
import { TextComponent } from './text.component';
import { UserAvailabilityStripComponent } from '../../userAvailability/components/user-availability-strip.component';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'dir-selected-users',
    templateUrl: './selected-users.component.html',
    styleUrls: ['./selected-users.component.scss'],
    imports: [NgIf, NgFor, RouterLink, UserPresenceComponent, TextComponent, UserAvailabilityStripComponent, AsyncPipe],
    animations: [
        trigger('animate', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('200ms ease-out', style({ height: '*', opacity: 1 }))
            ]),
            transition(':leave', [animate('200ms ease-out', style({ height: 0, opacity: 0 }))])
        ])
    ]
})
export class SelectedUsersComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    isEnabled = false;
    isTeams = false;
    subscriptionStatus: SubscriptionStatus = SubscriptionStatus.Subscribed;
    subscriptionStatus$: Observable<SubscriptionStatus>;
    isOutlook = false;
    chatHelper: ChatHelper;

    selectedUsers = signal<DirectoryUser[]>([]);
    names = computed<string>(() => {
        if (this.selectedUsers().length === 0) {
            return '';
        }
        return this.selectedUsers()
            .map((u) => u.displayName)
            .join(', ');
    });
    userAvailability: UserAvailability[] = [];

    dayModifier = 0;

    showAvailability = false;
    calendarFeatureConsented = false;
    isSchedueHidden$ = this.store.pipe(select(settingsFeature.selectIsScheduleHidden));
    constructor(private store: Store, private UISettings: UISettingsService, private featureService: FeatureService) {}

    ngOnInit(): void {
        this.subscriptionStatus$ = this.store.pipe(select(fromRoot.selectSubscriptionStatus));
        const isDemo$ = this.store.pipe(select(fromRoot.selectIsDemo));
        const isTeams$ = this.store.pipe(select(fromRoot.selectIsTeams));
        const teamsEnvironment$ = this.store.pipe(select(fromRoot.selectTeamsEnvironment));

        this.showAvailability = this.UISettings.getBoolValue(UISetting.Availability_View);

        combineLatest([this.subscriptionStatus$, isDemo$, isTeams$, teamsEnvironment$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([status, isDemo, isTeams, teamsEnv]) => {
                this.subscriptionStatus = status;
                this.isTeams = isTeams ? isTeams : false;
                this.isOutlook = teamsEnv?.isOutlook;
                this.isEnabled =
                    !isDemo && !(status === SubscriptionStatus.Expired || status === SubscriptionStatus.Lapsed);
            });

        combineLatest([
            this.featureService.hasFeature(Feature.UserAvailability),
            this.store.pipe(
                select(uiFeature.selectSelectedUsers),
                filter((x) => x != null)
            )
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([isCalendarAvailable, users]) => {
                this.selectedUsers.set(users);
                if (users.length > 0 && isCalendarAvailable) {
                    this.store.dispatch(
                        availabilityActions.loadUserSchedule({
                            mail: users.map((u) => u.mail),
                            dayModifier: this.dayModifier,
                            compareWithMe: false
                        })
                    );
                }
            });

        this.store.pipe(takeUntil(this.unsubscribe$), select(fromRoot.selectUserAvailability)).subscribe((a) => {
            this.userAvailability = a;
        });

        this.featureService
            .hasFeature(Feature.UserAvailability)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((hasFeature) => {
                this.calendarFeatureConsented = hasFeature;
            });
    }

    getUserAvailability(user: DirectoryUser): string {
        const a = this.userAvailability.find((a) => a.Mail === user.mail);
        return a ? a.Availability : '';
    }

    getAvailability(user: DirectoryUser): UserAvailability {
        const a = this.userAvailability.find((a) => a.Mail === user.mail);
        return a;
    }

    getAvailabilityDescription(): string {
        let result = '';
        switch (this.dayModifier) {
            case -1:
                result = 'Yesterday';
                break;
            case 0:
                result = 'Today';
                break;
            case 1:
                result = 'Tomorrow';
                break;
            default:
                result = new Date(Date.now() + this.dayModifier * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
                break;
        }
        return `${result}`;
    }

    onAvailability(): void {
        this.showAvailability = !this.showAvailability;
        this.UISettings.setBoolValue(UISetting.Availability_View, this.showAvailability);
    }

    onClear(): void {
        this.store.dispatch(uiActions.clearSelectedUsers());
    }

    onNextDay(d: number) {
        this.dayModifier = this.dayModifier + d;
        this.store.dispatch(
            availabilityActions.loadUserSchedule({
                mail: this.selectedUsers().map((u) => u.mail),
                dayModifier: this.dayModifier,
                compareWithMe: false
            })
        );
    }

    getComparision(): string {
        const maxLength = this.userAvailability.reduce((max, current) => {
            const currentLength = current.Availability ? current.Availability.length : 0;
            return Math.max(max, currentLength);
        }, 0);

        const sumArr: number[] = Array(maxLength).fill(0);

        for (const ua of this.userAvailability) {
            if (ua && ua.Availability) {
                const availabilityArr = ua.Availability.split('').map(Number);
                for (let i = 0; i < maxLength; i++) {
                    sumArr[i] += availabilityArr[i] || 0;
                    if (sumArr[i] > 0) sumArr[i] = 8;
                }
            }
        }
        return sumArr.join('');
    }

    isChecked(user: DirectoryUser): boolean {
        return this.selectedUsers().findIndex((u) => u.id === user.id) > -1;
    }

    onSelect($event, user: DirectoryUser) {
        $event.stopPropagation();
        this.store.dispatch(uiActions.selectDeselectUser({ user }));
    }

    onGroupChat() {
        const chatHelper = new ChatHelper('', this.isTeams);
        const url = chatHelper.onGroupChat(
            '',
            this.selectedUsers().map((u) => u.mail)
        );
        if (url && url.length > 0) {
            this.store.dispatch(uiActions.openDeepLink({ url }));
        }
    }

    onGroupCall() {
        const chatHelper = new ChatHelper('', this.isTeams);
        const url = chatHelper.onCall(this.selectedUsers().map((u) => u.mail));
        if (url && url.length > 0) {
            this.store.dispatch(uiActions.openDeepLink({ url }));
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
