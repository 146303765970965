import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageChangedEvent, PaginationComponent } from 'ngx-bootstrap/pagination';
import { DefaultDirectoryUserColumnSettings, UserColumnSettings } from 'src/app/data/categories';
import {
    CategorySearchResults,
    DirectoryUser,
    DisplayedUsersGroup,
    GroupByField,
    SortField,
    SortOrder,
    UsersViewMode
} from '../../data/data.models';
import { DataSourceType } from '../../services/data-service.factory';
import { Store } from '@ngrx/store';
import * as dataActions from '../../data/data.actions';
import { NgIf, NgFor, NgClass, SlicePipe } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { ToolbarComponent } from './toolbar.component';
import { SelectedUsersComponent } from './selected-users.component';
import { UsersTableComponent } from './users-table.component';
import { CalendarComponent } from './calendar/calendar.component';
import { UsersCardsComponent } from './users-cards.component';
import { AlphabeticFilterComponent } from './alphabetic-filter.component';
import { HighlightSearchPipe } from '../../shared/pipes/highlight-search.pipe';
import { UsersChartComponent } from '../users-chart.component';

export const listAnimation = trigger('listAnimation', [
    transition(':increment', [
        query(
            ':enter',
            [
                style({ opacity: 0, height: 0 }),
                stagger(10, [animate('50ms ease-out', style({ opacity: 1, height: '*' }))])
            ],
            { optional: true }
        )
    ]),
    transition(':decrement', [
        query(':leave', [stagger(10, [animate('50ms ease-out', style({ opacity: 0, height: 0 }))])])
    ])
]);

@Component({
    selector: 'dir-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    //changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('list', [transition('* => *', [query('@usersEnterLeave', stagger(50, animateChild()))])]),
        trigger('usersEnterLeave', [
            transition(':enter', [
                query(
                    '.user-box',
                    [
                        style({ opacity: 0, transform: 'translateX(-100px)' }),
                        stagger(30, [animate('200ms ease-out', style({ opacity: 1, transform: 'none' }))])
                    ],
                    { optional: true }
                )
            ])
        ]),
        listAnimation
    ],
    imports: [
        NgIf,
        NgFor,
        RouterLink,
        NgClass,
        ToolbarComponent,
        SelectedUsersComponent,
        UsersTableComponent,
        CalendarComponent,
        UsersCardsComponent,
        PaginationComponent,
        AlphabeticFilterComponent,
        SlicePipe,
        HighlightSearchPipe,
        UsersChartComponent
    ]
})
export class UsersComponent {
    @Input() totalCount = 0;
    @Input() pageSize = 10;
    @Input() users: DirectoryUser[] = [];
    @Input() usersGroups: DisplayedUsersGroup[] = [];
    @Input() categorySearchResults: CategorySearchResults[] = [];
    @Input() dataSourceType: DataSourceType = 2;
    @Input() searchString = '';
    @Input() viewMode: UsersViewMode = UsersViewMode.Cards;
    @Input() currentPage = 1;
    @Input() sortField: SortField = 'firstName';
    @Input() sortOrder = SortOrder.Asc;
    @Input() groupBy: GroupByField = null;
    @Input() userColumnSettings: UserColumnSettings = DefaultDirectoryUserColumnSettings;
    @Input() favorites: string[] = [];
    @Input() isCalendarHidden = false;
    @Input() filterLetter = '';
    @Input() hasLoadedDataFromDb = false;
    @Output() setPageNumber = new EventEmitter<number>();
    @Output() setPageSize = new EventEmitter<number>();
    @Output() setViewMode = new EventEmitter<UsersViewMode>();
    @Output() setSortField = new EventEmitter<SortField>();
    @Output() setSortOrder = new EventEmitter<SortOrder>();
    @Output() setGroupBy = new EventEmitter<GroupByField>();
    @Output() clearFilterLetter = new EventEmitter<void>();

    usersViewMode = UsersViewMode;
    maxPages = 10;
    expandedCategories: number[] = [];

    constructor(private store: Store, private router: Router) {}

    setPage(event: PageChangedEvent) {
        setTimeout(() => this.setPageNumber.emit(event.page));
    }

    expandCategory(category: number) {
        this.expandedCategories.push(category);
        return false;
    }

    collapseCategory(category: number) {
        this.expandedCategories = this.expandedCategories.filter((c) => c !== category);
        return false;
    }

    onClearFilterLetter($event: MouseEvent) {
        $event.preventDefault();
        this.clearFilterLetter.emit();
    }

    onClearSearch($event: MouseEvent) {
        $event.preventDefault();
        this.store.dispatch(dataActions.updateSearchString({ searchString: '' }));
    }

    navigateToUser(userId: string) {
        console.log('navigateToUser', userId);
        //console.log($event.target);
        // $event.stopPropagation();
        // $event.preventDefault();
        if (this.router.getCurrentNavigation() === null) {
            this.router.navigate([], { queryParams: { userId: userId } });
        }
        return false;
    }
}
