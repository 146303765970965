import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { select, Store } from '@ngrx/store';
import {
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    Observable,
    Subject,
    take,
    takeUntil,
    withLatestFrom
} from 'rxjs';
import { ViewType } from '../app.models';
import { Category } from '../data/categories';
import * as dataActions from '../data/data.actions';
import {
    CategoryChosenSortField,
    CategoryChosenSortOrder,
    CategoryChosenViewMode,
    CategorySearchResults,
    DirModals,
    GroupByField,
    SortField,
    SortOrder,
    UsersViewMode
} from '../data/data.models';
import * as fromRoot from '../reducers';
import * as settingsActions from '../settings/settings.actions';
import * as microsoftTeams from '@microsoft/teams-js';
import { TeamsEnvironment } from '../auth/auth.models';
import * as AuthActions from '../auth/auth.actions';
import { uiFeature } from '../ui/ui.reducer';
import { FeatureService } from '../shared/services/feature.service';
import { Feature, FeatureDefinitions } from '../shared/components/features/features.models';
import { DemoHelper } from '../utils/demo-helper';
import { FrameCommunicationService } from '../services/frame-communication.service';
import { settingsFeature } from '../settings/settings.reducer';
import { InformationBannerComponent } from './information-banner.component';
import { NgIf, AsyncPipe, DecimalPipe } from '@angular/common';
import { CategoryImageComponent } from '../components/categories/category-image.component';
import { HeaderComponent } from '../shared/components/header.component';
import { AsideComponent } from '../shared/components/aside.component';
import { AdminButtonComponent } from '../shared/components/admin-button.component';
import { RouterLink } from '@angular/router';
import { ProgressbarComponent } from 'ngx-bootstrap/progressbar';
import { CategoryItemsComponent } from '../components/categories/category-items.component';
import { UsersComponent } from '../users/components/users.component';
import { FooterComponent } from '../shared/components/footer.component';
import { ConsentComponent } from '../components/consent.component';
import { GraphPictureComponent } from '../shared/components/graph-picture.component';
import {
    BorderStyle,
    BoxLayoutType,
    BoxStyleType,
    BoxType,
    ChartMetaInfo,
    ChartNode,
    ChartViewComponent,
    DataSourceType,
    DefaultChartView,
    SeparationSettings
} from '@teamimprover/ti-orgchart';

export const slideUpDown = trigger('slideUpDown', [
    transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('0.3s ease-out', style({ height: '*', opacity: 1 }))
    ]),
    transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('0.3s ease-out', style({ height: 0, opacity: 0 }))
    ])
]);

@Component({
    selector: 'dir-directory',
    templateUrl: './directory.component.html',
    styleUrls: ['./directory.component.scss'],
    animations: [slideUpDown],
    imports: [
        InformationBannerComponent,
        NgIf,
        CategoryImageComponent,
        HeaderComponent,
        AsideComponent,
        AdminButtonComponent,
        RouterLink,
        ProgressbarComponent,
        CategoryItemsComponent,
        UsersComponent,
        FooterComponent,
        ConsentComponent,
        AsyncPipe,
        DecimalPipe
    ]
})
export class DirectoryComponent implements OnInit, OnDestroy {
    searchInput = new FormControl<string>('');
    progress$ = this.store.pipe(select(fromRoot.selectProgress));
    users$ = this.store.pipe(select(fromRoot.selectUsersSorted));
    usersPage$ = this.store.pipe(select(fromRoot.selectActivePageOfUsers));
    usersPageSize$ = this.store.pipe(select(fromRoot.selectUsersPageSize));
    usersPageNumber$ = this.store.pipe(select(fromRoot.selectUsersPageNumber));
    sortField$ = this.store.pipe(select(fromRoot.selectSortField));
    sortOrder$ = this.store.pipe(select(fromRoot.selectSortOrder));
    groupBy$ = this.store.pipe(select(fromRoot.selectGroupBy));
    category$: Observable<Category>;
    categories$ = this.store.pipe(select(fromRoot.selectCategories));
    dataSourceType$ = this.store.pipe(select(fromRoot.selectDataSourceType));
    activeCategory$ = this.store.pipe(select(fromRoot.selectActiveCategory));
    activeCategoryItem$ = this.store.pipe(select(fromRoot.selectActiveCategoryItem));
    activeCategoryItemsSorted$ = this.store.pipe(select(fromRoot.selectActiveCategoryItemsSorted));
    searchString$ = this.store.pipe(select(fromRoot.selectSearchString));
    searchResults$ = this.store.pipe(select(fromRoot.selectSearchResults));
    viewTypes = ViewType;
    activeViewType: ViewType = ViewType.People;
    activeViewType$ = this.store.pipe(select(fromRoot.selectActiveView));
    usersViewMode$ = this.store.pipe(select(fromRoot.selectUsersViewMode));
    categoryItemsViewModes$ = this.store.pipe(select(fromRoot.selectCategoryItemsViewModes));
    categoryItemsSortFields$ = this.store.pipe(select(fromRoot.selectCategorySortFields));
    categoryItemsSortOrders$ = this.store.pipe(select(fromRoot.selectCategorySortOrders));
    displayedUsers$ = this.store.pipe(select(fromRoot.selectDisplayedUsers));
    displayedCount$ = this.store.pipe(select(fromRoot.selectDisplayedCount));
    usersGroups$ = this.store.pipe(select(fromRoot.selectUsersGroups));
    displayedUsersGroups$ = this.store.pipe(select(fromRoot.selectDisplayedUsersGroups));
    categorySearchResults$: Observable<CategorySearchResults[]>;
    userColumnSettings$ = this.store.pipe(select(fromRoot.selectUserColumnSettings));
    favorites$ = this.store.pipe(select(fromRoot.selectFavorites));
    isDemo$ = this.store.pipe(select(fromRoot.selectIsDemo));
    hasLoadDataFromDbFailed$ = this.store.pipe(select(fromRoot.selectHasLoadDataFromDbFailed));
    isFromToc$ = this.store.pipe(select(fromRoot.selectIsFromToc));
    numberOfBannersShowing = 0;
    showAsideComponent = true;
    editModeInSharePoint$: Observable<boolean> = this.store.pipe(select(uiFeature.selectEditModeInSharePoint));
    isOnSharePointStartingUrl$: Observable<boolean> = this.store.pipe(select(fromRoot.selectIsOnSharePointStartingUrl));
    filterLetter$: Observable<string> = this.store.pipe(select(fromRoot.selectFilterLetter));
    isCalendarHidden$ = this.store.pipe(select(settingsFeature.selectIsCalendarHidden));
    hasFailedToLoadTenant$ = this.store.pipe(select(fromRoot.selectHasFailedToLoadTenant));
    hasLoadedDataFromDb$ = this.store.pipe(select(fromRoot.selectHasLoadedDataFromDb));
    chartMeta: ChartMetaInfo = {
        availableFields: ['DisplayName'],
        referenceDataColumns: ['DisplayName'],
        chartId: 'Directory',
        chartName: 'Directory',
        contextMenuItems: [],
        dataSourceName: 'Directory',
        dataSourceType: DataSourceType.WAADDataSource,
        pictureSource: 0,
        isPublic: true,
        isPromoted: true,
        isPrivate: false,
        isAdmin: true,
        isPlanner: false,
        isHiddenInUi: false,
        usingTeams: false,
        isWaadTenant: false,
        isDemo: false,
        isDeleted: false,
        hideDataSourceLink: false,
        isSharePointBasedChart: false,
        sharePointLink: '',
        sharePointLinkToPosition: '',
        sharePointListUrl: '',
        sharePointLinkToRedirect: '',
        hideToolBarButtons: '',
        topUserId: '',
        currentUserId: '',
        inFullScreenMode: false,
        columnSettings: [
            {
                OriginalName: 'DisplayName',
                Id: 1,
                IsBold: false,
                IsInfo: true,
                IsDisplayed: true,
                IsItalic: false,
                IsStaff: false,
                IsStrikeout: false,
                CalculatedNewName: 'DisplayName',
                Order: 1,
                FontColour: '#000000',
                FontName: 'Arial',
                FontSize: '10',
                IsUnderlined: false,
                IsSearched: true,
                IsParentKey: false,
                IsPrimaryKey: false,
                LengthLimit: 20,
                OutputFormat: '',
                InformationOrder: 1
            }
        ],
        SmartDraw: false,
        chartView: DefaultChartView.Chart, // Replace with an appropriate enum value
        NameColumn: 'DisplayName',
        PhoneColumn: '',
        EmailColumn: '',
        CompanyNameColumn: '',
        CityColumn: '',
        OfficeLocationColumn: '',
        CountryColumn: '',
        JobTitleColumn: '',
        GroupColumn: '',
        PictureColumn: '',
        UniqueId: 'unique-id-123',
        ManagerId: 'manager-id-123',
        CustomLayout: '',
        SortColumns: [],
        BrandColor: '#000000',
        isAuditTrailEnabled: false,
        isStatusTrackerEnabled: false,
        isMappingsEnabled: false,
        excludeNonChartUsers: false,
        isUserPresenceEnabled: false,
        createdByObjectId: 'creator-id-123',
        TenantAccountId: 'tenant-id-123',
        ChartUserOptionBookmarkId: 0,
        ruleKeys: [],
        filters: [],
        ShowNavigationArrows: false,
        MaximumDepth: 3,
        SortOrder: 'asc',
        LineColour: 'blue',
        GridWidth: 20,
        StackLowerLevel: false,
        ShowDottedLines: true,
        CustomCss: '',
        InformationText: '',
        additions: [],
        VacantCustomKeyLabel: '',
        TempCustomKeyLabel: '',
        DepartmentCustomKeyLabel: '',
        VacantIsDisplayedInKey: false,
        TempIsDisplayedInKey: false,
        DepartmentIsDisplayedInKey: false,
        Templates: {
            Standard: {
                TemplateId: 1,
                TemplateName: 'Standard',
                BoxLayout: BoxLayoutType.PhotoLeft,
                BoxWidth: 180,
                BoxHeight: 100,
                CustomLayout: '',
                BoxStyle: BoxStyleType.Hatched,
                BoxColor: 'gold',
                BorderColor: '#000000',
                BoxSize: 1,
                AccentColor: '#0000FF',
                BorderStyle: BorderStyle.Solid,
                TextColor: '#000000',
                AnnotationColor: '#000000'
            }
        },
        inEvaluationMode: false
    };
    separationSettings: SeparationSettings = {
        sibling: 20,
        stack: 20,
        level: 20
    };
    chartNodes: ChartNode[] = [];

    //Has the user consented to the directory feature
    isDirectoryAvailable$: Observable<boolean>;
    pictureComponentProvider = (objectId: string) => ({
        component: GraphPictureComponent,
        inputs: { id: objectId, size: 90 }
    });

    private readonly unsubscribe$ = new Subject<void>();
    constructor(private store: Store, private featureService: FeatureService) {}

    ngOnInit(): void {
        this.displayedUsers$.pipe(takeUntil(this.unsubscribe$)).subscribe((users) => {
            const chartNodes = users.map((u) => {
                const node: ChartNode = {
                    DisplayName: u.displayName,
                    UniqueId: u.id,
                    ManagerId: u.managerId,
                    Title: u.jobTitle,
                    JobTitle: u.jobTitle,
                    IsDepartment: false,
                    BoxType: BoxType.Standard,
                    Height: 100,
                    Width: 200,
                    TOC_Impacts: [],
                    TOC_Fields: {},
                    MultiplePositions: [],
                    Coordinators: []
                };
                return node;
            });
            if (chartNodes.length === 0) {
                return;
            }
            console.log(chartNodes);
            this.chartNodes = chartNodes;
        });
        this.featureService.refreshAvailableScopes(FeatureDefinitions.Directory.scopes);
        this.isDirectoryAvailable$ = combineLatest([
            this.store.pipe(select(fromRoot.selectHasFailedToGetDirectoryData)),
            this.featureService.hasFeature(Feature.Directory),
            this.isDemo$
        ]).pipe(
            takeUntil(this.unsubscribe$),
            map(([hasFailedToGetDirectoryData, hasFeature, isDemo]) => {
                return !hasFailedToGetDirectoryData || hasFeature || isDemo;
            }),
            distinctUntilChanged()
        );

        combineLatest([
            this.store.pipe(select(settingsFeature.selectHasProcessedSettings)),
            this.store.pipe(select(settingsFeature.selectHasLoadedSettingsFromServer)),
            this.store.pipe(select(settingsFeature.selectHasFailedToLoadSettingsFromServer)),
            this.store.pipe(
                takeUntil(this.unsubscribe$),
                select(fromRoot.selectTenantAccount),
                filter((x) => x != null),
                distinctUntilChanged((x, y) => x.id === y.id)
            ),
            this.hasLoadedDataFromDb$
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                ([
                    hasProcessedSettings,
                    hasLoadedSettingsFromServer,
                    hasFailedToLoadSettingsFromServer,
                    user,
                    hasLoadedDataFromDb
                ]) => {
                    //, isDirectoryAvailable]) => {
                    // console.log(
                    //     'directory component: settings.hasProcessedSettings, user, hasLoadedDataFromDb, isDirectoryAvailable',
                    //     hasProcessedSettings,
                    //     user,
                    //     hasLoadedDataFromDb
                    //     //isDirectoryAvailable
                    // );
                    if (!hasLoadedDataFromDb && hasProcessedSettings) {
                        // && isDirectoryAvailable) {
                        if (DemoHelper.IsRebuildRequired()) {
                            this.store.dispatch(dataActions.rebuildCategoriesFromDb());
                            DemoHelper.ClearRebuildRequired();
                        } else {
                            this.store.dispatch(dataActions.loadDataFromDbDirectory());
                        }
                    }
                    if (!hasLoadedSettingsFromServer && !hasFailedToLoadSettingsFromServer) {
                        this.store.dispatch(settingsActions.loadSettings());
                    }
                }
            );
        this.activeViewType$.pipe(takeUntil(this.unsubscribe$)).subscribe((view) => {
            this.activeViewType = view;
        });
        this.store
            .pipe(
                takeUntil(this.unsubscribe$),
                select(fromRoot.selectSelectedPersonId),
                withLatestFrom(this.store.pipe(select(fromRoot.selectOpenedModalNames)))
            )
            .subscribe(([userId, openedModalNames]) => {
                //don't open again if there's one already open, it will handle the change
                if (userId != null && openedModalNames.length === 0) {
                    this.store.dispatch(dataActions.dialogShow({ dialog: DirModals.PersonDetails }));
                }
            });

        this.initTeams();

        //if users view mode is calendar and isCalendarHidden is true, switch to People view
        combineLatest([this.usersViewMode$, this.isCalendarHidden$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([viewMode, isCalendarHidden]) => {
                if (viewMode === UsersViewMode.Calendar && isCalendarHidden) {
                    this.store.dispatch(dataActions.setUsersViewMode({ viewMode: UsersViewMode.Cards }));
                }
            });
    }

    initTeams() {
        this.store.pipe(select(fromRoot.selectIsTeams)).subscribe((isTeams) => {
            if (isTeams) {
                console.log('directory.component.ts : Init Teams');
                microsoftTeams.app.initialize().then(
                    () => {
                        //microsoftTeams.app.notifySuccess();
                        console.log('directory.component.ts : Notified.  Getting Context');
                        //Need the context so that we can tell which Teams mode we are running in.
                        microsoftTeams.app
                            .getContext()
                            .then((context) => {
                                console.log('directory.component.ts : Got Context', context);

                                const channel: boolean =
                                    (context.channel != undefined && context.channel != null) ||
                                    (context.chat != undefined && context.chat != null);
                                const mobile: boolean =
                                    context.app.host.clientType == microsoftTeams.HostClientType.android ||
                                    context.app.host.clientType == microsoftTeams.HostClientType.ios;

                                const te: TeamsEnvironment = {
                                    isTeams: true,
                                    isTeamsChannel: channel,
                                    isTeamsMobile: mobile,
                                    teamsTabUrl: null,
                                    isOutlook: context?.app.host.name.toLocaleLowerCase().indexOf('outlook') != -1
                                };

                                this.store.dispatch(AuthActions.setTeamsEnvironment({ teamsEnvironment: te }));

                                this.showAsideComponent = !channel;
                                console.log('directory.component.ts : Teams channel', channel);
                            })
                            .catch((reason) => {
                                console.log('directory.component.ts : Error gettting Context', reason);
                            });
                    },
                    (reason) => {
                        console.log('microsoftTeams.app.initialize() failed', reason);
                    }
                );
            }
        });
    }

    onSetUsersPageNumber($event: number) {
        this.store.dispatch(dataActions.setUsersPageNumber({ pageNumber: $event }));
    }

    onSetUsersPageSize($event: number) {
        this.store.dispatch(dataActions.setUsersPageSize({ pageSize: $event }));
    }

    onSetUsersViewMode($event: UsersViewMode) {
        this.store.dispatch(dataActions.setUsersViewMode({ viewMode: $event }));
    }

    onSetCategoryItemsViewMode($event: CategoryChosenViewMode) {
        this.store.dispatch(dataActions.setCategoryItemsViewMode({ categoryChosenViewMode: $event }));
    }

    onSetCategorySortField($event: CategoryChosenSortField) {
        this.store.dispatch(dataActions.setCategorySortField({ categoryChosenSortField: $event }));
    }

    onSetCategorySortOrder($event: CategoryChosenSortOrder) {
        this.store.dispatch(dataActions.setCategorySortOrder({ categoryChosenSortOrder: $event }));
    }

    onSetSortField($event: SortField) {
        this.store.dispatch(dataActions.setSortField({ sortField: $event }));
    }

    onSetSortOrder($event: SortOrder) {
        this.store.dispatch(dataActions.setSortOrder({ sortOrder: $event }));
    }

    onSetGroupBy($event: GroupByField) {
        this.store.dispatch(dataActions.setGroupBy({ groupBy: $event }));
    }

    onClearFilterLetter() {
        this.store.dispatch(dataActions.setFilterLetter({ filterLetter: null }));
    }

    editOffice($event: Event) {
        this.activeCategoryItem$
            .pipe(take(1), withLatestFrom(this.activeCategory$))
            .subscribe(([categoryItem, category]) => {
                this.store.dispatch(
                    dataActions.dialogShow({
                        dialog: DirModals.EditCategoryItem,
                        options: { initialState: { categoryItem, category } }
                    })
                );
            });
    }

    pinWebPart() {
        FrameCommunicationService.sendPinRequest();
    }

    unpinWebPart() {
        FrameCommunicationService.sendUnpinRequest();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(undefined);
        this.unsubscribe$.complete();
    }
}
