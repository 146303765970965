<div class="modal-header">
    <div class="card-title d-flex">
        <!--begin::Svg Icon | path: icons/duotune/communication/com005.svg-->
        <span class="svg-icon svg-icon-1 me-2">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-building"
                viewBox="0 0 16 16">
                <path
                    fill-rule="evenodd"
                    d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                <path
                    d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
            </svg>
        </span>
        <!--end::Svg Icon-->
        <h2>Edit {{ categoryItem.name }} ({{ category.name }})</h2>
    </div>
    <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="mb-7">
        <label class="fs-6 fw-bold mb-3">
            <span>Update image</span>
            <i class="fas fa-exclamation-circle ms-1 fs-7"></i>
        </label>
        <div class="mt-1 d-flex justify-content-center">
            <dir-category-image
                [imageId]="imageSrc"
                [pattern]="categoryItem.pattern"
                [editMode]="true"></dir-category-image>
        </div>
    </div>
    <div class="d-flex align-items-start mb-4 flex-column" *ngIf="categoryItem !== null">
        <div class="col-4">
            <label for="name" class="fs-6 fw-bold form-label mt-2"><span>Name</span></label>
            <!--<input type="text" class="form-control form-control-solid" name="name" [formControl]="form.controls.name"
                   required>-->

            <span
                type="text"
                class="form-control form-control-solid"
                name="name"
                [innerHTML]="form.controls.name.value"></span>

            <div class="invalid-feedback">
                <div data-field="name">Name is required</div>
            </div>
        </div>
        <div class="col-12">
            <label for="name" class="fs-6 fw-bold form-label mt-2"><span>Description</span></label>
            <input
                type="text"
                class="form-control form-control-solid"
                name="name"
                [formControl]="form.controls.description" />
        </div>
    </div>
    <div class="d-flex align-items-start mb-4 flex-column" *ngIf="categoryItem !== null">
        <div class="col-12" *ngFor="let colDef of category.columnDefinitions; let i = index">
            <dir-editor-field [colDef]="colDef" [group]="form.controls.fields.at(i)"></dir-editor-field>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-light" (click)="closeModal()">Close</button>
    <button class="btn btn-success" (click)="onSave()" [disabled]="isSavingFromDialog">Save</button>
</div>
