import { Component, computed, input, output } from '@angular/core';
import {
    BorderStyle,
    BoxLayoutType,
    BoxStyleType,
    BoxType,
    ChartMetaInfo,
    ChartNode,
    ChartViewComponent,
    DataSourceType,
    DefaultChartView,
    SeparationSettings
} from '@teamimprover/ti-orgchart';
import { DirectoryUser } from '../data/data.models';
import { GraphPictureComponent } from '../shared/components/graph-picture.component';

@Component({
    selector: 'dir-users-chart',
    imports: [ChartViewComponent],
    templateUrl: './users-chart.component.html',
    styleUrl: './users-chart.component.scss'
})
export class UsersChartComponent {
    users = input<DirectoryUser[]>([]);
    chartNodes = computed<ChartNode[]>(() => {
        const nodes = this.users()?.map((u) => {
            const node: ChartNode = {
                DisplayName: u.displayName,
                UniqueId: u.id,
                ManagerId: u.managerId,
                Title: u.jobTitle,
                JobTitle: u.jobTitle,
                IsDepartment: false,
                BoxType: BoxType.Standard,
                Height: 100,
                Width: 200,
                TOC_Impacts: [],
                TOC_Fields: {
                    "DisplayName": u.displayName,
                },
                MultiplePositions: [],
                Coordinators: []
            };
            return node;
        });
        if (!nodes || nodes.length === 0) {
            return [];
        }
        return nodes;
    });
    pictureComponentProvider = (objectId: string) => ({
        component: GraphPictureComponent,
        inputs: { id: objectId, size: 90, directoryUser: this.users().find((u) => u.id === objectId) }
    });
    information = output<string>();
    chartMeta: ChartMetaInfo = {
        availableFields: ['DisplayName'],
        referenceDataColumns: ['DisplayName'],
        chartId: 'Directory',
        chartName: 'Directory',
        contextMenuItems: [],
        dataSourceName: 'Directory',
        dataSourceType: DataSourceType.WAADDataSource,
        pictureSource: 0,
        isPublic: true,
        isPromoted: true,
        isPrivate: false,
        isAdmin: true,
        isPlanner: false,
        isHiddenInUi: false,
        usingTeams: false,
        isWaadTenant: false,
        isDemo: false,
        isDeleted: false,
        hideDataSourceLink: false,
        isSharePointBasedChart: false,
        sharePointLink: '',
        sharePointLinkToPosition: '',
        sharePointListUrl: '',
        sharePointLinkToRedirect: '',
        hideToolBarButtons: '',
        topUserId: '',
        currentUserId: '',
        inFullScreenMode: false,
        columnSettings: [
            {
                OriginalName: 'DisplayName',
                Id: 1,
                IsBold: false,
                IsInfo: true,
                IsDisplayed: true,
                IsItalic: false,
                IsStaff: false,
                IsStrikeout: false,
                CalculatedNewName: 'DisplayName',
                Order: 1,
                FontColour: '#000000',
                FontName: 'Inter, Helvetica, "sans-serif"',
                FontSize: '14px',
                IsUnderlined: false,
                IsSearched: true,
                IsParentKey: false,
                IsPrimaryKey: false,
                LengthLimit: 20,
                OutputFormat: '',
                InformationOrder: 1
            }
        ],
        SmartDraw: false,
        chartView: DefaultChartView.Chart, // Replace with an appropriate enum value
        NameColumn: 'DisplayName',
        PhoneColumn: '',
        EmailColumn: '',
        CompanyNameColumn: '',
        CityColumn: '',
        OfficeLocationColumn: '',
        CountryColumn: '',
        JobTitleColumn: '',
        GroupColumn: '',
        PictureColumn: '',
        UniqueId: 'unique-id-123',
        ManagerId: 'manager-id-123',
        CustomLayout: '',
        SortColumns: [],
        BrandColor: '#000000',
        isAuditTrailEnabled: false,
        isStatusTrackerEnabled: false,
        isMappingsEnabled: false,
        excludeNonChartUsers: false,
        isUserPresenceEnabled: false,
        createdByObjectId: 'creator-id-123',
        TenantAccountId: 'tenant-id-123',
        ChartUserOptionBookmarkId: 0,
        ruleKeys: [],
        filters: [],
        ShowNavigationArrows: false,
        MaximumDepth: 3,
        SortOrder: 'asc',
        LineColour: 'blue',
        GridWidth: 20,
        StackLowerLevel: false,
        ShowDottedLines: true,
        CustomCss: '',
        InformationText: '',
        additions: [],
        VacantCustomKeyLabel: '',
        TempCustomKeyLabel: '',
        DepartmentCustomKeyLabel: '',
        VacantIsDisplayedInKey: false,
        TempIsDisplayedInKey: false,
        DepartmentIsDisplayedInKey: false,
        Templates: {
            Standard: {
                TemplateId: 1,
                TemplateName: 'Standard',
                BoxLayout: BoxLayoutType.PhotoLeft,
                BoxWidth: 180,
                BoxHeight: 100,
                CustomLayout: '',
                BoxStyle: BoxStyleType.Solid,
                BoxColor: 'var(--bs-secondary)',
                BorderColor: '#000000',
                BoxSize: 1,
                AccentColor: '#0000FF',
                BorderStyle: BorderStyle.Solid,
                TextColor: '#000000',
                AnnotationColor: '#000000'
            }
        },
        inEvaluationMode: false
    };
    separationSettings: SeparationSettings = {
        sibling: 20,
        stack: 20,
        level: 20
    };
    startingPositionId = '';

    navigate(userId: string) {
        console.log('Navigate to ', userId);
        this.startingPositionId = userId;
    }

    info(userId: string) {
        console.log('Info for ', userId);
        this.information.emit(userId);
    }
}
