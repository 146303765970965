<div class="d-flex flex-column min-vh-100" id="kt_app_root">
    <div class="d-flex flex-column flex-column-fluid">
        <div class="d-flex flex-row-fluid flex-column flex-column-fluid text-center p-10 py-lg-20">
            <div>
                <a routerLink="/"><img alt="Logo" src="assets/img/logo96x96.svg" class="h-50px" /></a>
                <h1 class="fw-bold fs-2qx text-gray-800 mb-7">Subscription Information</h1>
            </div>

            <div class="container-sm">
                <div class="col">
                    <div class="card mb-10 p-8">
                        <dir-subscription-information [isInline]="true"></dir-subscription-information>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-auto">
        <dir-footer></dir-footer>
    </div>
</div>
