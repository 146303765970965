import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
    BrowserCacheLocation,
    InteractionType,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import {
    AVAILABILITY_MAILBOXSETTINGS_ENDPOINT,
    AVAILABILITY_PRESENCE_ENDPOINT,
    AVAILABILITY_SCHEDULE_ENDPOINT
} from '../app/userAvailability/services/availability.service';

export function loggerCallback(level: LogLevel, message: string, containsPii: boolean) {
    console.log(message);
}

function loggerCallbackFactory(logger: NGXLogger) {
    return (level: LogLevel, message: string, containsPii: boolean) => {
        switch (level) {
            case LogLevel.Error:
                logger.error(message);
                break;
            case LogLevel.Info:
                logger.info(message);
                break;
            case LogLevel.Verbose:
                logger.debug(message);
                break;
            case LogLevel.Warning:
                logger.warn(message);
                break;
            default:
                logger.trace(message);
        }
    };
}

export function MSALInstanceFactory(logger: NGXLogger): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.clientId,
            authority: 'https://login.microsoftonline.com/common',
            redirectUri: environment.redirectUri,
            clientCapabilities: ['CP1'] // This lets the resource server know that this client can handle claim challenges.
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false
        },
        system: {
            allowNativeBroker: false,
            loggerOptions: {
                loggerCallback: loggerCallbackFactory(logger),
                piiLoggingEnabled: true,
                logLevel: LogLevel.Warning
            }
        }
    });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: window.isTeams ? InteractionType.Redirect : InteractionType.Popup,
        authRequest: {
            scopes: [environment.apiScope]
        },
        loginFailedRoute: window.isTeams ? '/teams-login-failed' : '/login-failed'
    };
}

export function MSALInterceptorConfigFactory(store: Store): MsalInterceptorConfiguration {
    return {
        interactionType:
            window.isTeams || !(window !== window.parent && !window.opener)
                ? InteractionType.Redirect
                : InteractionType.Popup,
        protectedResourceMap: new Map([
            [
                'https://graph.microsoft.com/v1.0/me',
                ['user.read', 'profile', { httpMethod: 'PATCH', scopes: ['user.readwrite'] }]
            ],
            [
                'https://graph.microsoft.com/v1.0/users*',
                ['user.readbasic.all', 'user.read', { httpMethod: 'PATCH', scopes: ['user.readwrite.all'] }]
            ],
            ['https://graph.microsoft.com/v1.0/*batch', ['user.readbasic.all', 'user.read']],
            [`${environment.serverUrl}/api*`, [environment.apiScope]],
            [`https://localhost:4200/settings/consent`, null], //TODO - why do we need this?  What about PROD!
            [AVAILABILITY_PRESENCE_ENDPOINT, ['presence.read.all']],
            [AVAILABILITY_SCHEDULE_ENDPOINT, ['Calendars.Read.Shared']],
            [AVAILABILITY_MAILBOXSETTINGS_ENDPOINT, ['MailboxSettings.Read']],
            [`${environment.tocUrl}/api/td/exists/*`, null],
            [`${environment.tocUrl}/api*`, [environment.apiScopeToc]]
        ])
    };
}
