<div *ngIf="userAvailability" class="row">
    <div *ngIf="!userAvailability.NoCalendar" class="col-1">
        {{ formatTime(userAvailability.WorkingHours.StartTime) }}
    </div>
    <div *ngIf="!userAvailability.NoCalendar" class="col-10">
        <span
            *ngFor="let t of getAvailability(); let i = index"
            class="p-1 m-0 calendar-lg span-border availability-span"
            [ngClass]="{
                'calendar-free': t === '0',
                'calendar-tentative': t === '1',
                'calendar-busy': t === '2',
                'calendar-ooo': t === '3',
                'calendar-elsewhere': t === '4',
                'calendar-clash': t === '8'
            }"
            (click)="onBookMeeting(i, $event)"
            tabindex="0"
            (keydown.enter)="onBookMeeting(i, $event)">
            <b *ngIf="i % 4 === 0">{{ getSpanTime(i) }}</b>
            <span *ngIf="i % 4 !== 0">{{ getSpanTime(i) }}</span>
        </span>
    </div>
    <div *ngIf="!userAvailability.NoCalendar" class="col-1">
        {{ formatTime(userAvailability.WorkingHours.EndTime) }}
    </div>
    <div *ngIf="userAvailability.NoCalendar" class="col-12 text-center">No calendar available for this user.</div>
</div>
