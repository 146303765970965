// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).global ||= window;
import {
    enableProdMode,
    ErrorHandler,
    isDevMode,
    importProvidersFrom,
    Provider,
    EnvironmentProviders,
    inject
} from '@angular/core';
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory } from './msal/msal.config';
import { environment } from './environments/environment';
import * as microsoftTeams from '@microsoft/teams-js';
import { TeamsEnvironment } from './app/auth/auth.models';
import posthog from 'posthog-js';
import { NGXLogger, LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_WRITER_SERVICE } from 'ngx-logger';
import { LogLevel } from '@azure/msal-browser';
import {
    MsalInterceptor,
    MSAL_INSTANCE,
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
    MsalService,
    MsalGuard,
    MsalModule
} from '@azure/msal-angular';
import { StoreModule } from '@ngrx/store';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { InsightsService } from './app/services/insights.service';
import { SignalRService } from './app/services/signalr.service';
import { TocService } from './app/services/toc.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { LoggingInterceptor } from './app/utils/logging-interceptor';
import { AdminGuard } from './app/guards/admin.guard';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routes } from './app/app-routing.module';
import { LoggerWriterService } from './app/utils/logger-mapper.provider';
import { MarkdownModule } from 'ngx-markdown';
import { ROOT_REDUCERS, metaReducers } from './app/reducers';
import { IntegrityModule } from './app/integrity/integrity.module';
import { SettingsModule } from './app/settings/settings.module';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app/app.effects';
import { AuthEffects } from './app/auth/auth.effects';
import { DataEffects } from './app/data/data.effects';
import { SettingsEffects } from './app/settings/settings.effects';
import { ImageEffects } from './app/images/image.effects';
import { AvailabilityEffects } from './app/userAvailability/availability.effects';
import { TocEffects } from './app/settings/toc.effects';
import { IntegrityEffects } from './app/integrity/integrity.effects';
import { StoreRouterConnectingModule, NavigationActionTiming } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SharedModule } from './app/shared/shared.module';
import { UsersModule } from './app/users/users.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app/app.component';
import {
    createUrlTreeFromSnapshot,
    provideRouter,
    Router,
    withInMemoryScrolling,
    withViewTransitions
} from '@angular/router';
import { CurrentTransitionService } from './app/services/current-transition.service';
import { DndModule, provideDnd } from '@ng-dnd/core';
import { HTML5Backend } from 'react-dnd-html5-backend';

function loggerCallbackFactory(logger: NGXLogger) {
    return (level: LogLevel, message: string, containsPii: boolean) => {
        switch (level) {
            case LogLevel.Error:
                logger.error(message);
                break;
            case LogLevel.Info:
                logger.info(message);
                break;
            case LogLevel.Verbose:
                logger.debug(message);
                break;
            case LogLevel.Warning:
                logger.warn(message);
                break;
            default:
                logger.trace(message);
        }
    };
}

if (environment.production) {
    enableProdMode();
}
console.log(Date.now() + ' - starting platformBrowserDynamic');

if (environment.postHogApiKey) {
    posthog.init(environment.postHogApiKey, {
        api_host: environment.postHogApiHost,
        person_profiles: 'always',
        capture_pageview: false,
        capture_pageleave: true,
        session_recording: {
            maskTextClass: 'nocap'
        }
    });
}

declare global {
    interface Window {
        isTeams: boolean;
        TeamsEnvironment: TeamsEnvironment;
    }
}

//Is this TD hosted within TOC or SharePoint?
const inTOC: boolean =
    window.location && (window.location.href.indexOf('fromTOC') > -1 || window.location.href.indexOf('fromSP') > -1);

window.isTeams = false;

const providers: (Provider | EnvironmentProviders)[] = [
    importProvidersFrom(
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        //AppRoutingModule,
        LoggerModule.forRoot(
            {
                level: NgxLoggerLevel.INFO,
                enableSourceMaps: !environment.production
            },
            { writerProvider: { provide: TOKEN_LOGGER_WRITER_SERVICE, useClass: LoggerWriterService } }
        ),
        MarkdownModule.forRoot(),
        /**
         * StoreModule.forRoot is imported once in the root module, accepting a reducer
         * function or object map of reducer functions. If passed an object of
         * reducers, combineReducers will be run creating your application
         * meta-reducer. This returns all providers for an @ngrx/store
         * based application.
         */
        StoreModule.forRoot(ROOT_REDUCERS, {
            metaReducers,
            runtimeChecks: {
                // strictStateImmutability and strictActionImmutability are enabled by default
                strictStateSerializability: true,
                strictActionSerializability: true,
                strictActionWithinNgZone: true,
                strictActionTypeUniqueness: true
            }
        }),
        IntegrityModule,
        SettingsModule,
        //StoreModule.forFeature(uiFeature),
        /**
         * EffectsModule.forRoot() is imported once in the root module and
         * sets up the effects class to be initialized immediately when the
         * application starts.
         *
         * See: https://ngrx.io/guide/effects#registering-root-effects
         */
        EffectsModule.forRoot([
            AppEffects,
            AuthEffects,
            DataEffects,
            SettingsEffects,
            ImageEffects,
            AvailabilityEffects,
            TocEffects,
            IntegrityEffects
        ]),
        /**
         * @ngrx/router-store keeps router state up-to-date in the store.
         */
        StoreRouterConnectingModule.forRoot({
            navigationActionTiming: NavigationActionTiming.PostActivation
        }),
        StoreDevtoolsModule.instrument({
            name: 'TeamDirectory',
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true
        }),
        ToastrModule.forRoot(),
        TypeaheadModule.forRoot(),
        CollapseModule.forRoot(),
        SharedModule,
        UsersModule,
        MsalModule,
        FontAwesomeModule,
        ProgressbarModule,
        PaginationModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        MatMenuModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatDialogModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            // eslint-disable-next-line no-constant-binary-expression
            enabled: false && !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        //DndModule.forRoot({ backend: HTML5Backend })
    ),
    provideDnd({ backend: HTML5Backend }),
    provideRouter(
        routes,
        withInMemoryScrolling(),
        withViewTransitions({
            skipInitialTransition: false,
            onViewTransitionCreated: (info) => {
                const router = inject(Router);
                const transitionService = inject(CurrentTransitionService);
                const toTree = createUrlTreeFromSnapshot(info.to, []);
                const targetUrl = router.getCurrentNavigation()!.finalUrl!;
                // Skip the transition if the only thing changing is the fragment and queryParams
                console.log('checking if we should skip transition', toTree, targetUrl);
                if (
                    (info.from.firstChild?.url?.length === 0 &&
                        info.to.firstChild?.url?.length === 1 &&
                        info.to.firstChild?.url[0]?.path === 'directory') ||
                    router.isActive(toTree, {
                        paths: 'exact',
                        matrixParams: 'exact',
                        fragment: 'ignored',
                        queryParams: 'ignored'
                    })
                ) {
                    console.log('skipping transition');
                    info.transition.skipTransition();
                } else {
                    console.log('not skipping transition');
                    //transition.skipTransition();
                }
                transitionService.currentTransition.set(info);
                console.log('transition created');
                // Update current transition when animation finishes
                info.transition.finished.finally(() => {
                    console.log('transition finished');
                    transitionService.currentTransition.set(null);
                });
            }
        })
        //withViewTransitions()
    ),
    //provideExperimentalCheckNoChangesForDebug({interval: 1000}),
    {
        provide: ErrorHandler,
        useClass:
            environment.appInsightsConnectionString !== '' ? ApplicationinsightsAngularpluginErrorService : ErrorHandler
    },
    InsightsService,
    SignalRService,
    TocService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoggingInterceptor,
        multi: true
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory,
        deps: [NGXLogger]
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
    },
    AdminGuard,
    MsalService,
    MsalGuard,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
];

if (!inTOC) {
    microsoftTeams.app
        .initialize()
        .then(
            () => {
                console.log(Date.now() + ' - initialized teams in main.ts');
                window.isTeams = true;
                //microsoftTeams.app.notifySuccess();
                //Need the context so that we can tell which Teams mode we are running in.
                microsoftTeams.app
                    .getContext()
                    .then((context) => {
                        if (context != null) {
                            const channel: boolean =
                                (context.channel != undefined && context.channel != null) ||
                                (context.chat != undefined && context.chat != null);
                            const mobile: boolean =
                                context.app.host.clientType == microsoftTeams.HostClientType.android ||
                                context.app.host.clientType == microsoftTeams.HostClientType.ios;
                            const te: TeamsEnvironment = {
                                isTeams: true,
                                isTeamsChannel: channel,
                                isTeamsMobile: mobile,
                                teamsTabUrl: null,
                                isOutlook: context?.app.host.name.toLocaleLowerCase().indexOf('outlook') != -1
                            };
                            window.TeamsEnvironment = te;
                            console.log('TeamsEnvironment: ', te);
                        } else {
                            console.log('TeamsEnvironment: context is null');
                        }
                    })
                    .catch((reason) => {
                        console.log('main.ts : Error gettting Context', reason);
                    });
            },
            (reason) => {
                if (reason?.message !== 'Initialization Failed. No Parent window found.') {
                    console.log('microsoftTeams.app.initialize() failed', reason);
                }
                console.log(Date.now() + ' - microsoftTeams.app.initialize() - not in teams');
                window.isTeams = false;
            }
        )
        .then(() => {
            console.log(Date.now() + ' - bootstrapping AppModule');
            bootstrapApplication(AppComponent, { providers })
                //.then((moduleRef) => {})
                .catch((err) => {
                    console.log('platformBrowserDynamic() failed', err);
                    console.error(err);
                });
        });
} else {
    window.isTeams = false;
    console.log(Date.now() + ' - bootstrapping AppModule');
    bootstrapApplication(AppComponent, { providers })
        //.then((moduleRef) => {})
        .catch((err) => {
            console.log('platformBrowserDynamic() failed', err);
            console.error(err);
        });
}
