import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, inject, ChangeDetectorRef, ElementRef, Renderer2, Directive, Input, makeEnvironmentProviders, APP_INITIALIZER, Pipe, NgModule, forwardRef } from '@angular/core';
import { of, throwError, BehaviorSubject, concat, merge, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { NG_VALIDATORS } from '@angular/forms';

/**
 * L10n configuration token.
 */
const L10N_CONFIG = new InjectionToken('L10N_CONFIG');
/**
 * L10n locale token.
 */
const L10N_LOCALE = new InjectionToken('L10N_LOCALE');
function l10nError(type, value) {
  return new Error(`angular-l10n (${type.name}): ${value}`);
}
function validateLanguage(language) {
  const regExp = new RegExp(/^([a-z]{2,3})(\-[A-Z][a-z]{3})?(\-[A-Z]{2})?(-u.+)?$/);
  return regExp.test(language);
}
function formatLanguage(language, format) {
  if (language == null || language === '') return '';
  if (!validateLanguage(language)) throw l10nError(formatLanguage, 'Invalid language');
  const [, LANGUAGE = '', SCRIPT = '', REGION = ''] = language.match(/^([a-z]{2,3})(\-[A-Z][a-z]{3})?(\-[A-Z]{2})?/) || [];
  switch (format) {
    case 'language':
      return LANGUAGE;
    case 'language-script':
      return LANGUAGE + SCRIPT;
    case 'language-region':
      return LANGUAGE + REGION;
    case 'language-script-region':
      return LANGUAGE + SCRIPT + REGION;
  }
}
function parseLanguage(language) {
  const groups = language.match(/^([a-z]{2,3})(\-([A-Z][a-z]{3}))?(\-([A-Z]{2}))?(-u.+)?$/);
  if (groups == null) throw l10nError(parseLanguage, 'Invalid language');
  return {
    language: groups[1],
    script: groups[3],
    region: groups[5],
    extension: groups[6]
  };
}
function getBrowserLanguage(format) {
  let browserLanguage = null;
  if (typeof navigator !== 'undefined' && navigator.language) {
    switch (format) {
      case 'language-region':
      case 'language-script-region':
        browserLanguage = navigator.language;
        break;
      default:
        browserLanguage = navigator.language.split('-')[0];
    }
  }
  return browserLanguage;
}
function getSchema(schema, language, format) {
  const element = schema.find(item => formatLanguage(item.locale.language, format) === language);
  return element;
}
function getValue(key, data, keySeparator) {
  if (data) {
    if (keySeparator) {
      return key.split(keySeparator).reduce((acc, cur) => (acc && acc[cur]) != null ? acc[cur] : null, data);
    }
    return data[key] != null ? data[key] : null;
  }
  return null;
}
function handleParams(value, params) {
  const replacedValue = value.replace(/{{\s?([^{}\s]*)\s?}}/g, (substring, parsedKey) => {
    const replacer = params[parsedKey];
    return replacer !== undefined ? replacer : substring;
  });
  return replacedValue;
}
function mergeDeep(target, source) {
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, {
            [key]: source[key]
          });
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(output, {
          [key]: source[key]
        });
      }
    });
  }
  return output;
}
function toNumber(value) {
  const parsedValue = typeof value === 'string' && !isNaN(+value - parseFloat(value)) ? +value : value;
  return parsedValue;
}
function toDate(value) {
  if (isDate(value)) {
    return value;
  }
  if (typeof value === 'number' && !isNaN(value)) {
    return new Date(value);
  }
  if (typeof value === 'string') {
    value = value.trim();
    if (!isNaN(value - parseFloat(value))) {
      return new Date(parseFloat(value));
    }
    if (/^(\d{4}-\d{1,2}-\d{1,2})$/.test(value)) {
      const [y, m, d] = value.split('-').map(val => +val);
      return new Date(y, m - 1, d);
    }
    const match = value.match(/^(\d{4})-?(\d\d)-?(\d\d)(?:T(\d\d)(?::?(\d\d)(?::?(\d\d)(?:\.(\d+))?)?)?(Z|([+-])(\d\d):?(\d\d))?)?$/);
    if (match) {
      return isoStringToDate(match);
    }
  }
  const date = new Date(value);
  if (!isDate(date)) {
    throw l10nError(toDate, 'Invalid date');
  }
  return date;
}
const PARSE_DATE_STYLE = {
  full: {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  },
  long: {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  },
  medium: {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  },
  short: {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric'
  }
};
const PARSE_TIME_STYLE = {
  full: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'long'
  },
  long: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  },
  medium: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  },
  short: {
    hour: 'numeric',
    minute: 'numeric'
  }
};
function parseDigits(digits) {
  const groups = digits.match(/^(\d+)?\.((\d+)(\-(\d+))?)?$/);
  if (groups == null) throw l10nError(parseDigits, 'Invalid digits');
  return {
    minimumIntegerDigits: groups[1] ? parseInt(groups[1]) : undefined,
    minimumFractionDigits: groups[3] ? parseInt(groups[3]) : undefined,
    maximumFractionDigits: groups[5] ? parseInt(groups[5]) : undefined
  };
}
function isObject(item) {
  return typeof item === 'object' && !Array.isArray(item);
}
function isDate(value) {
  return value instanceof Date && !isNaN(value.valueOf());
}
/**
 * Converts a date in ISO 8601 to a Date.
 */
function isoStringToDate(match) {
  const date = new Date(0);
  let tzHour = 0;
  let tzMin = 0;
  const dateSetter = match[8] ? date.setUTCFullYear : date.setFullYear;
  const timeSetter = match[8] ? date.setUTCHours : date.setHours;
  if (match[9]) {
    tzHour = Number(match[9] + match[10]);
    tzMin = Number(match[9] + match[11]);
  }
  dateSetter.call(date, Number(match[1]), Number(match[2]) - 1, Number(match[3]));
  const h = Number(match[4] || 0) - tzHour;
  const m = Number(match[5] || 0) - tzMin;
  const s = Number(match[6] || 0);
  const ms = Math.round(parseFloat('0.' + (match[7] || 0)) * 1000);
  timeSetter.call(date, h, m, s, ms);
  return date;
}
class L10nCache {
  constructor() {
    this.cache = {};
  }
  read(key, request) {
    if (this.cache[key]) return this.cache[key];
    const response = request.pipe(shareReplay(1));
    this.cache[key] = response;
    return response;
  }
  static {
    this.ɵfac = function L10nCache_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nCache)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nCache,
      factory: L10nCache.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nCache, [{
    type: Injectable
  }], null, null);
})();

/**
 * Implement this class-interface to create a storage for the locale.
 */
class L10nStorage {
  static {
    this.ɵfac = function L10nStorage_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nStorage)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nStorage,
      factory: L10nStorage.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nStorage, [{
    type: Injectable
  }], null, null);
})();
class L10nDefaultStorage {
  async read() {
    return Promise.resolve(null);
  }
  async write(locale) {}
  static {
    this.ɵfac = function L10nDefaultStorage_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDefaultStorage)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nDefaultStorage,
      factory: L10nDefaultStorage.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDefaultStorage, [{
    type: Injectable
  }], null, null);
})();

/**
 * Implement this class-interface to resolve the locale.
 */
class L10nLocaleResolver {
  static {
    this.ɵfac = function L10nLocaleResolver_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nLocaleResolver)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nLocaleResolver,
      factory: L10nLocaleResolver.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nLocaleResolver, [{
    type: Injectable
  }], null, null);
})();
class L10nDefaultLocaleResolver {
  constructor(config) {
    this.config = config;
  }
  async get() {
    const browserLanguage = getBrowserLanguage(this.config.format);
    if (browserLanguage) {
      const schema = getSchema(this.config.schema, browserLanguage, this.config.format);
      if (schema) {
        return Promise.resolve(schema.locale);
      }
    }
    return Promise.resolve(null);
  }
  static {
    this.ɵfac = function L10nDefaultLocaleResolver_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDefaultLocaleResolver)(i0.ɵɵinject(L10N_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nDefaultLocaleResolver,
      factory: L10nDefaultLocaleResolver.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDefaultLocaleResolver, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [L10N_CONFIG]
      }]
    }];
  }, null);
})();

/**
 * Implement this class-interface to create a loader of translation data.
 */
class L10nTranslationLoader {
  static {
    this.ɵfac = function L10nTranslationLoader_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nTranslationLoader)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nTranslationLoader,
      factory: L10nTranslationLoader.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nTranslationLoader, [{
    type: Injectable
  }], null, null);
})();
class L10nDefaultTranslationLoader {
  get(language, provider) {
    return provider.asset[language] ? of(provider.asset[language]) : throwError(() => l10nError(L10nDefaultTranslationLoader, 'Asset not found'));
  }
  static {
    this.ɵfac = function L10nDefaultTranslationLoader_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDefaultTranslationLoader)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nDefaultTranslationLoader,
      factory: L10nDefaultTranslationLoader.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDefaultTranslationLoader, [{
    type: Injectable
  }], null, null);
})();

/**
 * Implement this class-interface to create a translation fallback.
 */
class L10nTranslationFallback {
  static {
    this.ɵfac = function L10nTranslationFallback_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nTranslationFallback)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nTranslationFallback,
      factory: L10nTranslationFallback.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nTranslationFallback, [{
    type: Injectable
  }], null, null);
})();
class L10nDefaultTranslationFallback {
  constructor(config, cache, translationLoader) {
    this.config = config;
    this.cache = cache;
    this.translationLoader = translationLoader;
  }
  /**
   * Translation data will be merged in the following order:
   * 'language'
   * 'language[-script]'
   * 'language[-script][-region]'
   */
  get(language, provider) {
    const loaders = [];
    const keywords = language.match(/-?[a-zA-z]+/g) || [];
    let fallbackLanguage = '';
    for (const keyword of keywords) {
      fallbackLanguage += keyword;
      if (this.config.cache) {
        loaders.push(this.cache.read(`${provider.name}-${fallbackLanguage}`, this.translationLoader.get(fallbackLanguage, provider)));
      } else {
        loaders.push(this.translationLoader.get(fallbackLanguage, provider));
      }
    }
    return loaders;
  }
  static {
    this.ɵfac = function L10nDefaultTranslationFallback_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDefaultTranslationFallback)(i0.ɵɵinject(L10N_CONFIG), i0.ɵɵinject(L10nCache), i0.ɵɵinject(L10nTranslationLoader));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nDefaultTranslationFallback,
      factory: L10nDefaultTranslationFallback.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDefaultTranslationFallback, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [L10N_CONFIG]
      }]
    }, {
      type: L10nCache
    }, {
      type: L10nTranslationLoader
    }];
  }, null);
})();

/**
 * Implement this class-interface to create an handler for translated values.
 */
class L10nTranslationHandler {
  static {
    this.ɵfac = function L10nTranslationHandler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nTranslationHandler)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nTranslationHandler,
      factory: L10nTranslationHandler.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nTranslationHandler, [{
    type: Injectable
  }], null, null);
})();
class L10nDefaultTranslationHandler {
  parseValue(key, params, value) {
    if (params) return handleParams(value, params);
    return value;
  }
  static {
    this.ɵfac = function L10nDefaultTranslationHandler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDefaultTranslationHandler)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nDefaultTranslationHandler,
      factory: L10nDefaultTranslationHandler.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDefaultTranslationHandler, [{
    type: Injectable
  }], null, null);
})();

/**
 * Implement this class-interface to create an handler for missing values.
 */
class L10nMissingTranslationHandler {
  static {
    this.ɵfac = function L10nMissingTranslationHandler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nMissingTranslationHandler)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nMissingTranslationHandler,
      factory: L10nMissingTranslationHandler.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nMissingTranslationHandler, [{
    type: Injectable
  }], null, null);
})();
class L10nDefaultMissingTranslationHandler {
  handle(key, value, params) {
    return key;
  }
  static {
    this.ɵfac = function L10nDefaultMissingTranslationHandler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDefaultMissingTranslationHandler)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nDefaultMissingTranslationHandler,
      factory: L10nDefaultMissingTranslationHandler.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDefaultMissingTranslationHandler, [{
    type: Injectable
  }], null, null);
})();
class L10nTranslationService {
  constructor(config, locale, cache, storage, resolveLocale, translationFallback, translationLoader, translationHandler, missingTranslationHandler) {
    this.config = config;
    this.locale = locale;
    this.cache = cache;
    this.storage = storage;
    this.resolveLocale = resolveLocale;
    this.translationFallback = translationFallback;
    this.translationLoader = translationLoader;
    this.translationHandler = translationHandler;
    this.missingTranslationHandler = missingTranslationHandler;
    /**
     * The translation data: {language: {key: value}}
     */
    this.data = {};
    this.translation = new BehaviorSubject(this.locale);
    this.error = new BehaviorSubject(null);
  }
  /**
   * Gets the current locale.
   */
  getLocale() {
    return this.locale;
  }
  /**
   * Changes the current locale and load the translation data.
   * @param locale The new locale
   */
  async setLocale(locale) {
    await this.loadTranslations(this.config.providers, locale);
  }
  /**
   * Fired every time the translation data has been loaded. Returns the locale.
   */
  onChange() {
    return this.translation.asObservable();
  }
  /**
   * Fired when the translation data could not been loaded. Returns the error.
   */
  onError() {
    return this.error.asObservable();
  }
  /**
   * Translates a key or an array of keys.
   * @param keys The key or an array of keys to be translated
   * @param params Optional parameters contained in the key
   * @param language The current language
   * @return The translated value or an object: {key: value}
   */
  translate(keys, params, language = this.locale.language) {
    language = formatLanguage(language, this.config.format);
    if (Array.isArray(keys)) {
      const data = {};
      for (const key of keys) {
        data[key] = this.translate(key, params, language);
      }
      return data;
    }
    const value = getValue(keys, this.data[language], this.config.keySeparator);
    return value ? this.translationHandler.parseValue(keys, params, value) : this.missingTranslationHandler.handle(keys, value, params);
  }
  /**
   * Checks if a translation exists.
   * @param key The key to be tested
   * @param language The current language
   */
  has(key, language = this.locale.language) {
    language = formatLanguage(language, this.config.format);
    return getValue(key, this.data[language], this.config.keySeparator) !== null;
  }
  /**
   * Gets the language direction.
   */
  getLanguageDirection(language = this.locale.language) {
    const schema = getSchema(this.config.schema, language, this.config.format);
    return schema ? schema.dir : undefined;
  }
  /**
   * Gets available languages.
   */
  getAvailableLanguages() {
    const languages = this.config.schema.map(item => formatLanguage(item.locale.language, this.config.format));
    return languages;
  }
  /**
   * Initializes the service
   * @param providers An array of L10nProvider
   */
  async init(providers = this.config.providers) {
    let locale = null;
    // Tries to get locale from storage.
    if (locale == null) {
      locale = await this.storage.read();
    }
    // Tries resolved locale.
    if (locale == null) {
      locale = await this.resolveLocale.get();
    }
    // Uses default locale.
    if (locale == null) {
      locale = this.config.defaultLocale;
    }
    // Loads translation data.
    await this.loadTranslations(providers, locale);
  }
  /**
   * Can be called at every translation change.
   * @param providers An array of L10nProvider
   * @param locale The current locale
   */
  async loadTranslations(providers = this.config.providers, locale = this.locale) {
    const language = formatLanguage(locale.language, this.config.format);
    return new Promise(resolve => {
      concat(...this.getTranslation(providers, language)).subscribe({
        next: data => this.addData(data, language),
        error: error => {
          this.handleError(error);
          resolve();
        },
        complete: () => {
          this.releaseTranslation(locale);
          resolve();
        }
      });
    });
  }
  /**
   * Can be called to add translation data.
   * @param data The translation data {key: value}
   * @param language The language to add data
   */
  addData(data, language) {
    this.data[language] = this.data[language] !== undefined ? mergeDeep(this.data[language], data) : data;
  }
  /**
   * Adds providers to configuration
   * @param providers The providers of the translations data
   */
  addProviders(providers) {
    providers.forEach(provider => {
      if (!this.config.providers.find(p => p.name === provider.name)) {
        this.config.providers.push(provider);
      }
    });
  }
  getTranslation(providers, language) {
    const lazyLoaders = [];
    let loaders = [];
    for (const provider of providers) {
      if (this.config.fallback) {
        loaders = loaders.concat(this.translationFallback.get(language, provider));
      } else {
        if (this.config.cache) {
          lazyLoaders.push(this.cache.read(`${provider.name}-${language}`, this.translationLoader.get(language, provider)));
        } else {
          lazyLoaders.push(this.translationLoader.get(language, provider));
        }
      }
    }
    loaders.push(merge(...lazyLoaders));
    return loaders;
  }
  handleError(error) {
    this.error.next(error);
  }
  releaseTranslation(locale) {
    Object.assign(this.locale, locale);
    this.translation.next(this.locale);
    this.storage.write(this.locale);
  }
  static {
    this.ɵfac = function L10nTranslationService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nTranslationService)(i0.ɵɵinject(L10N_CONFIG), i0.ɵɵinject(L10N_LOCALE), i0.ɵɵinject(L10nCache), i0.ɵɵinject(L10nStorage), i0.ɵɵinject(L10nLocaleResolver), i0.ɵɵinject(L10nTranslationFallback), i0.ɵɵinject(L10nTranslationLoader), i0.ɵɵinject(L10nTranslationHandler), i0.ɵɵinject(L10nMissingTranslationHandler));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nTranslationService,
      factory: L10nTranslationService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nTranslationService, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [L10N_CONFIG]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [L10N_LOCALE]
      }]
    }, {
      type: L10nCache
    }, {
      type: L10nStorage
    }, {
      type: L10nLocaleResolver
    }, {
      type: L10nTranslationFallback
    }, {
      type: L10nTranslationLoader
    }, {
      type: L10nTranslationHandler
    }, {
      type: L10nMissingTranslationHandler
    }];
  }, null);
})();
class L10nAsyncPipe {
  constructor() {
    this.translation = inject(L10nTranslationService);
    this.cdr = inject(ChangeDetectorRef);
    this.onChanges = this.translation.onChange().subscribe({
      next: () => this.cdr.markForCheck()
    });
  }
  ngOnDestroy() {
    if (this.onChanges) this.onChanges.unsubscribe();
  }
  static {
    this.ɵfac = function L10nAsyncPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nAsyncPipe)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nAsyncPipe,
      factory: L10nAsyncPipe.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nAsyncPipe, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();

/**
 * Breadth First Search (BFS) algorithm for traversing & searching tree data structure of DOM
 * explores the neighbor nodes first, before moving to the next level neighbors.
 * Time complexity: between O(1) and O(|V|^2).
 */
function getTargetNode(rootNode) {
  return walk(rootNode);
}
const MAX_DEPTH = 10;
function walk(rootNode) {
  const queue = [];
  let iNode;
  let depth = 0;
  let nodeToDepthIncrease = 1;
  queue.push(rootNode);
  while (queue.length > 0 && depth <= MAX_DEPTH) {
    iNode = queue.splice(0, 1)[0];
    if (isTargetNode(iNode)) return iNode;
    if (depth < MAX_DEPTH && iNode.childNodes) {
      for (const child of Array.from(iNode.childNodes)) {
        if (isValidNode(child)) {
          queue.push(child);
        }
      }
    }
    if (--nodeToDepthIncrease === 0) {
      depth++;
      nodeToDepthIncrease = queue.length;
    }
  }
  return rootNode;
}
function isTargetNode(node) {
  return typeof node !== 'undefined' && node.nodeType === 3 && node.nodeValue != null && node.nodeValue.trim() !== '';
}
/**
 * A valid node is not marked for translation.
 */
function isValidNode(node) {
  if (typeof node !== 'undefined' && node.nodeType === 1 && node.attributes) {
    for (const attr of Array.from(node.attributes)) {
      if (attr && /^l10n|translate/.test(attr.name)) return false;
    }
  }
  return true;
}
class L10nDirective {
  constructor() {
    this.el = inject(ElementRef);
    this.renderer = inject(Renderer2);
    this.translation = inject(L10nTranslationService);
    this.attributes = [];
    this.destroy = new Subject();
  }
  set innerHTML(content) {
    // Handle TrustedHTML
    this.content = content.toString();
  }
  ngAfterViewInit() {
    if (this.el && this.el.nativeElement) {
      this.element = this.el.nativeElement;
      this.renderNode = getTargetNode(this.el.nativeElement);
      this.text = this.getText();
      this.attributes = this.getAttributes();
      this.addTextListener();
      if (this.language) {
        this.replaceText();
        this.replaceAttributes();
      } else {
        this.addTranslationListener();
      }
    }
  }
  ngOnChanges() {
    if (this.text) {
      if (this.nodeValue == null || this.nodeValue === '') {
        if (this.value) {
          this.text = this.value;
        } else if (this.content) {
          this.text = this.content;
        }
      }
      this.replaceText();
    }
    if (this.attributes && this.attributes.length > 0) {
      this.replaceAttributes();
    }
  }
  ngOnDestroy() {
    this.destroy.next(true);
    this.removeTextListener();
  }
  getText() {
    let text = '';
    if (this.element && this.element.childNodes.length > 0) {
      text = this.getNodeValue();
    } else if (this.value) {
      text = this.value;
    } else if (this.content) {
      text = this.content;
    }
    return text;
  }
  getNodeValue() {
    this.nodeValue = this.renderNode != null && this.renderNode.nodeValue != null ? this.renderNode.nodeValue : '';
    return this.nodeValue ? this.nodeValue.trim() : '';
  }
  getAttributes() {
    const attributes = [];
    if (this.element && this.element.attributes) {
      for (const attr of Array.from(this.element.attributes)) {
        if (attr && attr.name) {
          const [, name = ''] = attr.name.match(/^l10n-(.+)$/) || [];
          if (name) {
            const targetAttr = Array.from(this.element.attributes).find(a => a.name === name);
            if (targetAttr) attributes.push({
              name: targetAttr.name,
              value: targetAttr.value
            });
          }
        }
      }
    }
    return attributes;
  }
  addTextListener() {
    if (typeof MutationObserver !== 'undefined') {
      this.textObserver = new MutationObserver(() => {
        if (this.element) {
          this.renderNode = getTargetNode(this.element);
          this.text = this.getText();
          this.replaceText();
        }
      });
      if (this.renderNode) {
        this.textObserver.observe(this.renderNode, {
          subtree: true,
          characterData: true
        });
      }
    }
  }
  removeTextListener() {
    if (this.textObserver) {
      this.textObserver.disconnect();
    }
  }
  addTranslationListener() {
    this.translation.onChange().pipe(takeUntil(this.destroy)).subscribe({
      next: () => {
        this.replaceText();
        this.replaceAttributes();
      }
    });
  }
  replaceText() {
    if (this.text) {
      this.setText(this.getValue(this.text));
    }
  }
  replaceAttributes() {
    if (this.attributes.length > 0) {
      this.setAttributes(this.getAttributesValues());
    }
  }
  setText(value) {
    if (value) {
      if (this.nodeValue && this.text) {
        this.removeTextListener();
        this.renderer.setValue(this.renderNode, this.nodeValue.replace(this.text, value));
        this.addTextListener();
      } else if (this.value) {
        this.renderer.setAttribute(this.element, 'value', value);
      } else if (this.content) {
        this.renderer.setProperty(this.element, 'innerHTML', value);
      }
    }
  }
  setAttributes(data) {
    for (const attr of this.attributes) {
      this.renderer.setAttribute(this.element, attr.name, data[attr.value]);
    }
  }
  getAttributesValues() {
    const values = this.attributes.map(attr => attr.value);
    const data = {};
    for (const value of values) {
      data[value] = this.getValue(value);
    }
    return data;
  }
  static {
    this.ɵfac = function L10nDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: L10nDirective,
      inputs: {
        value: "value",
        innerHTML: "innerHTML",
        language: "language"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDirective, [{
    type: Directive
  }], null, {
    value: [{
      type: Input
    }],
    innerHTML: [{
      type: Input
    }],
    language: [{
      type: Input
    }]
  });
})();
const resolveL10n = async (route, state) => {
  const translation = inject(L10nTranslationService);
  const providers = route.data['l10nProviders'];
  translation.addProviders(providers);
  await translation.loadTranslations(providers);
};

/**
 * Implement this class-interface to init L10n.
 */
class L10nLoader {
  static {
    this.ɵfac = function L10nLoader_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nLoader)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nLoader,
      factory: L10nLoader.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nLoader, [{
    type: Injectable
  }], null, null);
})();
class L10nDefaultLoader {
  constructor(translation) {
    this.translation = translation;
  }
  async init() {
    await this.translation.init();
  }
  static {
    this.ɵfac = function L10nDefaultLoader_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDefaultLoader)(i0.ɵɵinject(L10nTranslationService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nDefaultLoader,
      factory: L10nDefaultLoader.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDefaultLoader, [{
    type: Injectable
  }], function () {
    return [{
      type: L10nTranslationService
    }];
  }, null);
})();
class L10nIntlService {
  constructor(config, locale, translation) {
    this.config = config;
    this.locale = locale;
    this.translation = translation;
  }
  /**
   * Formats a date.
   * @param value A date, a number (milliseconds since UTC epoch) or an ISO 8601 string
   * @param options A L10n or Intl DateTimeFormatOptions object
   * @param language The current language
   * @param timeZone The current time zone
   */
  formatDate(value, options, language = this.locale.dateLanguage || this.locale.language, timeZone = this.locale.timeZone) {
    value = toDate(value);
    let dateTimeFormatOptions = {};
    if (options) {
      if (options) {
        const {
          dateStyle,
          timeStyle,
          ...rest
        } = options;
        if (dateStyle) {
          dateTimeFormatOptions = {
            ...dateTimeFormatOptions,
            ...PARSE_DATE_STYLE[dateStyle]
          };
        }
        if (timeStyle) {
          dateTimeFormatOptions = {
            ...dateTimeFormatOptions,
            ...PARSE_TIME_STYLE[timeStyle]
          };
        }
        dateTimeFormatOptions = {
          ...dateTimeFormatOptions,
          ...rest
        };
      }
    }
    if (timeZone) {
      dateTimeFormatOptions.timeZone = timeZone;
    }
    return new Intl.DateTimeFormat(language, dateTimeFormatOptions).format(value);
  }
  /**
   * Formats a number.
   * @param value A number or a string
   * @param options A L10n or Intl NumberFormatOptions object
   * @param language The current language
   * @param currency The current currency
   * @param convert An optional function to convert the value, with value and locale in the signature.
   * For example:
   * ```
   * const convert = (value: number, locale: L10nLocale) => { return ... };
   * ```
   * @param convertParams Optional parameters for the convert function
   */
  formatNumber(value, options, language = this.locale.numberLanguage || this.locale.language, currency = this.locale.currency, convert, convertParams) {
    if (options && options['style'] === 'unit' && !options['unit']) return value;
    value = toNumber(value);
    // Optional conversion.
    if (typeof convert === 'function') {
      value = convert(value, this.locale, Object.values(convertParams || {})); // Destructures params
    }
    let numberFormatOptions = {};
    if (options) {
      const {
        digits,
        ...rest
      } = options;
      if (digits) {
        numberFormatOptions = {
          ...numberFormatOptions,
          ...parseDigits(digits)
        };
      }
      numberFormatOptions = {
        ...numberFormatOptions,
        ...rest
      };
    }
    if (currency) numberFormatOptions.currency = currency;
    return new Intl.NumberFormat(language, numberFormatOptions).format(value);
  }
  /**
   * Formats a relative time.
   * @param value A negative (or positive) number
   * @param unit An Intl RelativeTimeFormatUnit value
   * @param options An Intl RelativeTimeFormatOptions object
   * @param language The current language
   */
  formatRelativeTime(value, unit, options, language = this.locale.dateLanguage || this.locale.language) {
    value = toNumber(value);
    return new Intl.RelativeTimeFormat(language, options).format(value, unit);
  }
  /**
   * Gets the plural by a number.
   * The 'value' is passed as a parameter to the translation function.
   * @param value The number to get the plural
   * @param prefix Optional prefix for the key
   * @param options An Intl PluralRulesOptions object
   * @param language The current language
   */
  plural(value, prefix = '', options, language = this.locale.language) {
    value = toNumber(value);
    const rule = new Intl.PluralRules(language, options).select(value);
    const key = prefix ? `${prefix}${this.config.keySeparator}${rule}` : rule;
    return this.translation.translate(key, {
      value
    });
  }
  /**
   * Returns translation of language, region, script or currency display names
   * @param code ISO code of language, region, script or currency
   * @param options An Intl DisplayNamesOptions object
   * @param language The current language
   */
  displayNames(code, options, language = this.locale.language) {
    return new Intl.DisplayNames(language, options).of(code) || code;
  }
  getCurrencySymbol(locale = this.locale) {
    const decimal = this.formatNumber(0, {
      digits: '1.0-0'
    }, locale.numberLanguage || locale.language);
    const currency = this.formatNumber(0, {
      digits: '1.0-0',
      style: 'currency',
      currencyDisplay: 'symbol'
    }, locale.numberLanguage || locale.language, locale.currency);
    let symbol = currency.replace(decimal, '');
    symbol = symbol.trim();
    return symbol;
  }
  /**
   * Compares two keys by the value of translation.
   * @param key1 First key to compare
   * @param key1 Second key to compare
   * @param options An Intl CollatorOptions object
   * @param language The current language
   * @return A negative value if the value of translation of key1 comes before the value of translation of key2;
   *         a positive value if key1 comes after key2;
   *         0 if they are considered equal or Intl.Collator is not supported
   */
  compare(key1, key2, options, language = this.locale.language) {
    const value1 = this.translation.translate(key1);
    const value2 = this.translation.translate(key2);
    return new Intl.Collator(language, options).compare(value1, value2);
  }
  /**
   * Returns the representation of a list.
   * @param list An array of keys
   * @param options An Intl ListFormatOptions object
   * @param language The current language
   */
  list(list, options, language = this.locale.language) {
    const values = list.map(key => this.translation.translate(key));
    if (language == null || language === '') return values.join(', ');
    return new Intl.ListFormat(language, options).format(values);
  }
  static {
    this.ɵfac = function L10nIntlService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nIntlService)(i0.ɵɵinject(L10N_CONFIG), i0.ɵɵinject(L10N_LOCALE), i0.ɵɵinject(L10nTranslationService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nIntlService,
      factory: L10nIntlService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nIntlService, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [L10N_CONFIG]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [L10N_LOCALE]
      }]
    }, {
      type: L10nTranslationService
    }];
  }, null);
})();

/**
 * Implement this class-interface to create a validation service.
 */
class L10nValidation {
  static {
    this.ɵfac = function L10nValidation_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nValidation)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nValidation,
      factory: L10nValidation.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nValidation, [{
    type: Injectable
  }], null, null);
})();
class L10nDefaultValidation {
  constructor(locale) {
    this.locale = locale;
  }
  parseNumber(value, options, language = this.locale.numberLanguage || this.locale.language) {
    return null;
  }
  parseDate(value, options, language = this.locale.dateLanguage || this.locale.language) {
    return null;
  }
  static {
    this.ɵfac = function L10nDefaultValidation_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDefaultValidation)(i0.ɵɵinject(L10N_LOCALE));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: L10nDefaultValidation,
      factory: L10nDefaultValidation.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDefaultValidation, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [L10N_LOCALE]
      }]
    }];
  }, null);
})();
function initL10n(translation) {
  return () => translation.init();
}
function provideL10nTranslation(config, token = {}) {
  return makeEnvironmentProviders([L10nTranslationService, L10nCache, {
    provide: L10N_CONFIG,
    useValue: config
  }, {
    provide: L10N_LOCALE,
    useValue: {
      language: '',
      units: {}
    }
  }, {
    provide: L10nStorage,
    useClass: token.storage || L10nDefaultStorage
  }, {
    provide: L10nLocaleResolver,
    useClass: token.localeResolver || L10nDefaultLocaleResolver
  }, {
    provide: L10nTranslationFallback,
    useClass: token.translationFallback || L10nDefaultTranslationFallback
  }, {
    provide: L10nTranslationLoader,
    useClass: token.translationLoader || L10nDefaultTranslationLoader
  }, {
    provide: L10nTranslationHandler,
    useClass: token.translationHandler || L10nDefaultTranslationHandler
  }, {
    provide: L10nMissingTranslationHandler,
    useClass: token.missingTranslationHandler || L10nDefaultMissingTranslationHandler
  }, {
    provide: L10nLoader,
    useClass: token.loader || L10nDefaultLoader
  }, {
    provide: APP_INITIALIZER,
    useFactory: initL10n,
    deps: [L10nLoader],
    multi: true
  }]);
}
function provideL10nIntl() {
  return makeEnvironmentProviders([L10nIntlService]);
}
function provideL10nValidation(token = {}) {
  return makeEnvironmentProviders([{
    provide: L10nValidation,
    useClass: token.validation || L10nDefaultValidation
  }]);
}
class L10nTranslatePipe {
  constructor(translation) {
    this.translation = translation;
  }
  transform(key, language, params) {
    if (key == null || key === '') return null;
    return this.translation.translate(key, params, language);
  }
  static {
    this.ɵfac = function L10nTranslatePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nTranslatePipe)(i0.ɵɵdirectiveInject(L10nTranslationService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "translate",
      type: L10nTranslatePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nTranslatePipe, [{
    type: Pipe,
    args: [{
      name: 'translate',
      pure: true,
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nTranslationService
    }];
  }, null);
})();
class L10nTranslateAsyncPipe extends L10nAsyncPipe {
  transform(key, params, language) {
    if (key == null || key === '') return null;
    return this.translation.translate(key, params, language);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵL10nTranslateAsyncPipe_BaseFactory;
      return function L10nTranslateAsyncPipe_Factory(__ngFactoryType__) {
        return (ɵL10nTranslateAsyncPipe_BaseFactory || (ɵL10nTranslateAsyncPipe_BaseFactory = i0.ɵɵgetInheritedFactory(L10nTranslateAsyncPipe)))(__ngFactoryType__ || L10nTranslateAsyncPipe);
      };
    })();
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "translateAsync",
      type: L10nTranslateAsyncPipe,
      pure: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nTranslateAsyncPipe, [{
    type: Pipe,
    args: [{
      name: 'translateAsync',
      pure: false,
      standalone: true
    }]
  }], null, null);
})();
class L10nTranslateDirective extends L10nDirective {
  set l10nTranslate(params) {
    if (params) this.params = params;
  }
  set translate(params) {
    if (params) this.params = params;
  }
  getValue(text) {
    return this.translation.translate(text, this.params, this.language);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵL10nTranslateDirective_BaseFactory;
      return function L10nTranslateDirective_Factory(__ngFactoryType__) {
        return (ɵL10nTranslateDirective_BaseFactory || (ɵL10nTranslateDirective_BaseFactory = i0.ɵɵgetInheritedFactory(L10nTranslateDirective)))(__ngFactoryType__ || L10nTranslateDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: L10nTranslateDirective,
      selectors: [["", "l10nTranslate", ""], ["", "translate", ""]],
      inputs: {
        l10nTranslate: "l10nTranslate",
        translate: "translate",
        params: "params"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nTranslateDirective, [{
    type: Directive,
    args: [{
      selector: '[l10nTranslate],[translate]',
      standalone: true
    }]
  }], null, {
    l10nTranslate: [{
      type: Input
    }],
    translate: [{
      type: Input
    }],
    params: [{
      type: Input
    }]
  });
})();
class L10nTranslationModule {
  static forRoot(config, token = {}) {
    return {
      ngModule: L10nTranslationModule,
      providers: [L10nTranslationService, L10nCache, {
        provide: L10N_CONFIG,
        useValue: config
      }, {
        provide: L10N_LOCALE,
        useValue: {
          language: '',
          units: {}
        }
      }, {
        provide: L10nStorage,
        useClass: token.storage || L10nDefaultStorage
      }, {
        provide: L10nLocaleResolver,
        useClass: token.localeResolver || L10nDefaultLocaleResolver
      }, {
        provide: L10nTranslationFallback,
        useClass: token.translationFallback || L10nDefaultTranslationFallback
      }, {
        provide: L10nTranslationLoader,
        useClass: token.translationLoader || L10nDefaultTranslationLoader
      }, {
        provide: L10nTranslationHandler,
        useClass: token.translationHandler || L10nDefaultTranslationHandler
      }, {
        provide: L10nMissingTranslationHandler,
        useClass: token.missingTranslationHandler || L10nDefaultMissingTranslationHandler
      }, {
        provide: L10nLoader,
        useClass: token.loader || L10nDefaultLoader
      }, {
        provide: APP_INITIALIZER,
        useFactory: initL10n,
        deps: [L10nLoader],
        multi: true
      }]
    };
  }
  static {
    this.ɵfac = function L10nTranslationModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nTranslationModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: L10nTranslationModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nTranslationModule, [{
    type: NgModule,
    args: [{
      imports: [L10nTranslatePipe, L10nTranslateAsyncPipe, L10nTranslateDirective],
      exports: [L10nTranslatePipe, L10nTranslateAsyncPipe, L10nTranslateDirective]
    }]
  }], null, null);
})();
class L10nDatePipe {
  constructor(intl) {
    this.intl = intl;
  }
  transform(value, language, options, timezone) {
    if (value == null || value === '') return null;
    return this.intl.formatDate(value, options, language, timezone);
  }
  static {
    this.ɵfac = function L10nDatePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDatePipe)(i0.ɵɵdirectiveInject(L10nIntlService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "l10nDate",
      type: L10nDatePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDatePipe, [{
    type: Pipe,
    args: [{
      name: 'l10nDate',
      pure: true,
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, null);
})();
class L10nDateAsyncPipe extends L10nAsyncPipe {
  constructor(intl) {
    super();
    this.intl = intl;
  }
  transform(value, options, timezone, language) {
    if (value == null || value === '') return null;
    return this.intl.formatDate(value, options, language, timezone);
  }
  static {
    this.ɵfac = function L10nDateAsyncPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDateAsyncPipe)(i0.ɵɵdirectiveInject(L10nIntlService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "l10nDateAsync",
      type: L10nDateAsyncPipe,
      pure: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDateAsyncPipe, [{
    type: Pipe,
    args: [{
      name: 'l10nDateAsync',
      pure: false,
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, null);
})();
class L10nNumberPipe {
  constructor(intl) {
    this.intl = intl;
  }
  transform(value, language, options, currency, convert, convertParams) {
    if (value == null || value === '') return null;
    return this.intl.formatNumber(value, options, language, currency, convert, convertParams);
  }
  static {
    this.ɵfac = function L10nNumberPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nNumberPipe)(i0.ɵɵdirectiveInject(L10nIntlService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "l10nNumber",
      type: L10nNumberPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nNumberPipe, [{
    type: Pipe,
    args: [{
      name: 'l10nNumber',
      pure: true,
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, null);
})();
class L10nNumberAsyncPipe extends L10nAsyncPipe {
  constructor(intl) {
    super();
    this.intl = intl;
  }
  transform(value, options, convert, convertParams, language, currency) {
    if (value == null || value === '') return null;
    return this.intl.formatNumber(value, options, language, currency, convert, convertParams);
  }
  static {
    this.ɵfac = function L10nNumberAsyncPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nNumberAsyncPipe)(i0.ɵɵdirectiveInject(L10nIntlService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "l10nNumberAsync",
      type: L10nNumberAsyncPipe,
      pure: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nNumberAsyncPipe, [{
    type: Pipe,
    args: [{
      name: 'l10nNumberAsync',
      pure: false,
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, null);
})();
class L10nTimeAgoPipe {
  constructor(intl) {
    this.intl = intl;
  }
  transform(value, language, unit, options) {
    if (value == null || value === '') return null;
    return this.intl.formatRelativeTime(value, unit, options, language);
  }
  static {
    this.ɵfac = function L10nTimeAgoPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nTimeAgoPipe)(i0.ɵɵdirectiveInject(L10nIntlService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "l10nTimeAgo",
      type: L10nTimeAgoPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nTimeAgoPipe, [{
    type: Pipe,
    args: [{
      name: 'l10nTimeAgo',
      pure: true,
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, null);
})();
class L10nTimeAgoAsyncPipe extends L10nAsyncPipe {
  constructor(intl) {
    super();
    this.intl = intl;
  }
  transform(value, unit, options, language) {
    if (value == null || value === '') return null;
    return this.intl.formatRelativeTime(value, unit, options, language);
  }
  static {
    this.ɵfac = function L10nTimeAgoAsyncPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nTimeAgoAsyncPipe)(i0.ɵɵdirectiveInject(L10nIntlService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "l10nTimeAgoAsync",
      type: L10nTimeAgoAsyncPipe,
      pure: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nTimeAgoAsyncPipe, [{
    type: Pipe,
    args: [{
      name: 'l10nTimeAgoAsync',
      pure: false,
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, null);
})();
class L10nPluralPipe {
  constructor(intl) {
    this.intl = intl;
  }
  transform(value, language, prefix, options) {
    if (value == null || value === '') return null;
    return this.intl.plural(value, prefix, options, language);
  }
  static {
    this.ɵfac = function L10nPluralPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nPluralPipe)(i0.ɵɵdirectiveInject(L10nIntlService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "l10nPlural",
      type: L10nPluralPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nPluralPipe, [{
    type: Pipe,
    args: [{
      name: 'l10nPlural',
      pure: true,
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, null);
})();
class L10nPluralAsyncPipe extends L10nAsyncPipe {
  constructor(intl) {
    super();
    this.intl = intl;
  }
  transform(value, prefix, options, language) {
    if (value == null || value === '') return null;
    return this.intl.plural(value, prefix, options, language);
  }
  static {
    this.ɵfac = function L10nPluralAsyncPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nPluralAsyncPipe)(i0.ɵɵdirectiveInject(L10nIntlService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "l10nPluralAsync",
      type: L10nPluralAsyncPipe,
      pure: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nPluralAsyncPipe, [{
    type: Pipe,
    args: [{
      name: 'l10nPluralAsync',
      pure: false,
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, null);
})();
class L10nDisplayNamesPipe {
  constructor(intl) {
    this.intl = intl;
  }
  transform(value, language, options) {
    if (value == null || value === '') return null;
    return this.intl.displayNames(value, options, language);
  }
  static {
    this.ɵfac = function L10nDisplayNamesPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDisplayNamesPipe)(i0.ɵɵdirectiveInject(L10nIntlService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "l10nDisplayNames",
      type: L10nDisplayNamesPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDisplayNamesPipe, [{
    type: Pipe,
    args: [{
      name: 'l10nDisplayNames',
      pure: true,
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, null);
})();
class L10nDisplayNamesAsyncPipe extends L10nAsyncPipe {
  constructor(intl) {
    super();
    this.intl = intl;
  }
  transform(value, options, language) {
    if (value == null || value === '') return null;
    return this.intl.displayNames(value, options, language);
  }
  static {
    this.ɵfac = function L10nDisplayNamesAsyncPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDisplayNamesAsyncPipe)(i0.ɵɵdirectiveInject(L10nIntlService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "l10nDisplayNamesAsync",
      type: L10nDisplayNamesAsyncPipe,
      pure: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDisplayNamesAsyncPipe, [{
    type: Pipe,
    args: [{
      name: 'l10nDisplayNamesAsync',
      pure: false,
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, null);
})();
class L10nDateDirective extends L10nDirective {
  set l10nDate(options) {
    if (options) this.options = options;
  }
  constructor(intl) {
    super();
    this.intl = intl;
  }
  getValue(text) {
    return this.intl.formatDate(text, this.options, this.language, this.timezone);
  }
  static {
    this.ɵfac = function L10nDateDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDateDirective)(i0.ɵɵdirectiveInject(L10nIntlService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: L10nDateDirective,
      selectors: [["", "l10nDate", ""]],
      inputs: {
        l10nDate: "l10nDate",
        options: "options",
        timezone: "timezone"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDateDirective, [{
    type: Directive,
    args: [{
      selector: '[l10nDate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, {
    l10nDate: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    timezone: [{
      type: Input
    }]
  });
})();
class L10nNumberDirective extends L10nDirective {
  set l10nNumber(options) {
    if (options) this.options = options;
  }
  constructor(intl) {
    super();
    this.intl = intl;
  }
  getValue(text) {
    return this.intl.formatNumber(text, this.options, this.language, this.currency, this.convert, this.convertParams);
  }
  static {
    this.ɵfac = function L10nNumberDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nNumberDirective)(i0.ɵɵdirectiveInject(L10nIntlService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: L10nNumberDirective,
      selectors: [["", "l10nNumber", ""]],
      inputs: {
        l10nNumber: "l10nNumber",
        options: "options",
        currency: "currency",
        convert: "convert",
        convertParams: "convertParams"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nNumberDirective, [{
    type: Directive,
    args: [{
      selector: '[l10nNumber]',
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, {
    l10nNumber: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    currency: [{
      type: Input
    }],
    convert: [{
      type: Input
    }],
    convertParams: [{
      type: Input
    }]
  });
})();
class L10nTimeAgoDirective extends L10nDirective {
  set l10nTimeAgo(options) {
    if (options) this.options = options;
  }
  constructor(intl) {
    super();
    this.intl = intl;
  }
  getValue(text) {
    return this.intl.formatRelativeTime(text, this.unit, this.options, this.language);
  }
  static {
    this.ɵfac = function L10nTimeAgoDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nTimeAgoDirective)(i0.ɵɵdirectiveInject(L10nIntlService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: L10nTimeAgoDirective,
      selectors: [["", "l10nTimeAgo", ""]],
      inputs: {
        l10nTimeAgo: "l10nTimeAgo",
        unit: "unit",
        options: "options"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nTimeAgoDirective, [{
    type: Directive,
    args: [{
      selector: '[l10nTimeAgo]',
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, {
    l10nTimeAgo: [{
      type: Input
    }],
    unit: [{
      type: Input
    }],
    options: [{
      type: Input
    }]
  });
})();
class L10nPluralDirective extends L10nDirective {
  set l10nPlural(options) {
    if (options) this.options = options;
  }
  constructor(intl) {
    super();
    this.intl = intl;
  }
  getValue(text) {
    return this.intl.plural(text, this.prefix, this.options, this.language);
  }
  static {
    this.ɵfac = function L10nPluralDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nPluralDirective)(i0.ɵɵdirectiveInject(L10nIntlService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: L10nPluralDirective,
      selectors: [["", "l10nPlural", ""]],
      inputs: {
        l10nPlural: "l10nPlural",
        prefix: "prefix",
        options: "options"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nPluralDirective, [{
    type: Directive,
    args: [{
      selector: '[l10nPlural]',
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, {
    l10nPlural: [{
      type: Input
    }],
    prefix: [{
      type: Input
    }],
    options: [{
      type: Input
    }]
  });
})();
class L10nDisplayNamesDirective extends L10nDirective {
  set l10nDisplayNames(options) {
    if (options) this.options = options;
  }
  constructor(intl) {
    super();
    this.intl = intl;
  }
  getValue(text) {
    return this.intl.displayNames(text, this.options, this.language);
  }
  static {
    this.ɵfac = function L10nDisplayNamesDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nDisplayNamesDirective)(i0.ɵɵdirectiveInject(L10nIntlService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: L10nDisplayNamesDirective,
      selectors: [["", "l10nDisplayNames", ""]],
      inputs: {
        l10nDisplayNames: "l10nDisplayNames",
        options: "options"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nDisplayNamesDirective, [{
    type: Directive,
    args: [{
      selector: '[l10nDisplayNames]',
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nIntlService
    }];
  }, {
    l10nDisplayNames: [{
      type: Input
    }],
    options: [{
      type: Input
    }]
  });
})();
class L10nIntlModule {
  static {
    this.ɵfac = function L10nIntlModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nIntlModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: L10nIntlModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [L10nIntlService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nIntlModule, [{
    type: NgModule,
    args: [{
      imports: [L10nDatePipe, L10nNumberPipe, L10nTimeAgoPipe, L10nPluralPipe, L10nDisplayNamesPipe, L10nDateAsyncPipe, L10nNumberAsyncPipe, L10nTimeAgoAsyncPipe, L10nPluralAsyncPipe, L10nDisplayNamesAsyncPipe, L10nDateDirective, L10nNumberDirective, L10nTimeAgoDirective, L10nPluralDirective, L10nDisplayNamesDirective],
      exports: [L10nDatePipe, L10nNumberPipe, L10nTimeAgoPipe, L10nPluralPipe, L10nDisplayNamesPipe, L10nDateAsyncPipe, L10nNumberAsyncPipe, L10nTimeAgoAsyncPipe, L10nPluralAsyncPipe, L10nDisplayNamesAsyncPipe, L10nDateDirective, L10nNumberDirective, L10nTimeAgoDirective, L10nPluralDirective, L10nDisplayNamesDirective],
      providers: [L10nIntlService]
    }]
  }], null, null);
})();

/**
 * Function that takes a control and returns either null when it’s valid, or an error object if it’s not.
 * @param validation The instance of L10nValidation service
 * @param options A L10n or Intl NumberFormatOptions object
 * @param minValue The minimum value
 * @param maxValue The maximum value
 * @param language The current language
 * @return An error object: 'format', 'minValue' or 'maxValue'; null in case the value is valid
 */
function l10nValidateNumber(validation, options, minValue = Number.MIN_VALUE, maxValue = Number.MAX_VALUE, language) {
  const validator = c => {
    if (c.value === '' || c.value == null) return null;
    const value = validation.parseNumber(c.value, options, language);
    if (value != null) {
      if (value < minValue) {
        return {
          minValue: true
        };
      } else if (value > maxValue) {
        return {
          maxValue: true
        };
      }
      return null; // The number is valid.
    } else {
      return {
        format: true
      };
    }
  };
  return validator;
}
class L10nValidateNumberDirective {
  set l10nValidateNumber(options) {
    if (options) this.options = options;
  }
  constructor(validation) {
    this.validation = validation;
  }
  ngOnInit() {
    this.validator = l10nValidateNumber(this.validation, this.options, this.minValue, this.maxValue, this.language);
  }
  ngOnChanges() {
    this.validator = l10nValidateNumber(this.validation, this.options, this.minValue, this.maxValue, this.language);
  }
  validate(c) {
    return this.validator ? this.validator(c) : null;
  }
  static {
    this.ɵfac = function L10nValidateNumberDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nValidateNumberDirective)(i0.ɵɵdirectiveInject(L10nValidation));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: L10nValidateNumberDirective,
      selectors: [["", "l10nValidateNumber", "", "ngModel", ""], ["", "l10nValidateNumber", "", "formControl", ""], ["", "l10nValidateNumber", "", "formControlName", ""]],
      inputs: {
        l10nValidateNumber: "l10nValidateNumber",
        options: "options",
        minValue: "minValue",
        maxValue: "maxValue",
        language: "language"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => L10nValidateNumberDirective),
        multi: true
      }]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nValidateNumberDirective, [{
    type: Directive,
    args: [{
      selector: '[l10nValidateNumber][ngModel],[l10nValidateNumber][formControl],[l10nValidateNumber][formControlName]',
      providers: [{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => L10nValidateNumberDirective),
        multi: true
      }],
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nValidation
    }];
  }, {
    l10nValidateNumber: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    minValue: [{
      type: Input
    }],
    maxValue: [{
      type: Input
    }],
    language: [{
      type: Input
    }]
  });
})();

/**
 * Function that takes a control and returns either null when it’s valid, or an error object if it’s not.
 * @param validation The instance of L10nValidation service
 * @param options A L10n or Intl DateTimeFormatOptions object
 * @param minDate The minimum date
 * @param maxDate The maximum date
 * @param language The current language
 * @return An error object: 'format', 'minDate' or 'maxDate'; null in case the date is valid
 */
function l10nValidateDate(validation, options, minDate, maxDate, language) {
  const validator = c => {
    if (c.value === '' || c.value == null) return null;
    const date = validation.parseDate(c.value, options, language);
    if (date != null) {
      if (minDate && date < minDate) {
        return {
          mindate: true
        };
      } else if (maxDate && date > maxDate) {
        return {
          maxDate: true
        };
      }
      return null; // The date is valid.
    } else {
      return {
        format: true
      };
    }
  };
  return validator;
}
class L10nValidateDateDirective {
  set l10nValidateDate(options) {
    if (options) this.options = options;
  }
  constructor(validation) {
    this.validation = validation;
  }
  ngOnInit() {
    this.validator = l10nValidateDate(this.validation, this.options, this.minDate, this.maxDate, this.language);
  }
  ngOnChanges() {
    this.validator = l10nValidateDate(this.validation, this.options, this.minDate, this.maxDate, this.language);
  }
  validate(c) {
    return this.validator ? this.validator(c) : null;
  }
  static {
    this.ɵfac = function L10nValidateDateDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nValidateDateDirective)(i0.ɵɵdirectiveInject(L10nValidation));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: L10nValidateDateDirective,
      selectors: [["", "l10nValidateDate", "", "ngModel", ""], ["", "l10nValidateDate", "", "formControl", ""], ["", "l10nValidateDate", "", "formControlName", ""]],
      inputs: {
        l10nValidateDate: "l10nValidateDate",
        options: "options",
        minDate: "minDate",
        maxDate: "maxDate",
        language: "language"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => L10nValidateDateDirective),
        multi: true
      }]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nValidateDateDirective, [{
    type: Directive,
    args: [{
      selector: '[l10nValidateDate][ngModel],[l10nValidateDate][formControl],[l10nValidateDate][formControlName]',
      providers: [{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => L10nValidateDateDirective),
        multi: true
      }],
      standalone: true
    }]
  }], function () {
    return [{
      type: L10nValidation
    }];
  }, {
    l10nValidateDate: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    minDate: [{
      type: Input
    }],
    maxDate: [{
      type: Input
    }],
    language: [{
      type: Input
    }]
  });
})();
class L10nValidationModule {
  static forRoot(token = {}) {
    return {
      ngModule: L10nValidationModule,
      providers: [{
        provide: L10nValidation,
        useClass: token.validation || L10nDefaultValidation
      }]
    };
  }
  static {
    this.ɵfac = function L10nValidationModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || L10nValidationModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: L10nValidationModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(L10nValidationModule, [{
    type: NgModule,
    args: [{
      imports: [L10nValidateNumberDirective, L10nValidateDateDirective],
      exports: [L10nValidateNumberDirective, L10nValidateDateDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of angular-l10n
 */
// Models

/**
 * Generated bundle index. Do not edit.
 */

export { L10N_CONFIG, L10N_LOCALE, L10nAsyncPipe, L10nCache, L10nDateAsyncPipe, L10nDateDirective, L10nDatePipe, L10nDirective, L10nDisplayNamesAsyncPipe, L10nDisplayNamesDirective, L10nDisplayNamesPipe, L10nIntlModule, L10nIntlService, L10nLoader, L10nLocaleResolver, L10nMissingTranslationHandler, L10nNumberAsyncPipe, L10nNumberDirective, L10nNumberPipe, L10nPluralAsyncPipe, L10nPluralDirective, L10nPluralPipe, L10nStorage, L10nTimeAgoAsyncPipe, L10nTimeAgoDirective, L10nTimeAgoPipe, L10nTranslateAsyncPipe, L10nTranslateDirective, L10nTranslatePipe, L10nTranslationFallback, L10nTranslationHandler, L10nTranslationLoader, L10nTranslationModule, L10nTranslationService, L10nValidateDateDirective, L10nValidateNumberDirective, L10nValidation, L10nValidationModule, PARSE_DATE_STYLE, PARSE_TIME_STYLE, formatLanguage, getBrowserLanguage, getSchema, getValue, handleParams, l10nValidateDate, l10nValidateNumber, mergeDeep, parseDigits, parseLanguage, provideL10nIntl, provideL10nTranslation, provideL10nValidation, resolveL10n, toDate, toNumber, validateLanguage };
