{
    "name": "Cities",
    "type": "folder",
    "contents": [
        {
            "name": "Asia",
            "type": "folder",
            "contents": [
                {
                    "name": "China",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Beijing",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Beijing_day",
                                    "city": "Beijing",
                                    "original": "Asia/China/Beijing/Beijing_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/China/Beijing/Beijing_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/China/Beijing/Beijing_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/China/Beijing/Beijing_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/China/Beijing/Beijing_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Beijing_night",
                                    "city": "Beijing",
                                    "original": "Asia/China/Beijing/Beijing_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/China/Beijing/Beijing_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/China/Beijing/Beijing_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/China/Beijing/Beijing_night.cropped.jpeg",
                                            "width": 5000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/China/Beijing/Beijing_night.jpeg",
                                            "width": 5000
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Hong Kong",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Hong Kong_day",
                                    "city": "Hong Kong",
                                    "original": "Asia/China/Hong Kong/Hong Kong_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/China/Hong Kong/Hong Kong_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/China/Hong Kong/Hong Kong_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/China/Hong Kong/Hong Kong_day.cropped.jpeg",
                                            "width": 4500
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/China/Hong Kong/Hong Kong_day.jpeg",
                                            "width": 6194
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Hong Kong_day1",
                                    "city": "Hong Kong",
                                    "original": "Asia/China/Hong Kong/Hong Kong_day1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/China/Hong Kong/Hong Kong_day1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/China/Hong Kong/Hong Kong_day1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/China/Hong Kong/Hong Kong_day1.cropped.jpeg",
                                            "width": 3000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/China/Hong Kong/Hong Kong_day1.jpeg",
                                            "width": 3000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Hong Kong_night",
                                    "city": "Hong Kong",
                                    "original": "Asia/China/Hong Kong/Hong Kong_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/China/Hong Kong/Hong Kong_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/China/Hong Kong/Hong Kong_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/China/Hong Kong/Hong Kong_night.cropped.jpeg",
                                            "width": 5472
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/China/Hong Kong/Hong Kong_night.jpeg",
                                            "width": 5472
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Nanjing",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Nanjing_day",
                                    "city": "Nanjing",
                                    "original": "Asia/China/Nanjing/Nanjing_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/China/Nanjing/Nanjing_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/China/Nanjing/Nanjing_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/China/Nanjing/Nanjing_day.cropped.jpeg",
                                            "width": 6787
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/China/Nanjing/Nanjing_day.jpeg",
                                            "width": 6787
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Nanjing_night",
                                    "city": "Nanjing",
                                    "original": "Asia/China/Nanjing/Nanjing_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/China/Nanjing/Nanjing_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/China/Nanjing/Nanjing_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/China/Nanjing/Nanjing_night.cropped.jpeg",
                                            "width": 7952
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/China/Nanjing/Nanjing_night.jpeg",
                                            "width": 7952
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Shanghai",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Shanghai_day",
                                    "city": "Shanghai",
                                    "original": "Asia/China/Shanghai/Shanghai_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/China/Shanghai/Shanghai_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/China/Shanghai/Shanghai_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/China/Shanghai/Shanghai_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/China/Shanghai/Shanghai_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Shanghai_night",
                                    "city": "Shanghai",
                                    "original": "Asia/China/Shanghai/Shanghai_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/China/Shanghai/Shanghai_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/China/Shanghai/Shanghai_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/China/Shanghai/Shanghai_night.cropped.jpeg",
                                            "width": 6257
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/China/Shanghai/Shanghai_night.jpeg",
                                            "width": 6720
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "India",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Dehli",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Dehli_Gurgaon_night",
                                    "city": "Dehli",
                                    "original": "Asia/India/Dehli/Dehli_Gurgaon_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/India/Dehli/Dehli_Gurgaon_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/India/Dehli/Dehli_Gurgaon_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/India/Dehli/Dehli_Gurgaon_night.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/India/Dehli/Dehli_Gurgaon_night.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Dehli_day",
                                    "city": "Dehli",
                                    "original": "Asia/India/Dehli/Dehli_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/India/Dehli/Dehli_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/India/Dehli/Dehli_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/India/Dehli/Dehli_day.cropped.jpeg",
                                            "width": 7119
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/India/Dehli/Dehli_day.jpeg",
                                            "width": 7119
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Mumbai",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Mumbabi_night",
                                    "city": "Mumbai",
                                    "original": "Asia/India/Mumbai/Mumbabi_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/India/Mumbai/Mumbabi_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/India/Mumbai/Mumbabi_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/India/Mumbai/Mumbabi_night.cropped.jpeg",
                                            "width": 5063
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/India/Mumbai/Mumbabi_night.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Mumbai_sunset",
                                    "city": "Mumbai",
                                    "original": "Asia/India/Mumbai/Mumbai_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/India/Mumbai/Mumbai_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/India/Mumbai/Mumbai_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/India/Mumbai/Mumbai_sunset.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/India/Mumbai/Mumbai_sunset.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Mumbay_day1",
                                    "city": "Mumbai",
                                    "original": "Asia/India/Mumbai/Mumbay_day1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/India/Mumbai/Mumbay_day1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/India/Mumbai/Mumbay_day1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/India/Mumbai/Mumbay_day1.cropped.jpeg",
                                            "width": 5268
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/India/Mumbai/Mumbay_day1.jpeg",
                                            "width": 5619
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Indonesia",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Jakarta",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Jakarta_day",
                                    "city": "Jakarta",
                                    "original": "Asia/Indonesia/Jakarta/Jakarta_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Indonesia/Jakarta/Jakarta_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Indonesia/Jakarta/Jakarta_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Indonesia/Jakarta/Jakarta_day.cropped.jpeg",
                                            "width": 6069
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Indonesia/Jakarta/Jakarta_day.jpeg",
                                            "width": 7361
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Jakarta_night",
                                    "city": "Jakarta",
                                    "original": "Asia/Indonesia/Jakarta/Jakarta_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Indonesia/Jakarta/Jakarta_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Indonesia/Jakarta/Jakarta_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Indonesia/Jakarta/Jakarta_night.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Indonesia/Jakarta/Jakarta_night.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Israel",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Tel Aviv",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Tel Aviv_day",
                                    "city": "Tel Aviv",
                                    "original": "Asia/Israel/Tel Aviv/Tel Aviv_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Israel/Tel Aviv/Tel Aviv_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Israel/Tel Aviv/Tel Aviv_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Israel/Tel Aviv/Tel Aviv_day.cropped.jpeg",
                                            "width": 7000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Israel/Tel Aviv/Tel Aviv_day.jpeg",
                                            "width": 7000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Tel Aviv_sunset",
                                    "city": "Tel Aviv",
                                    "original": "Asia/Israel/Tel Aviv/Tel Aviv_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Israel/Tel Aviv/Tel Aviv_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Israel/Tel Aviv/Tel Aviv_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Israel/Tel Aviv/Tel Aviv_sunset.cropped.jpeg",
                                            "width": 5000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Israel/Tel Aviv/Tel Aviv_sunset.jpeg",
                                            "width": 5000
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Japan",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Fukuoka",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Fukuoka_day",
                                    "city": "Fukuoka",
                                    "original": "Asia/Japan/Fukuoka/Fukuoka_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Fukuoka/Fukuoka_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Fukuoka/Fukuoka_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Fukuoka/Fukuoka_day.cropped.jpeg",
                                            "width": 4989
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Fukuoka/Fukuoka_day.jpeg",
                                            "width": 4989
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Fukuoka_night",
                                    "city": "Fukuoka",
                                    "original": "Asia/Japan/Fukuoka/Fukuoka_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Fukuoka/Fukuoka_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Fukuoka/Fukuoka_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Fukuoka/Fukuoka_night.cropped.jpeg",
                                            "width": 7355
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Fukuoka/Fukuoka_night.jpeg",
                                            "width": 7355
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Kyoto",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Kyoto_day",
                                    "city": "Kyoto",
                                    "original": "Asia/Japan/Kyoto/Kyoto_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Kyoto/Kyoto_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Kyoto/Kyoto_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Kyoto/Kyoto_day.cropped.jpeg",
                                            "width": 7952
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Kyoto/Kyoto_day.jpeg",
                                            "width": 7952
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Kyoto_day1",
                                    "city": "Kyoto",
                                    "original": "Asia/Japan/Kyoto/Kyoto_day1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Kyoto/Kyoto_day1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Kyoto/Kyoto_day1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Kyoto/Kyoto_day1.cropped.jpeg",
                                            "width": 5600
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Kyoto/Kyoto_day1.jpeg",
                                            "width": 5600
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Kyoto_night",
                                    "city": "Kyoto",
                                    "original": "Asia/Japan/Kyoto/Kyoto_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Kyoto/Kyoto_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Kyoto/Kyoto_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Kyoto/Kyoto_night.cropped.jpeg",
                                            "width": 5285
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Kyoto/Kyoto_night.jpeg",
                                            "width": 5288
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Kyoto_night1",
                                    "city": "Kyoto",
                                    "original": "Asia/Japan/Kyoto/Kyoto_night1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Kyoto/Kyoto_night1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Kyoto/Kyoto_night1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Kyoto/Kyoto_night1.cropped.jpeg",
                                            "width": 5720
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Kyoto/Kyoto_night1.jpeg",
                                            "width": 5720
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Nagoya",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Nagoya_day",
                                    "city": "Nagoya",
                                    "original": "Asia/Japan/Nagoya/Nagoya_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Nagoya/Nagoya_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Nagoya/Nagoya_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Nagoya/Nagoya_day.cropped.jpeg",
                                            "width": 5063
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Nagoya/Nagoya_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Nagoya_night",
                                    "city": "Nagoya",
                                    "original": "Asia/Japan/Nagoya/Nagoya_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Nagoya/Nagoya_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Nagoya/Nagoya_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Nagoya/Nagoya_night.cropped.jpeg",
                                            "width": 5413
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Nagoya/Nagoya_night.jpeg",
                                            "width": 5413
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Osaka",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Osaka_day",
                                    "city": "Osaka",
                                    "original": "Asia/Japan/Osaka/Osaka_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Osaka/Osaka_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Osaka/Osaka_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Osaka/Osaka_day.cropped.jpeg",
                                            "width": 5600
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Osaka/Osaka_day.jpeg",
                                            "width": 5600
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Osaka_night",
                                    "city": "Osaka",
                                    "original": "Asia/Japan/Osaka/Osaka_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Osaka/Osaka_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Osaka/Osaka_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Osaka/Osaka_night.cropped.jpeg",
                                            "width": 5217
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Osaka/Osaka_night.jpeg",
                                            "width": 5221
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Sendai",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Sendai_day",
                                    "city": "Sendai",
                                    "original": "Asia/Japan/Sendai/Sendai_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Sendai/Sendai_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Sendai/Sendai_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Sendai/Sendai_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Sendai/Sendai_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Sendai_sunset",
                                    "city": "Sendai",
                                    "original": "Asia/Japan/Sendai/Sendai_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Sendai/Sendai_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Sendai/Sendai_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Sendai/Sendai_sunset.cropped.jpeg",
                                            "width": 5000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Sendai/Sendai_sunset.jpeg",
                                            "width": 5000
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Tokyo",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Tokyo_day",
                                    "city": "Tokyo",
                                    "original": "Asia/Japan/Tokyo/Tokyo_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Tokyo/Tokyo_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Tokyo/Tokyo_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Tokyo/Tokyo_day.cropped.jpeg",
                                            "width": 5760
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Tokyo/Tokyo_day.jpeg",
                                            "width": 5760
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Tokyo_sunset",
                                    "city": "Tokyo",
                                    "original": "Asia/Japan/Tokyo/Tokyo_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Japan/Tokyo/Tokyo_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Japan/Tokyo/Tokyo_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Japan/Tokyo/Tokyo_sunset.cropped.jpeg",
                                            "width": 4500
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Japan/Tokyo/Tokyo_sunset.jpeg",
                                            "width": 4500
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Qatar",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Doha",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Doha_day",
                                    "city": "Doha",
                                    "original": "Asia/Qatar/Doha/Doha_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Qatar/Doha/Doha_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Qatar/Doha/Doha_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Qatar/Doha/Doha_day.cropped.jpeg",
                                            "width": 7268
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Qatar/Doha/Doha_day.jpeg",
                                            "width": 7756
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Doha_sunset",
                                    "city": "Doha",
                                    "original": "Asia/Qatar/Doha/Doha_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Qatar/Doha/Doha_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Qatar/Doha/Doha_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Qatar/Doha/Doha_sunset.cropped.jpeg",
                                            "width": 7080
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Qatar/Doha/Doha_sunset.jpeg",
                                            "width": 8500
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Saudi Arabia",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Riyadh",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Riyadh_day",
                                    "city": "Riyadh",
                                    "original": "Asia/Saudi Arabia/Riyadh/Riyadh_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Saudi Arabia/Riyadh/Riyadh_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Saudi Arabia/Riyadh/Riyadh_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Saudi Arabia/Riyadh/Riyadh_day.cropped.jpeg",
                                            "width": 4800
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Saudi Arabia/Riyadh/Riyadh_day.jpeg",
                                            "width": 4800
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Riyadh_night",
                                    "city": "Riyadh",
                                    "original": "Asia/Saudi Arabia/Riyadh/Riyadh_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Saudi Arabia/Riyadh/Riyadh_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Saudi Arabia/Riyadh/Riyadh_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Saudi Arabia/Riyadh/Riyadh_night.cropped.jpeg",
                                            "width": 5546
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Saudi Arabia/Riyadh/Riyadh_night.jpeg",
                                            "width": 7719
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Singapore",
                    "type": "folder",
                    "contents": [
                        {
                            "type": "file",
                            "name": "Singapore_day",
                            "city": "Singapore",
                            "original": "Asia/Singapore/Singapore_day.jpeg",
                            "variants": [
                                {
                                    "variant": "2400",
                                    "path": "Asia/Singapore/Singapore_day.2400.jpeg",
                                    "width": 2400
                                },
                                {
                                    "variant": "600",
                                    "path": "Asia/Singapore/Singapore_day.600.jpeg",
                                    "width": 600
                                },
                                {
                                    "variant": "cropped",
                                    "path": "Asia/Singapore/Singapore_day.cropped.jpeg",
                                    "width": 6100
                                },
                                {
                                    "variant": "original",
                                    "path": "Asia/Singapore/Singapore_day.jpeg",
                                    "width": 6100
                                }
                            ]
                        },
                        {
                            "type": "file",
                            "name": "Singapore_night",
                            "city": "Singapore",
                            "original": "Asia/Singapore/Singapore_night.jpeg",
                            "variants": [
                                {
                                    "variant": "2400",
                                    "path": "Asia/Singapore/Singapore_night.2400.jpeg",
                                    "width": 2400
                                },
                                {
                                    "variant": "600",
                                    "path": "Asia/Singapore/Singapore_night.600.jpeg",
                                    "width": 600
                                },
                                {
                                    "variant": "cropped",
                                    "path": "Asia/Singapore/Singapore_night.cropped.jpeg",
                                    "width": 6099
                                },
                                {
                                    "variant": "original",
                                    "path": "Asia/Singapore/Singapore_night.jpeg",
                                    "width": 6100
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "South Korea",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Seoul",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Seoul_business",
                                    "city": "Seoul",
                                    "original": "Asia/South Korea/Seoul/Seoul_business.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/South Korea/Seoul/Seoul_business.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/South Korea/Seoul/Seoul_business.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/South Korea/Seoul/Seoul_business.cropped.jpeg",
                                            "width": 11752
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/South Korea/Seoul/Seoul_business.jpeg",
                                            "width": 11752
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Seoul_night",
                                    "city": "Seoul",
                                    "original": "Asia/South Korea/Seoul/Seoul_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/South Korea/Seoul/Seoul_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/South Korea/Seoul/Seoul_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/South Korea/Seoul/Seoul_night.cropped.jpeg",
                                            "width": 5000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/South Korea/Seoul/Seoul_night.jpeg",
                                            "width": 5000
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Taiwan",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Taipei",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Taipei_day",
                                    "city": "Taipei",
                                    "original": "Asia/Taiwan/Taipei/Taipei_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Taiwan/Taipei/Taipei_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Taiwan/Taipei/Taipei_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Taiwan/Taipei/Taipei_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Taiwan/Taipei/Taipei_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Taipei_night",
                                    "city": "Taipei",
                                    "original": "Asia/Taiwan/Taipei/Taipei_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Taiwan/Taipei/Taipei_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Taiwan/Taipei/Taipei_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Taiwan/Taipei/Taipei_night.cropped.jpeg",
                                            "width": 7197
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Taiwan/Taipei/Taipei_night.jpeg",
                                            "width": 7238
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Thailand",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Bangkok",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Bangkok_day",
                                    "city": "Bangkok",
                                    "original": "Asia/Thailand/Bangkok/Bangkok_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Thailand/Bangkok/Bangkok_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Thailand/Bangkok/Bangkok_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Thailand/Bangkok/Bangkok_day.cropped.jpeg",
                                            "width": 7446
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Thailand/Bangkok/Bangkok_day.jpeg",
                                            "width": 8353
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Bangkok_night",
                                    "city": "Bangkok",
                                    "original": "Asia/Thailand/Bangkok/Bangkok_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Thailand/Bangkok/Bangkok_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Thailand/Bangkok/Bangkok_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Thailand/Bangkok/Bangkok_night.cropped.jpeg",
                                            "width": 5387
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Thailand/Bangkok/Bangkok_night.jpeg",
                                            "width": 5400
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "UAE",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Abu Dhabi",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Abu Dhabi_day",
                                    "city": "Abu Dhabi",
                                    "original": "Asia/UAE/Abu Dhabi/Abu Dhabi_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/UAE/Abu Dhabi/Abu Dhabi_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/UAE/Abu Dhabi/Abu Dhabi_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/UAE/Abu Dhabi/Abu Dhabi_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/UAE/Abu Dhabi/Abu Dhabi_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Abu Dhabi_night",
                                    "city": "Abu Dhabi",
                                    "original": "Asia/UAE/Abu Dhabi/Abu Dhabi_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/UAE/Abu Dhabi/Abu Dhabi_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/UAE/Abu Dhabi/Abu Dhabi_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/UAE/Abu Dhabi/Abu Dhabi_night.cropped.jpeg",
                                            "width": 5335
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/UAE/Abu Dhabi/Abu Dhabi_night.jpeg",
                                            "width": 5335
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Dubai",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Dubai_day",
                                    "city": "Dubai",
                                    "original": "Asia/UAE/Dubai/Dubai_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/UAE/Dubai/Dubai_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/UAE/Dubai/Dubai_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/UAE/Dubai/Dubai_day.cropped.jpeg",
                                            "width": 6960
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/UAE/Dubai/Dubai_day.jpeg",
                                            "width": 6960
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Dubai_night",
                                    "city": "Dubai",
                                    "original": "Asia/UAE/Dubai/Dubai_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/UAE/Dubai/Dubai_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/UAE/Dubai/Dubai_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/UAE/Dubai/Dubai_night.cropped.jpeg",
                                            "width": 6720
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/UAE/Dubai/Dubai_night.jpeg",
                                            "width": 6720
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Vietnam",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Hanoi",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Hanoi_day",
                                    "city": "Hanoi",
                                    "original": "Asia/Vietnam/Hanoi/Hanoi_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Vietnam/Hanoi/Hanoi_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Vietnam/Hanoi/Hanoi_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Vietnam/Hanoi/Hanoi_day.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Vietnam/Hanoi/Hanoi_day.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Hanoi_night",
                                    "city": "Hanoi",
                                    "original": "Asia/Vietnam/Hanoi/Hanoi_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Asia/Vietnam/Hanoi/Hanoi_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Asia/Vietnam/Hanoi/Hanoi_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Asia/Vietnam/Hanoi/Hanoi_night.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Asia/Vietnam/Hanoi/Hanoi_night.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "name": "Europe",
            "type": "folder",
            "contents": [
                {
                    "name": "Austria",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Vienna",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Vienna_day",
                                    "city": "Vienna",
                                    "original": "Europe/Austria/Vienna/Vienna_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Austria/Vienna/Vienna_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Austria/Vienna/Vienna_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Austria/Vienna/Vienna_day.cropped.jpeg",
                                            "width": 4400
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Austria/Vienna/Vienna_day.jpeg",
                                            "width": 4400
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Vienna_modern",
                                    "city": "Vienna",
                                    "original": "Europe/Austria/Vienna/Vienna_modern.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Austria/Vienna/Vienna_modern.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Austria/Vienna/Vienna_modern.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Austria/Vienna/Vienna_modern.cropped.jpeg",
                                            "width": 5616
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Austria/Vienna/Vienna_modern.jpeg",
                                            "width": 5616
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Vienna_night",
                                    "city": "Vienna",
                                    "original": "Europe/Austria/Vienna/Vienna_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Austria/Vienna/Vienna_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Austria/Vienna/Vienna_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Austria/Vienna/Vienna_night.cropped.jpeg",
                                            "width": 5143
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Austria/Vienna/Vienna_night.jpeg",
                                            "width": 5143
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Vienna_sunset",
                                    "city": "Vienna",
                                    "original": "Europe/Austria/Vienna/Vienna_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Austria/Vienna/Vienna_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Austria/Vienna/Vienna_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Austria/Vienna/Vienna_sunset.cropped.jpeg",
                                            "width": 4978
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Austria/Vienna/Vienna_sunset.jpeg",
                                            "width": 4978
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Belgium",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Antwerpen",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Antwerpen_day",
                                    "city": "Antwerpen",
                                    "original": "Europe/Belgium/Antwerpen/Antwerpen_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Belgium/Antwerpen/Antwerpen_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Belgium/Antwerpen/Antwerpen_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Belgium/Antwerpen/Antwerpen_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Belgium/Antwerpen/Antwerpen_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Antwerpen_evening",
                                    "city": "Antwerpen",
                                    "original": "Europe/Belgium/Antwerpen/Antwerpen_evening.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Belgium/Antwerpen/Antwerpen_evening.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Belgium/Antwerpen/Antwerpen_evening.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Belgium/Antwerpen/Antwerpen_evening.cropped.jpeg",
                                            "width": 6400
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Belgium/Antwerpen/Antwerpen_evening.jpeg",
                                            "width": 6400
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Brussels",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Brussels_day",
                                    "city": "Brussels",
                                    "original": "Europe/Belgium/Brussels/Brussels_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Belgium/Brussels/Brussels_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Belgium/Brussels/Brussels_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Belgium/Brussels/Brussels_day.cropped.jpeg",
                                            "width": 4508
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Belgium/Brussels/Brussels_day.jpeg",
                                            "width": 5543
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Brussels_night",
                                    "city": "Brussels",
                                    "original": "Europe/Belgium/Brussels/Brussels_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Belgium/Brussels/Brussels_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Belgium/Brussels/Brussels_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Belgium/Brussels/Brussels_night.cropped.jpeg",
                                            "width": 5400
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Belgium/Brussels/Brussels_night.jpeg",
                                            "width": 5400
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Brussels_night_road",
                                    "city": "Brussels",
                                    "original": "Europe/Belgium/Brussels/Brussels_night_road.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Belgium/Brussels/Brussels_night_road.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Belgium/Brussels/Brussels_night_road.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Belgium/Brussels/Brussels_night_road.cropped.jpeg",
                                            "width": 5601
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Belgium/Brussels/Brussels_night_road.jpeg",
                                            "width": 5601
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Czechia",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Prague",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Prague_day",
                                    "city": "Prague",
                                    "original": "Europe/Czechia/Prague/Prague_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Czechia/Prague/Prague_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Czechia/Prague/Prague_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Czechia/Prague/Prague_day.cropped.jpeg",
                                            "width": 5693
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Czechia/Prague/Prague_day.jpeg",
                                            "width": 5693
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Prague_eve",
                                    "city": "Prague",
                                    "original": "Europe/Czechia/Prague/Prague_eve.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Czechia/Prague/Prague_eve.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Czechia/Prague/Prague_eve.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Czechia/Prague/Prague_eve.cropped.jpeg",
                                            "width": 4500
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Czechia/Prague/Prague_eve.jpeg",
                                            "width": 4500
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Denmark",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Copenhagen",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Copenhagen_day",
                                    "city": "Copenhagen",
                                    "original": "Europe/Denmark/Copenhagen/Copenhagen_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Denmark/Copenhagen/Copenhagen_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Denmark/Copenhagen/Copenhagen_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Denmark/Copenhagen/Copenhagen_day.cropped.jpeg",
                                            "width": 5064
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Denmark/Copenhagen/Copenhagen_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Copenhagen_night",
                                    "city": "Copenhagen",
                                    "original": "Europe/Denmark/Copenhagen/Copenhagen_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Denmark/Copenhagen/Copenhagen_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Denmark/Copenhagen/Copenhagen_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Denmark/Copenhagen/Copenhagen_night.cropped.jpeg",
                                            "width": 5383
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Denmark/Copenhagen/Copenhagen_night.jpeg",
                                            "width": 5383
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Estonia",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Tallinn",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Tallinn_day",
                                    "city": "Tallinn",
                                    "original": "Europe/Estonia/Tallinn/Tallinn_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Estonia/Tallinn/Tallinn_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Estonia/Tallinn/Tallinn_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Estonia/Tallinn/Tallinn_day.cropped.jpeg",
                                            "width": 6200
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Estonia/Tallinn/Tallinn_day.jpeg",
                                            "width": 6200
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Tallinn_night",
                                    "city": "Tallinn",
                                    "original": "Europe/Estonia/Tallinn/Tallinn_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Estonia/Tallinn/Tallinn_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Estonia/Tallinn/Tallinn_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Estonia/Tallinn/Tallinn_night.cropped.jpeg",
                                            "width": 5600
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Estonia/Tallinn/Tallinn_night.jpeg",
                                            "width": 5600
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Finland",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Helsinki",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Helsinki_day",
                                    "city": "Helsinki",
                                    "original": "Europe/Finland/Helsinki/Helsinki_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Finland/Helsinki/Helsinki_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Finland/Helsinki/Helsinki_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Finland/Helsinki/Helsinki_day.cropped.jpeg",
                                            "width": 5262
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Finland/Helsinki/Helsinki_day.jpeg",
                                            "width": 5801
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Helsinki_night",
                                    "city": "Helsinki",
                                    "original": "Europe/Finland/Helsinki/Helsinki_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Finland/Helsinki/Helsinki_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Finland/Helsinki/Helsinki_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Finland/Helsinki/Helsinki_night.cropped.jpeg",
                                            "width": 5438
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Finland/Helsinki/Helsinki_night.jpeg",
                                            "width": 5441
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "France",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Lyon",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Lyon_day",
                                    "city": "Lyon",
                                    "original": "Europe/France/Lyon/Lyon_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/France/Lyon/Lyon_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/France/Lyon/Lyon_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/France/Lyon/Lyon_day.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/France/Lyon/Lyon_day.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Lyon_night",
                                    "city": "Lyon",
                                    "original": "Europe/France/Lyon/Lyon_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/France/Lyon/Lyon_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/France/Lyon/Lyon_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/France/Lyon/Lyon_night.cropped.jpeg",
                                            "width": 5681
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/France/Lyon/Lyon_night.jpeg",
                                            "width": 5681
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Lyon_sunset",
                                    "city": "Lyon",
                                    "original": "Europe/France/Lyon/Lyon_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/France/Lyon/Lyon_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/France/Lyon/Lyon_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/France/Lyon/Lyon_sunset.cropped.jpeg",
                                            "width": 5745
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/France/Lyon/Lyon_sunset.jpeg",
                                            "width": 5746
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Marseille",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Marseille_day",
                                    "city": "Marseille",
                                    "original": "Europe/France/Marseille/Marseille_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/France/Marseille/Marseille_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/France/Marseille/Marseille_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/France/Marseille/Marseille_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/France/Marseille/Marseille_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Marseille_day1",
                                    "city": "Marseille",
                                    "original": "Europe/France/Marseille/Marseille_day1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/France/Marseille/Marseille_day1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/France/Marseille/Marseille_day1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/France/Marseille/Marseille_day1.cropped.jpeg",
                                            "width": 7360
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/France/Marseille/Marseille_day1.jpeg",
                                            "width": 7360
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Marseille_night",
                                    "city": "Marseille",
                                    "original": "Europe/France/Marseille/Marseille_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/France/Marseille/Marseille_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/France/Marseille/Marseille_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/France/Marseille/Marseille_night.cropped.jpeg",
                                            "width": 4306
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/France/Marseille/Marseille_night.jpeg",
                                            "width": 4306
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Paris",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Paris_day",
                                    "city": "Paris",
                                    "original": "Europe/France/Paris/Paris_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/France/Paris/Paris_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/France/Paris/Paris_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/France/Paris/Paris_day.cropped.jpeg",
                                            "width": 4622
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/France/Paris/Paris_day.jpeg",
                                            "width": 5379
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Paris_night",
                                    "city": "Paris",
                                    "original": "Europe/France/Paris/Paris_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/France/Paris/Paris_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/France/Paris/Paris_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/France/Paris/Paris_night.cropped.jpeg",
                                            "width": 7086
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/France/Paris/Paris_night.jpeg",
                                            "width": 7086
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Germany",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Berlin",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Berlin_day",
                                    "city": "Berlin",
                                    "original": "Europe/Germany/Berlin/Berlin_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Berlin/Berlin_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Berlin/Berlin_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Berlin/Berlin_day.cropped.jpeg",
                                            "width": 5162
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Berlin/Berlin_day.jpeg",
                                            "width": 5310
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Berlin_night",
                                    "city": "Berlin",
                                    "original": "Europe/Germany/Berlin/Berlin_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Berlin/Berlin_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Berlin/Berlin_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Berlin/Berlin_night.cropped.jpeg",
                                            "width": 5345
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Berlin/Berlin_night.jpeg",
                                            "width": 5345
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Berlin_night1",
                                    "city": "Berlin",
                                    "original": "Europe/Germany/Berlin/Berlin_night1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Berlin/Berlin_night1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Berlin/Berlin_night1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Berlin/Berlin_night1.cropped.jpeg",
                                            "width": 9228
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Berlin/Berlin_night1.jpeg",
                                            "width": 10063
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Cologne",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Cologne_day",
                                    "city": "Cologne",
                                    "original": "Europe/Germany/Cologne/Cologne_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Cologne/Cologne_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Cologne/Cologne_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Cologne/Cologne_day.cropped.jpeg",
                                            "width": 5000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Cologne/Cologne_day.jpeg",
                                            "width": 5000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Cologne_sunset",
                                    "city": "Cologne",
                                    "original": "Europe/Germany/Cologne/Cologne_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Cologne/Cologne_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Cologne/Cologne_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Cologne/Cologne_sunset.cropped.jpeg",
                                            "width": 7305
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Cologne/Cologne_sunset.jpeg",
                                            "width": 7305
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Dresden",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Dresden_eve",
                                    "city": "Dresden",
                                    "original": "Europe/Germany/Dresden/Dresden_eve.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Dresden/Dresden_eve.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Dresden/Dresden_eve.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Dresden/Dresden_eve.cropped.jpeg",
                                            "width": 5760
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Dresden/Dresden_eve.jpeg",
                                            "width": 5760
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Dresden_morning",
                                    "city": "Dresden",
                                    "original": "Europe/Germany/Dresden/Dresden_morning.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Dresden/Dresden_morning.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Dresden/Dresden_morning.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Dresden/Dresden_morning.cropped.jpeg",
                                            "width": 5981
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Dresden/Dresden_morning.jpeg",
                                            "width": 5988
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Düsseldorf",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Düsseldorf_day",
                                    "city": "Düsseldorf",
                                    "original": "Europe/Germany/Düsseldorf/Düsseldorf_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Düsseldorf/Düsseldorf_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Düsseldorf/Düsseldorf_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Düsseldorf/Düsseldorf_day.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Düsseldorf/Düsseldorf_day.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Düsseldorf_sunset",
                                    "city": "Düsseldorf",
                                    "original": "Europe/Germany/Düsseldorf/Düsseldorf_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Düsseldorf/Düsseldorf_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Düsseldorf/Düsseldorf_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Düsseldorf/Düsseldorf_sunset.cropped.jpeg",
                                            "width": 4756
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Düsseldorf/Düsseldorf_sunset.jpeg",
                                            "width": 4756
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Frankfurt am Main",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "FrankfurtAM_day",
                                    "city": "Frankfurt am Main",
                                    "original": "Europe/Germany/Frankfurt am Main/FrankfurtAM_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Frankfurt am Main/FrankfurtAM_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Frankfurt am Main/FrankfurtAM_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Frankfurt am Main/FrankfurtAM_day.cropped.jpeg",
                                            "width": 5388
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Frankfurt am Main/FrankfurtAM_day.jpeg",
                                            "width": 5392
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "FrankfurtAM_night",
                                    "city": "Frankfurt am Main",
                                    "original": "Europe/Germany/Frankfurt am Main/FrankfurtAM_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Frankfurt am Main/FrankfurtAM_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Frankfurt am Main/FrankfurtAM_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Frankfurt am Main/FrankfurtAM_night.cropped.jpeg",
                                            "width": 5616
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Frankfurt am Main/FrankfurtAM_night.jpeg",
                                            "width": 5616
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Hamburg",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Hamburg_day",
                                    "city": "Hamburg",
                                    "original": "Europe/Germany/Hamburg/Hamburg_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Hamburg/Hamburg_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Hamburg/Hamburg_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Hamburg/Hamburg_day.cropped.jpeg",
                                            "width": 7903
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Hamburg/Hamburg_day.jpeg",
                                            "width": 7903
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Hamburg_sunset",
                                    "city": "Hamburg",
                                    "original": "Europe/Germany/Hamburg/Hamburg_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Hamburg/Hamburg_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Hamburg/Hamburg_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Hamburg/Hamburg_sunset.cropped.jpeg",
                                            "width": 5301
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Hamburg/Hamburg_sunset.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Munich",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Munich_day",
                                    "city": "Munich",
                                    "original": "Europe/Germany/Munich/Munich_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Munich/Munich_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Munich/Munich_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Munich/Munich_day.cropped.jpeg",
                                            "width": 4590
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Munich/Munich_day.jpeg",
                                            "width": 4760
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Munich_night",
                                    "city": "Munich",
                                    "original": "Europe/Germany/Munich/Munich_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Munich/Munich_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Munich/Munich_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Munich/Munich_night.cropped.jpeg",
                                            "width": 8192
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Munich/Munich_night.jpeg",
                                            "width": 8192
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Stuttgart",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Stuttgart_day",
                                    "city": "Stuttgart",
                                    "original": "Europe/Germany/Stuttgart/Stuttgart_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_day.cropped.jpeg",
                                            "width": 4896
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_day.jpeg",
                                            "width": 4928
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Stuttgart_day1",
                                    "city": "Stuttgart",
                                    "original": "Europe/Germany/Stuttgart/Stuttgart_day1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_day1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_day1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_day1.cropped.jpeg",
                                            "width": 6036
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_day1.jpeg",
                                            "width": 6048
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Stuttgart_night",
                                    "city": "Stuttgart",
                                    "original": "Europe/Germany/Stuttgart/Stuttgart_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_night.cropped.jpeg",
                                            "width": 7200
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Germany/Stuttgart/Stuttgart_night.jpeg",
                                            "width": 7200
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Greece",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Athens",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Athens_day",
                                    "city": "Athens",
                                    "original": "Europe/Greece/Athens/Athens_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Greece/Athens/Athens_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Greece/Athens/Athens_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Greece/Athens/Athens_day.cropped.jpeg",
                                            "width": 5817
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Greece/Athens/Athens_day.jpeg",
                                            "width": 5818
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Athens_night",
                                    "city": "Athens",
                                    "original": "Europe/Greece/Athens/Athens_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Greece/Athens/Athens_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Greece/Athens/Athens_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Greece/Athens/Athens_night.cropped.jpeg",
                                            "width": 6941
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Greece/Athens/Athens_night.jpeg",
                                            "width": 8217
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Hungary",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Budapest",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Budapest_business_night",
                                    "city": "Budapest",
                                    "original": "Europe/Hungary/Budapest/Budapest_business_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Hungary/Budapest/Budapest_business_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Hungary/Budapest/Budapest_business_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Hungary/Budapest/Budapest_business_night.cropped.jpeg",
                                            "width": 5253
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Hungary/Budapest/Budapest_business_night.jpeg",
                                            "width": 5253
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Budapest_day",
                                    "city": "Budapest",
                                    "original": "Europe/Hungary/Budapest/Budapest_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Hungary/Budapest/Budapest_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Hungary/Budapest/Budapest_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Hungary/Budapest/Budapest_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Hungary/Budapest/Budapest_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Italy",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Milan",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Milan_day",
                                    "city": "Milan",
                                    "original": "Europe/Italy/Milan/Milan_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Italy/Milan/Milan_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Italy/Milan/Milan_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Italy/Milan/Milan_day.cropped.jpeg",
                                            "width": 5000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Italy/Milan/Milan_day.jpeg",
                                            "width": 5000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Milan_sunset",
                                    "city": "Milan",
                                    "original": "Europe/Italy/Milan/Milan_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Italy/Milan/Milan_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Italy/Milan/Milan_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Italy/Milan/Milan_sunset.cropped.jpeg",
                                            "width": 8219
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Italy/Milan/Milan_sunset.jpeg",
                                            "width": 8219
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Naples",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Naples_day",
                                    "city": "Naples",
                                    "original": "Europe/Italy/Naples/Naples_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Italy/Naples/Naples_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Italy/Naples/Naples_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Italy/Naples/Naples_day.cropped.jpeg",
                                            "width": 6300
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Italy/Naples/Naples_day.jpeg",
                                            "width": 6900
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Naples_night",
                                    "city": "Naples",
                                    "original": "Europe/Italy/Naples/Naples_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Italy/Naples/Naples_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Italy/Naples/Naples_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Italy/Naples/Naples_night.cropped.jpeg",
                                            "width": 8256
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Italy/Naples/Naples_night.jpeg",
                                            "width": 8256
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Rome",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Rome_day",
                                    "city": "Rome",
                                    "original": "Europe/Italy/Rome/Rome_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Italy/Rome/Rome_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Italy/Rome/Rome_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Italy/Rome/Rome_day.cropped.jpeg",
                                            "width": 5184
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Italy/Rome/Rome_day.jpeg",
                                            "width": 5184
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Rome_night",
                                    "city": "Rome",
                                    "original": "Europe/Italy/Rome/Rome_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Italy/Rome/Rome_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Italy/Rome/Rome_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Italy/Rome/Rome_night.cropped.jpeg",
                                            "width": 5600
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Italy/Rome/Rome_night.jpeg",
                                            "width": 6362
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Turin",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Turin_day",
                                    "city": "Turin",
                                    "original": "Europe/Italy/Turin/Turin_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Italy/Turin/Turin_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Italy/Turin/Turin_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Italy/Turin/Turin_day.cropped.jpeg",
                                            "width": 6500
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Italy/Turin/Turin_day.jpeg",
                                            "width": 6500
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Turin_night",
                                    "city": "Turin",
                                    "original": "Europe/Italy/Turin/Turin_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Italy/Turin/Turin_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Italy/Turin/Turin_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Italy/Turin/Turin_night.cropped.jpeg",
                                            "width": 4794
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Italy/Turin/Turin_night.jpeg",
                                            "width": 4825
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Latvia",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Riga",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Riga_day",
                                    "city": "Riga",
                                    "original": "Europe/Latvia/Riga/Riga_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Latvia/Riga/Riga_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Latvia/Riga/Riga_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Latvia/Riga/Riga_day.cropped.jpeg",
                                            "width": 4608
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Latvia/Riga/Riga_day.jpeg",
                                            "width": 4608
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Riga_night",
                                    "city": "Riga",
                                    "original": "Europe/Latvia/Riga/Riga_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Latvia/Riga/Riga_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Latvia/Riga/Riga_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Latvia/Riga/Riga_night.cropped.jpeg",
                                            "width": 5517
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Latvia/Riga/Riga_night.jpeg",
                                            "width": 5517
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Lithuania",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Vilnius",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Vilnius_day",
                                    "city": "Vilnius",
                                    "original": "Europe/Lithuania/Vilnius/Vilnius_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Lithuania/Vilnius/Vilnius_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Lithuania/Vilnius/Vilnius_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Lithuania/Vilnius/Vilnius_day.cropped.jpeg",
                                            "width": 4809
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Lithuania/Vilnius/Vilnius_day.jpeg",
                                            "width": 4840
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Vilnius_night",
                                    "city": "Vilnius",
                                    "original": "Europe/Lithuania/Vilnius/Vilnius_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Lithuania/Vilnius/Vilnius_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Lithuania/Vilnius/Vilnius_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Lithuania/Vilnius/Vilnius_night.cropped.jpeg",
                                            "width": 5170
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Lithuania/Vilnius/Vilnius_night.jpeg",
                                            "width": 5170
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Netherlands",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Amsterdam",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Amsterdam_day",
                                    "city": "Amsterdam",
                                    "original": "Europe/Netherlands/Amsterdam/Amsterdam_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_day.cropped.jpeg",
                                            "width": 4506
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_day.jpeg",
                                            "width": 4930
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Amsterdam_night",
                                    "city": "Amsterdam",
                                    "original": "Europe/Netherlands/Amsterdam/Amsterdam_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_night.cropped.jpeg",
                                            "width": 6100
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_night.jpeg",
                                            "width": 6100
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Amsterdam_night1",
                                    "city": "Amsterdam",
                                    "original": "Europe/Netherlands/Amsterdam/Amsterdam_night1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_night1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_night1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_night1.cropped.jpeg",
                                            "width": 5874
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Netherlands/Amsterdam/Amsterdam_night1.jpeg",
                                            "width": 5874
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Rotterdam",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Rotterdam_day",
                                    "city": "Rotterdam",
                                    "original": "Europe/Netherlands/Rotterdam/Rotterdam_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Netherlands/Rotterdam/Rotterdam_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Netherlands/Rotterdam/Rotterdam_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Netherlands/Rotterdam/Rotterdam_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Netherlands/Rotterdam/Rotterdam_day.jpeg",
                                            "width": 6016
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Rotterdam_night",
                                    "city": "Rotterdam",
                                    "original": "Europe/Netherlands/Rotterdam/Rotterdam_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Netherlands/Rotterdam/Rotterdam_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Netherlands/Rotterdam/Rotterdam_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Netherlands/Rotterdam/Rotterdam_night.cropped.jpeg",
                                            "width": 6470
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Netherlands/Rotterdam/Rotterdam_night.jpeg",
                                            "width": 6673
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Utrecht",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Utrecht_day",
                                    "city": "Utrecht",
                                    "original": "Europe/Netherlands/Utrecht/Utrecht_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Netherlands/Utrecht/Utrecht_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Netherlands/Utrecht/Utrecht_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Netherlands/Utrecht/Utrecht_day.cropped.jpeg",
                                            "width": 5412
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Netherlands/Utrecht/Utrecht_day.jpeg",
                                            "width": 5412
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Utrecht_night",
                                    "city": "Utrecht",
                                    "original": "Europe/Netherlands/Utrecht/Utrecht_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Netherlands/Utrecht/Utrecht_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Netherlands/Utrecht/Utrecht_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Netherlands/Utrecht/Utrecht_night.cropped.jpeg",
                                            "width": 5253
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Netherlands/Utrecht/Utrecht_night.jpeg",
                                            "width": 5253
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Norway",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Oslo",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Oslo_day",
                                    "city": "Oslo",
                                    "original": "Europe/Norway/Oslo/Oslo_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Norway/Oslo/Oslo_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Norway/Oslo/Oslo_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Norway/Oslo/Oslo_day.cropped.jpeg",
                                            "width": 5250
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Norway/Oslo/Oslo_day.jpeg",
                                            "width": 7000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Oslo_night",
                                    "city": "Oslo",
                                    "original": "Europe/Norway/Oslo/Oslo_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Norway/Oslo/Oslo_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Norway/Oslo/Oslo_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Norway/Oslo/Oslo_night.cropped.jpeg",
                                            "width": 7200
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Norway/Oslo/Oslo_night.jpeg",
                                            "width": 7200
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Poland",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Gdansk",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Gdansk_day",
                                    "city": "Gdansk",
                                    "original": "Europe/Poland/Gdansk/Gdansk_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Poland/Gdansk/Gdansk_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Poland/Gdansk/Gdansk_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Poland/Gdansk/Gdansk_day.cropped.jpeg",
                                            "width": 5000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Poland/Gdansk/Gdansk_day.jpeg",
                                            "width": 5000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Gdansk_night",
                                    "city": "Gdansk",
                                    "original": "Europe/Poland/Gdansk/Gdansk_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Poland/Gdansk/Gdansk_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Poland/Gdansk/Gdansk_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Poland/Gdansk/Gdansk_night.cropped.jpeg",
                                            "width": 5000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Poland/Gdansk/Gdansk_night.jpeg",
                                            "width": 5000
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Krakow",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Krakow_day",
                                    "city": "Krakow",
                                    "original": "Europe/Poland/Krakow/Krakow_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Poland/Krakow/Krakow_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Poland/Krakow/Krakow_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Poland/Krakow/Krakow_day.cropped.jpeg",
                                            "width": 5207
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Poland/Krakow/Krakow_day.jpeg",
                                            "width": 5210
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Krakow_day1",
                                    "city": "Krakow",
                                    "original": "Europe/Poland/Krakow/Krakow_day1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Poland/Krakow/Krakow_day1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Poland/Krakow/Krakow_day1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Poland/Krakow/Krakow_day1.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Poland/Krakow/Krakow_day1.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Krakow_night",
                                    "city": "Krakow",
                                    "original": "Europe/Poland/Krakow/Krakow_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Poland/Krakow/Krakow_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Poland/Krakow/Krakow_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Poland/Krakow/Krakow_night.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Poland/Krakow/Krakow_night.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Poznan",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Poznan_day",
                                    "city": "Poznan",
                                    "original": "Europe/Poland/Poznan/Poznan_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Poland/Poznan/Poznan_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Poland/Poznan/Poznan_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Poland/Poznan/Poznan_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Poland/Poznan/Poznan_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Poznan_night",
                                    "city": "Poznan",
                                    "original": "Europe/Poland/Poznan/Poznan_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Poland/Poznan/Poznan_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Poland/Poznan/Poznan_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Poland/Poznan/Poznan_night.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Poland/Poznan/Poznan_night.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Warsaw",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Warsaw_day",
                                    "city": "Warsaw",
                                    "original": "Europe/Poland/Warsaw/Warsaw_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Poland/Warsaw/Warsaw_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Poland/Warsaw/Warsaw_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Poland/Warsaw/Warsaw_day.cropped.jpeg",
                                            "width": 4554
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Poland/Warsaw/Warsaw_day.jpeg",
                                            "width": 4554
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Warsaw_night",
                                    "city": "Warsaw",
                                    "original": "Europe/Poland/Warsaw/Warsaw_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Poland/Warsaw/Warsaw_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Poland/Warsaw/Warsaw_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Poland/Warsaw/Warsaw_night.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Poland/Warsaw/Warsaw_night.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Wroclaw",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Wroclaw_day",
                                    "city": "Wroclaw",
                                    "original": "Europe/Poland/Wroclaw/Wroclaw_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Poland/Wroclaw/Wroclaw_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Poland/Wroclaw/Wroclaw_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Poland/Wroclaw/Wroclaw_day.cropped.jpeg",
                                            "width": 5020
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Poland/Wroclaw/Wroclaw_day.jpeg",
                                            "width": 5020
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Wroclaw_night",
                                    "city": "Wroclaw",
                                    "original": "Europe/Poland/Wroclaw/Wroclaw_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Poland/Wroclaw/Wroclaw_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Poland/Wroclaw/Wroclaw_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Poland/Wroclaw/Wroclaw_night.cropped.jpeg",
                                            "width": 5136
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Poland/Wroclaw/Wroclaw_night.jpeg",
                                            "width": 5262
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Portugal",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Lisbon",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Lisbon_day",
                                    "city": "Lisbon",
                                    "original": "Europe/Portugal/Lisbon/Lisbon_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Portugal/Lisbon/Lisbon_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Portugal/Lisbon/Lisbon_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Portugal/Lisbon/Lisbon_day.cropped.jpeg",
                                            "width": 5000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Portugal/Lisbon/Lisbon_day.jpeg",
                                            "width": 5000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Lisbon_night",
                                    "city": "Lisbon",
                                    "original": "Europe/Portugal/Lisbon/Lisbon_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Portugal/Lisbon/Lisbon_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Portugal/Lisbon/Lisbon_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Portugal/Lisbon/Lisbon_night.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Portugal/Lisbon/Lisbon_night.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Porto",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Porto_day",
                                    "city": "Porto",
                                    "original": "Europe/Portugal/Porto/Porto_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Portugal/Porto/Porto_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Portugal/Porto/Porto_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Portugal/Porto/Porto_day.cropped.jpeg",
                                            "width": 7360
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Portugal/Porto/Porto_day.jpeg",
                                            "width": 7360
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Porto_night",
                                    "city": "Porto",
                                    "original": "Europe/Portugal/Porto/Porto_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Portugal/Porto/Porto_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Portugal/Porto/Porto_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Portugal/Porto/Porto_night.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Portugal/Porto/Porto_night.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Spain",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Barcelona",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Barcelona_day",
                                    "city": "Barcelona",
                                    "original": "Europe/Spain/Barcelona/Barcelona_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Spain/Barcelona/Barcelona_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Spain/Barcelona/Barcelona_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Spain/Barcelona/Barcelona_day.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Spain/Barcelona/Barcelona_day.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Barcelona_night",
                                    "city": "Barcelona",
                                    "original": "Europe/Spain/Barcelona/Barcelona_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Spain/Barcelona/Barcelona_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Spain/Barcelona/Barcelona_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Spain/Barcelona/Barcelona_night.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Spain/Barcelona/Barcelona_night.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Bilbao",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Bilbao_day",
                                    "city": "Bilbao",
                                    "original": "Europe/Spain/Bilbao/Bilbao_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Spain/Bilbao/Bilbao_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Spain/Bilbao/Bilbao_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Spain/Bilbao/Bilbao_day.cropped.jpeg",
                                            "width": 5411
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Spain/Bilbao/Bilbao_day.jpeg",
                                            "width": 5411
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Bilbao_night",
                                    "city": "Bilbao",
                                    "original": "Europe/Spain/Bilbao/Bilbao_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Spain/Bilbao/Bilbao_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Spain/Bilbao/Bilbao_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Spain/Bilbao/Bilbao_night.cropped.jpeg",
                                            "width": 8361
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Spain/Bilbao/Bilbao_night.jpeg",
                                            "width": 13797
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Madrid",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Madrid_day",
                                    "city": "Madrid",
                                    "original": "Europe/Spain/Madrid/Madrid_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Spain/Madrid/Madrid_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Spain/Madrid/Madrid_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Spain/Madrid/Madrid_day.cropped.jpeg",
                                            "width": 4761
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Spain/Madrid/Madrid_day.jpeg",
                                            "width": 6959
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Madrid_night",
                                    "city": "Madrid",
                                    "original": "Europe/Spain/Madrid/Madrid_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Spain/Madrid/Madrid_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Spain/Madrid/Madrid_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Spain/Madrid/Madrid_night.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Spain/Madrid/Madrid_night.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Valencia",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Valencia_day",
                                    "city": "Valencia",
                                    "original": "Europe/Spain/Valencia/Valencia_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Spain/Valencia/Valencia_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Spain/Valencia/Valencia_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Spain/Valencia/Valencia_day.cropped.jpeg",
                                            "width": 5700
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Spain/Valencia/Valencia_day.jpeg",
                                            "width": 5700
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Valencia_night",
                                    "city": "Valencia",
                                    "original": "Europe/Spain/Valencia/Valencia_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Spain/Valencia/Valencia_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Spain/Valencia/Valencia_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Spain/Valencia/Valencia_night.cropped.jpeg",
                                            "width": 6453
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Spain/Valencia/Valencia_night.jpeg",
                                            "width": 6453
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Sweden",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Goethenburg",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Goethenburg_night",
                                    "city": "Goethenburg",
                                    "original": "Europe/Sweden/Goethenburg/Goethenburg_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Sweden/Goethenburg/Goethenburg_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Sweden/Goethenburg/Goethenburg_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Sweden/Goethenburg/Goethenburg_night.cropped.jpeg",
                                            "width": 5265
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Sweden/Goethenburg/Goethenburg_night.jpeg",
                                            "width": 6240
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Goethenburg_sunset",
                                    "city": "Goethenburg",
                                    "original": "Europe/Sweden/Goethenburg/Goethenburg_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Sweden/Goethenburg/Goethenburg_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Sweden/Goethenburg/Goethenburg_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Sweden/Goethenburg/Goethenburg_sunset.cropped.jpeg",
                                            "width": 6500
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Sweden/Goethenburg/Goethenburg_sunset.jpeg",
                                            "width": 6500
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Malmö",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Malmö_day",
                                    "city": "Malmö",
                                    "original": "Europe/Sweden/Malmö/Malmö_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Sweden/Malmö/Malmö_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Sweden/Malmö/Malmö_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Sweden/Malmö/Malmö_day.cropped.jpeg",
                                            "width": 4048
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Sweden/Malmö/Malmö_day.jpeg",
                                            "width": 4048
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Malmö_day1",
                                    "city": "Malmö",
                                    "original": "Europe/Sweden/Malmö/Malmö_day1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Sweden/Malmö/Malmö_day1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Sweden/Malmö/Malmö_day1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Sweden/Malmö/Malmö_day1.cropped.jpeg",
                                            "width": 6016
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Sweden/Malmö/Malmö_day1.jpeg",
                                            "width": 6016
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Malmö_night",
                                    "city": "Malmö",
                                    "original": "Europe/Sweden/Malmö/Malmö_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Sweden/Malmö/Malmö_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Sweden/Malmö/Malmö_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Sweden/Malmö/Malmö_night.cropped.jpeg",
                                            "width": 4600
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Sweden/Malmö/Malmö_night.jpeg",
                                            "width": 4600
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Malmö_night1",
                                    "city": "Malmö",
                                    "original": "Europe/Sweden/Malmö/Malmö_night1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Sweden/Malmö/Malmö_night1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Sweden/Malmö/Malmö_night1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Sweden/Malmö/Malmö_night1.cropped.jpeg",
                                            "width": 5616
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Sweden/Malmö/Malmö_night1.jpeg",
                                            "width": 5616
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Stockholm",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Stockholm_day",
                                    "city": "Stockholm",
                                    "original": "Europe/Sweden/Stockholm/Stockholm_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Sweden/Stockholm/Stockholm_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Sweden/Stockholm/Stockholm_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Sweden/Stockholm/Stockholm_day.cropped.jpeg",
                                            "width": 4551
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Sweden/Stockholm/Stockholm_day.jpeg",
                                            "width": 5466
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Stockholm_night",
                                    "city": "Stockholm",
                                    "original": "Europe/Sweden/Stockholm/Stockholm_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Sweden/Stockholm/Stockholm_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Sweden/Stockholm/Stockholm_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Sweden/Stockholm/Stockholm_night.cropped.jpeg",
                                            "width": 5472
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Sweden/Stockholm/Stockholm_night.jpeg",
                                            "width": 5472
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Switzerland",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Bern",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Bern_day",
                                    "city": "Bern",
                                    "original": "Europe/Switzerland/Bern/Bern_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Switzerland/Bern/Bern_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Switzerland/Bern/Bern_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Switzerland/Bern/Bern_day.cropped.jpeg",
                                            "width": 6942
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Switzerland/Bern/Bern_day.jpeg",
                                            "width": 7078
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Bern_sunset",
                                    "city": "Bern",
                                    "original": "Europe/Switzerland/Bern/Bern_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Switzerland/Bern/Bern_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Switzerland/Bern/Bern_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Switzerland/Bern/Bern_sunset.cropped.jpeg",
                                            "width": 5794
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Switzerland/Bern/Bern_sunset.jpeg",
                                            "width": 5794
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Zürich",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Zürich_day",
                                    "city": "Zürich",
                                    "original": "Europe/Switzerland/Zürich/Zürich_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Switzerland/Zürich/Zürich_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Switzerland/Zürich/Zürich_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Switzerland/Zürich/Zürich_day.cropped.jpeg",
                                            "width": 5472
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Switzerland/Zürich/Zürich_day.jpeg",
                                            "width": 5472
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Zürich_night",
                                    "city": "Zürich",
                                    "original": "Europe/Switzerland/Zürich/Zürich_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Switzerland/Zürich/Zürich_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Switzerland/Zürich/Zürich_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Switzerland/Zürich/Zürich_night.cropped.jpeg",
                                            "width": 5921
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Switzerland/Zürich/Zürich_night.jpeg",
                                            "width": 5946
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Türkiye",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Istanbul",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Istanbul_day",
                                    "city": "Istanbul",
                                    "original": "Europe/Türkiye/Istanbul/Istanbul_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Türkiye/Istanbul/Istanbul_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Türkiye/Istanbul/Istanbul_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Türkiye/Istanbul/Istanbul_day.cropped.jpeg",
                                            "width": 4532
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Türkiye/Istanbul/Istanbul_day.jpeg",
                                            "width": 4576
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Istanbul_sunset",
                                    "city": "Istanbul",
                                    "original": "Europe/Türkiye/Istanbul/Istanbul_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Türkiye/Istanbul/Istanbul_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Türkiye/Istanbul/Istanbul_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Türkiye/Istanbul/Istanbul_sunset.cropped.jpeg",
                                            "width": 7360
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Türkiye/Istanbul/Istanbul_sunset.jpeg",
                                            "width": 7360
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "UK",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Dublin",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Dublin_day",
                                    "city": "Dublin",
                                    "original": "Europe/UK/Dublin/Dublin_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/Dublin/Dublin_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/Dublin/Dublin_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/Dublin/Dublin_day.cropped.jpeg",
                                            "width": 5700
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/Dublin/Dublin_day.jpeg",
                                            "width": 5700
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Dublin_sunset",
                                    "city": "Dublin",
                                    "original": "Europe/UK/Dublin/Dublin_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/Dublin/Dublin_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/Dublin/Dublin_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/Dublin/Dublin_sunset.cropped.jpeg",
                                            "width": 4048
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/Dublin/Dublin_sunset.jpeg",
                                            "width": 4048
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Edinburgh",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Edinburgh_day",
                                    "city": "Edinburgh",
                                    "original": "Europe/UK/Edinburgh/Edinburgh_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/Edinburgh/Edinburgh_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/Edinburgh/Edinburgh_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/Edinburgh/Edinburgh_day.cropped.jpeg",
                                            "width": 4584
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/Edinburgh/Edinburgh_day.jpeg",
                                            "width": 4592
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Edinburgh_sunset",
                                    "city": "Edinburgh",
                                    "original": "Europe/UK/Edinburgh/Edinburgh_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/Edinburgh/Edinburgh_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/Edinburgh/Edinburgh_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/Edinburgh/Edinburgh_sunset.cropped.jpeg",
                                            "width": 7303
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/Edinburgh/Edinburgh_sunset.jpeg",
                                            "width": 7303
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Glasgow",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Glasgow_day",
                                    "city": "Glasgow",
                                    "original": "Europe/UK/Glasgow/Glasgow_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/Glasgow/Glasgow_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/Glasgow/Glasgow_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/Glasgow/Glasgow_day.cropped.jpeg",
                                            "width": 4083
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/Glasgow/Glasgow_day.jpeg",
                                            "width": 4091
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Glasgow_night",
                                    "city": "Glasgow",
                                    "original": "Europe/UK/Glasgow/Glasgow_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/Glasgow/Glasgow_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/Glasgow/Glasgow_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/Glasgow/Glasgow_night.cropped.jpeg",
                                            "width": 6016
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/Glasgow/Glasgow_night.jpeg",
                                            "width": 6016
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Liverpool",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Liverpool_day",
                                    "city": "Liverpool",
                                    "original": "Europe/UK/Liverpool/Liverpool_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/Liverpool/Liverpool_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/Liverpool/Liverpool_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/Liverpool/Liverpool_day.cropped.jpeg",
                                            "width": 5597
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/Liverpool/Liverpool_day.jpeg",
                                            "width": 6043
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Liverpool_night",
                                    "city": "Liverpool",
                                    "original": "Europe/UK/Liverpool/Liverpool_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/Liverpool/Liverpool_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/Liverpool/Liverpool_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/Liverpool/Liverpool_night.cropped.jpeg",
                                            "width": 5318
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/Liverpool/Liverpool_night.jpeg",
                                            "width": 5318
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "London",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "London_day",
                                    "city": "London",
                                    "original": "Europe/UK/London/London_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/London/London_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/London/London_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/London/London_day.cropped.jpeg",
                                            "width": 5500
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/London/London_day.jpeg",
                                            "width": 5500
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "London_night",
                                    "city": "London",
                                    "original": "Europe/UK/London/London_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/London/London_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/London/London_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/London/London_night.cropped.jpeg",
                                            "width": 5333
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/London/London_night.jpeg",
                                            "width": 5333
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Manchester",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Manchester_day",
                                    "city": "Manchester",
                                    "original": "Europe/UK/Manchester/Manchester_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/Manchester/Manchester_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/Manchester/Manchester_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/Manchester/Manchester_day.cropped.jpeg",
                                            "width": 4989
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/Manchester/Manchester_day.jpeg",
                                            "width": 4993
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Manchester_night",
                                    "city": "Manchester",
                                    "original": "Europe/UK/Manchester/Manchester_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/UK/Manchester/Manchester_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/UK/Manchester/Manchester_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/UK/Manchester/Manchester_night.cropped.jpeg",
                                            "width": 4650
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/UK/Manchester/Manchester_night.jpeg",
                                            "width": 4650
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Ukraine",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Kyiv",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Kyiv_day",
                                    "city": "Kyiv",
                                    "original": "Europe/Ukraine/Kyiv/Kyiv_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Ukraine/Kyiv/Kyiv_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Ukraine/Kyiv/Kyiv_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Ukraine/Kyiv/Kyiv_day.cropped.jpeg",
                                            "width": 4323
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Ukraine/Kyiv/Kyiv_day.jpeg",
                                            "width": 4323
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Kyiv_night",
                                    "city": "Kyiv",
                                    "original": "Europe/Ukraine/Kyiv/Kyiv_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Europe/Ukraine/Kyiv/Kyiv_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Europe/Ukraine/Kyiv/Kyiv_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Europe/Ukraine/Kyiv/Kyiv_night.cropped.jpeg",
                                            "width": 7454
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Europe/Ukraine/Kyiv/Kyiv_night.jpeg",
                                            "width": 9000
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "name": "North America",
            "type": "folder",
            "contents": [
                {
                    "name": "Canada",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Calgary",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Calgary_day",
                                    "city": "Calgary",
                                    "original": "North America/Canada/Calgary/Calgary_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Canada/Calgary/Calgary_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Canada/Calgary/Calgary_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Canada/Calgary/Calgary_day.cropped.jpeg",
                                            "width": 5760
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Canada/Calgary/Calgary_day.jpeg",
                                            "width": 5760
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Calgary_day1",
                                    "city": "Calgary",
                                    "original": "North America/Canada/Calgary/Calgary_day1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Canada/Calgary/Calgary_day1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Canada/Calgary/Calgary_day1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Canada/Calgary/Calgary_day1.cropped.jpeg",
                                            "width": 5306
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Canada/Calgary/Calgary_day1.jpeg",
                                            "width": 5730
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Calgary_night",
                                    "city": "Calgary",
                                    "original": "North America/Canada/Calgary/Calgary_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Canada/Calgary/Calgary_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Canada/Calgary/Calgary_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Canada/Calgary/Calgary_night.cropped.jpeg",
                                            "width": 4931
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Canada/Calgary/Calgary_night.jpeg",
                                            "width": 5108
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Montreal",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Montreal_day",
                                    "city": "Montreal",
                                    "original": "North America/Canada/Montreal/Montreal_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Canada/Montreal/Montreal_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Canada/Montreal/Montreal_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Canada/Montreal/Montreal_day.cropped.jpeg",
                                            "width": 4920
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Canada/Montreal/Montreal_day.jpeg",
                                            "width": 4928
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Montreal_sunset",
                                    "city": "Montreal",
                                    "original": "North America/Canada/Montreal/Montreal_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Canada/Montreal/Montreal_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Canada/Montreal/Montreal_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Canada/Montreal/Montreal_sunset.cropped.jpeg",
                                            "width": 4896
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Canada/Montreal/Montreal_sunset.jpeg",
                                            "width": 4928
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Ottawa",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Ottawa_day",
                                    "city": "Ottawa",
                                    "original": "North America/Canada/Ottawa/Ottawa_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Canada/Ottawa/Ottawa_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Canada/Ottawa/Ottawa_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Canada/Ottawa/Ottawa_day.cropped.jpeg",
                                            "width": 5072
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Canada/Ottawa/Ottawa_day.jpeg",
                                            "width": 5072
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Ottawa_sunset",
                                    "city": "Ottawa",
                                    "original": "North America/Canada/Ottawa/Ottawa_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Canada/Ottawa/Ottawa_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Canada/Ottawa/Ottawa_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Canada/Ottawa/Ottawa_sunset.cropped.jpeg",
                                            "width": 8083
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Canada/Ottawa/Ottawa_sunset.jpeg",
                                            "width": 8083
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Toronto",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Toronto_day",
                                    "city": "Toronto",
                                    "original": "North America/Canada/Toronto/Toronto_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Canada/Toronto/Toronto_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Canada/Toronto/Toronto_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Canada/Toronto/Toronto_day.cropped.jpeg",
                                            "width": 6195
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Canada/Toronto/Toronto_day.jpeg",
                                            "width": 6195
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Toronto_night",
                                    "city": "Toronto",
                                    "original": "North America/Canada/Toronto/Toronto_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Canada/Toronto/Toronto_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Canada/Toronto/Toronto_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Canada/Toronto/Toronto_night.cropped.jpeg",
                                            "width": 5986
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Canada/Toronto/Toronto_night.jpeg",
                                            "width": 5986
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Vancouver",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Vancouver_day",
                                    "city": "Vancouver",
                                    "original": "North America/Canada/Vancouver/Vancouver_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Canada/Vancouver/Vancouver_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Canada/Vancouver/Vancouver_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Canada/Vancouver/Vancouver_day.cropped.jpeg",
                                            "width": 5184
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Canada/Vancouver/Vancouver_day.jpeg",
                                            "width": 5184
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Vancouver_night",
                                    "city": "Vancouver",
                                    "original": "North America/Canada/Vancouver/Vancouver_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Canada/Vancouver/Vancouver_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Canada/Vancouver/Vancouver_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Canada/Vancouver/Vancouver_night.cropped.jpeg",
                                            "width": 4176
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Canada/Vancouver/Vancouver_night.jpeg",
                                            "width": 4400
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Mexico",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Mexico City",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Mexico City_day",
                                    "city": "Mexico City",
                                    "original": "North America/Mexico/Mexico City/Mexico City_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Mexico/Mexico City/Mexico City_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Mexico/Mexico City/Mexico City_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Mexico/Mexico City/Mexico City_day.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Mexico/Mexico City/Mexico City_day.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Mexico City_sunset",
                                    "city": "Mexico City",
                                    "original": "North America/Mexico/Mexico City/Mexico City_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/Mexico/Mexico City/Mexico City_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/Mexico/Mexico City/Mexico City_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/Mexico/Mexico City/Mexico City_sunset.cropped.jpeg",
                                            "width": 6720
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/Mexico/Mexico City/Mexico City_sunset.jpeg",
                                            "width": 6720
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "USA",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Atlanta",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Atlanta_day",
                                    "city": "Atlanta",
                                    "original": "North America/USA/Atlanta/Atlanta_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Atlanta/Atlanta_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Atlanta/Atlanta_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Atlanta/Atlanta_day.cropped.jpeg",
                                            "width": 4517
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Atlanta/Atlanta_day.jpeg",
                                            "width": 4519
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Atlanta_day1",
                                    "city": "Atlanta",
                                    "original": "North America/USA/Atlanta/Atlanta_day1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Atlanta/Atlanta_day1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Atlanta/Atlanta_day1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Atlanta/Atlanta_day1.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Atlanta/Atlanta_day1.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Atlanta_night",
                                    "city": "Atlanta",
                                    "original": "North America/USA/Atlanta/Atlanta_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Atlanta/Atlanta_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Atlanta/Atlanta_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Atlanta/Atlanta_night.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Atlanta/Atlanta_night.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Atlanta_night1",
                                    "city": "Atlanta",
                                    "original": "North America/USA/Atlanta/Atlanta_night1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Atlanta/Atlanta_night1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Atlanta/Atlanta_night1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Atlanta/Atlanta_night1.cropped.jpeg",
                                            "width": 4896
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Atlanta/Atlanta_night1.jpeg",
                                            "width": 4928
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Austin",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Austin_day",
                                    "city": "Austin",
                                    "original": "North America/USA/Austin/Austin_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Austin/Austin_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Austin/Austin_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Austin/Austin_day.cropped.jpeg",
                                            "width": 5760
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Austin/Austin_day.jpeg",
                                            "width": 5760
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Austin_night",
                                    "city": "Austin",
                                    "original": "North America/USA/Austin/Austin_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Austin/Austin_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Austin/Austin_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Austin/Austin_night.cropped.jpeg",
                                            "width": 5262
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Austin/Austin_night.jpeg",
                                            "width": 5266
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Baltimore",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Baltimore_day",
                                    "city": "Baltimore",
                                    "original": "North America/USA/Baltimore/Baltimore_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Baltimore/Baltimore_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Baltimore/Baltimore_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Baltimore/Baltimore_day.cropped.jpeg",
                                            "width": 5760
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Baltimore/Baltimore_day.jpeg",
                                            "width": 5760
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Baltimore_night",
                                    "city": "Baltimore",
                                    "original": "North America/USA/Baltimore/Baltimore_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Baltimore/Baltimore_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Baltimore/Baltimore_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Baltimore/Baltimore_night.cropped.jpeg",
                                            "width": 5853
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Baltimore/Baltimore_night.jpeg",
                                            "width": 5853
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Boston",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Boston_day",
                                    "city": "Boston",
                                    "original": "North America/USA/Boston/Boston_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Boston/Boston_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Boston/Boston_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Boston/Boston_day.cropped.jpeg",
                                            "width": 5579
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Boston/Boston_day.jpeg",
                                            "width": 5999
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Boston_night",
                                    "city": "Boston",
                                    "original": "North America/USA/Boston/Boston_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Boston/Boston_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Boston/Boston_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Boston/Boston_night.cropped.jpeg",
                                            "width": 8889
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Boston/Boston_night.jpeg",
                                            "width": 9167
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Chicago",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Chicago_day",
                                    "city": "Chicago",
                                    "original": "North America/USA/Chicago/Chicago_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Chicago/Chicago_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Chicago/Chicago_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Chicago/Chicago_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Chicago/Chicago_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Chicago_night",
                                    "city": "Chicago",
                                    "original": "North America/USA/Chicago/Chicago_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Chicago/Chicago_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Chicago/Chicago_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Chicago/Chicago_night.cropped.jpeg",
                                            "width": 4211
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Chicago/Chicago_night.jpeg",
                                            "width": 4219
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Columbus",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Columbus_day",
                                    "city": "Columbus",
                                    "original": "North America/USA/Columbus/Columbus_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Columbus/Columbus_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Columbus/Columbus_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Columbus/Columbus_day.cropped.jpeg",
                                            "width": 4751
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Columbus/Columbus_day.jpeg",
                                            "width": 4751
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Columbus_night",
                                    "city": "Columbus",
                                    "original": "North America/USA/Columbus/Columbus_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Columbus/Columbus_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Columbus/Columbus_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Columbus/Columbus_night.cropped.jpeg",
                                            "width": 5507
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Columbus/Columbus_night.jpeg",
                                            "width": 5507
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Dallas",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Dallas_day",
                                    "city": "Dallas",
                                    "original": "North America/USA/Dallas/Dallas_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Dallas/Dallas_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Dallas/Dallas_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Dallas/Dallas_day.cropped.jpeg",
                                            "width": 5052
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Dallas/Dallas_day.jpeg",
                                            "width": 6705
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Dallas_night",
                                    "city": "Dallas",
                                    "original": "North America/USA/Dallas/Dallas_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Dallas/Dallas_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Dallas/Dallas_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Dallas/Dallas_night.cropped.jpeg",
                                            "width": 5611
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Dallas/Dallas_night.jpeg",
                                            "width": 5611
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Denver",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Denver_day",
                                    "city": "Denver",
                                    "original": "North America/USA/Denver/Denver_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Denver/Denver_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Denver/Denver_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Denver/Denver_day.cropped.jpeg",
                                            "width": 7314
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Denver/Denver_day.jpeg",
                                            "width": 7314
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Denver_night",
                                    "city": "Denver",
                                    "original": "North America/USA/Denver/Denver_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Denver/Denver_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Denver/Denver_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Denver/Denver_night.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Denver/Denver_night.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Detroit",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Detroit_day",
                                    "city": "Detroit",
                                    "original": "North America/USA/Detroit/Detroit_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Detroit/Detroit_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Detroit/Detroit_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Detroit/Detroit_day.cropped.jpeg",
                                            "width": 5472
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Detroit/Detroit_day.jpeg",
                                            "width": 5472
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Detroit_night",
                                    "city": "Detroit",
                                    "original": "North America/USA/Detroit/Detroit_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Detroit/Detroit_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Detroit/Detroit_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Detroit/Detroit_night.cropped.jpeg",
                                            "width": 6016
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Detroit/Detroit_night.jpeg",
                                            "width": 6016
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Detroit_night1",
                                    "city": "Detroit",
                                    "original": "North America/USA/Detroit/Detroit_night1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Detroit/Detroit_night1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Detroit/Detroit_night1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Detroit/Detroit_night1.cropped.jpeg",
                                            "width": 7952
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Detroit/Detroit_night1.jpeg",
                                            "width": 7952
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Houston",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Houston_day",
                                    "city": "Houston",
                                    "original": "North America/USA/Houston/Houston_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Houston/Houston_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Houston/Houston_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Houston/Houston_day.cropped.jpeg",
                                            "width": 5460
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Houston/Houston_day.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Houston_night",
                                    "city": "Houston",
                                    "original": "North America/USA/Houston/Houston_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Houston/Houston_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Houston/Houston_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Houston/Houston_night.cropped.jpeg",
                                            "width": 4605
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Houston/Houston_night.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Las Vegas",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Las Vegas_day",
                                    "city": "Las Vegas",
                                    "original": "North America/USA/Las Vegas/Las Vegas_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Las Vegas/Las Vegas_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Las Vegas/Las Vegas_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Las Vegas/Las Vegas_day.cropped.jpeg",
                                            "width": 8256
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Las Vegas/Las Vegas_day.jpeg",
                                            "width": 8256
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Las Vegas_night",
                                    "city": "Las Vegas",
                                    "original": "North America/USA/Las Vegas/Las Vegas_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Las Vegas/Las Vegas_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Las Vegas/Las Vegas_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Las Vegas/Las Vegas_night.cropped.jpeg",
                                            "width": 4539
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Las Vegas/Las Vegas_night.jpeg",
                                            "width": 4539
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Los Angeles",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "LA_day_palms",
                                    "city": "Los Angeles",
                                    "original": "North America/USA/Los Angeles/LA_day_palms.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Los Angeles/LA_day_palms.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Los Angeles/LA_day_palms.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Los Angeles/LA_day_palms.cropped.jpeg",
                                            "width": 7360
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Los Angeles/LA_day_palms.jpeg",
                                            "width": 7360
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Los Angeles_day",
                                    "city": "Los Angeles",
                                    "original": "North America/USA/Los Angeles/Los Angeles_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Los Angeles/Los Angeles_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Los Angeles/Los Angeles_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Los Angeles/Los Angeles_day.cropped.jpeg",
                                            "width": 5600
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Los Angeles/Los Angeles_day.jpeg",
                                            "width": 5600
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Los Angeles_night_mount",
                                    "city": "Los Angeles",
                                    "original": "North America/USA/Los Angeles/Los Angeles_night_mount.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Los Angeles/Los Angeles_night_mount.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Los Angeles/Los Angeles_night_mount.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Los Angeles/Los Angeles_night_mount.cropped.jpeg",
                                            "width": 5520
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Los Angeles/Los Angeles_night_mount.jpeg",
                                            "width": 5520
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Miami",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Miami_day",
                                    "city": "Miami",
                                    "original": "North America/USA/Miami/Miami_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Miami/Miami_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Miami/Miami_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Miami/Miami_day.cropped.jpeg",
                                            "width": 9504
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Miami/Miami_day.jpeg",
                                            "width": 9504
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Miami_night",
                                    "city": "Miami",
                                    "original": "North America/USA/Miami/Miami_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Miami/Miami_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Miami/Miami_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Miami/Miami_night.cropped.jpeg",
                                            "width": 4920
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Miami/Miami_night.jpeg",
                                            "width": 4928
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Minneapolis",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Minneapolis_day",
                                    "city": "Minneapolis",
                                    "original": "North America/USA/Minneapolis/Minneapolis_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Minneapolis/Minneapolis_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Minneapolis/Minneapolis_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Minneapolis/Minneapolis_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Minneapolis/Minneapolis_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Minneapolis_sunset",
                                    "city": "Minneapolis",
                                    "original": "North America/USA/Minneapolis/Minneapolis_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Minneapolis/Minneapolis_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Minneapolis/Minneapolis_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Minneapolis/Minneapolis_sunset.cropped.jpeg",
                                            "width": 5304
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Minneapolis/Minneapolis_sunset.jpeg",
                                            "width": 5435
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Nashville",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Nashville_day",
                                    "city": "Nashville",
                                    "original": "North America/USA/Nashville/Nashville_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Nashville/Nashville_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Nashville/Nashville_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Nashville/Nashville_day.cropped.jpeg",
                                            "width": 5063
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Nashville/Nashville_day.jpeg",
                                            "width": 5400
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Nashville_sunset",
                                    "city": "Nashville",
                                    "original": "North America/USA/Nashville/Nashville_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Nashville/Nashville_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Nashville/Nashville_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Nashville/Nashville_sunset.cropped.jpeg",
                                            "width": 5120
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Nashville/Nashville_sunset.jpeg",
                                            "width": 5120
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "New Orleans",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "New Orleans_day",
                                    "city": "New Orleans",
                                    "original": "North America/USA/New Orleans/New Orleans_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/New Orleans/New Orleans_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/New Orleans/New Orleans_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/New Orleans/New Orleans_day.cropped.jpeg",
                                            "width": 5034
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/New Orleans/New Orleans_day.jpeg",
                                            "width": 5034
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "New Orleans_day1",
                                    "city": "New Orleans",
                                    "original": "North America/USA/New Orleans/New Orleans_day1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/New Orleans/New Orleans_day1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/New Orleans/New Orleans_day1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/New Orleans/New Orleans_day1.cropped.jpeg",
                                            "width": 5654
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/New Orleans/New Orleans_day1.jpeg",
                                            "width": 5752
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "New Orleans_sunset",
                                    "city": "New Orleans",
                                    "original": "North America/USA/New Orleans/New Orleans_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/New Orleans/New Orleans_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/New Orleans/New Orleans_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/New Orleans/New Orleans_sunset.cropped.jpeg",
                                            "width": 6600
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/New Orleans/New Orleans_sunset.jpeg",
                                            "width": 6600
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "New York",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "NY_day",
                                    "city": "New York",
                                    "original": "North America/USA/New York/NY_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/New York/NY_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/New York/NY_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/New York/NY_day.cropped.jpeg",
                                            "width": 4500
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/New York/NY_day.jpeg",
                                            "width": 5518
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "NY_night",
                                    "city": "New York",
                                    "original": "North America/USA/New York/NY_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/New York/NY_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/New York/NY_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/New York/NY_night.cropped.jpeg",
                                            "width": 4842
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/New York/NY_night.jpeg",
                                            "width": 4873
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Orlando",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Orlando_morning",
                                    "city": "Orlando",
                                    "original": "North America/USA/Orlando/Orlando_morning.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Orlando/Orlando_morning.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Orlando/Orlando_morning.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Orlando/Orlando_morning.cropped.jpeg",
                                            "width": 4949
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Orlando/Orlando_morning.jpeg",
                                            "width": 4949
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Orlando_sunset",
                                    "city": "Orlando",
                                    "original": "North America/USA/Orlando/Orlando_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Orlando/Orlando_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Orlando/Orlando_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Orlando/Orlando_sunset.cropped.jpeg",
                                            "width": 4610
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Orlando/Orlando_sunset.jpeg",
                                            "width": 5464
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Philadelphia",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Philadelphia_day",
                                    "city": "Philadelphia",
                                    "original": "North America/USA/Philadelphia/Philadelphia_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Philadelphia/Philadelphia_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Philadelphia/Philadelphia_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Philadelphia/Philadelphia_day.cropped.jpeg",
                                            "width": 7942
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Philadelphia/Philadelphia_day.jpeg",
                                            "width": 7942
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Philadelphia_night",
                                    "city": "Philadelphia",
                                    "original": "North America/USA/Philadelphia/Philadelphia_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Philadelphia/Philadelphia_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Philadelphia/Philadelphia_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Philadelphia/Philadelphia_night.cropped.jpeg",
                                            "width": 4986
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Philadelphia/Philadelphia_night.jpeg",
                                            "width": 4990
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Phoenix",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Phoenix_day",
                                    "city": "Phoenix",
                                    "original": "North America/USA/Phoenix/Phoenix_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Phoenix/Phoenix_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Phoenix/Phoenix_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Phoenix/Phoenix_day.cropped.jpeg",
                                            "width": 8256
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Phoenix/Phoenix_day.jpeg",
                                            "width": 8256
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Phoenix_sunset",
                                    "city": "Phoenix",
                                    "original": "North America/USA/Phoenix/Phoenix_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Phoenix/Phoenix_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Phoenix/Phoenix_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Phoenix/Phoenix_sunset.cropped.jpeg",
                                            "width": 8256
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Phoenix/Phoenix_sunset.jpeg",
                                            "width": 8256
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Portland",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Portland_day",
                                    "city": "Portland",
                                    "original": "North America/USA/Portland/Portland_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Portland/Portland_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Portland/Portland_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Portland/Portland_day.cropped.jpeg",
                                            "width": 5184
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Portland/Portland_day.jpeg",
                                            "width": 5184
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Portland_night",
                                    "city": "Portland",
                                    "original": "North America/USA/Portland/Portland_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Portland/Portland_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Portland/Portland_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Portland/Portland_night.cropped.jpeg",
                                            "width": 5184
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Portland/Portland_night.jpeg",
                                            "width": 5184
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Saint Louis",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "St Louis_day",
                                    "city": "Saint Louis",
                                    "original": "North America/USA/Saint Louis/St Louis_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Saint Louis/St Louis_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Saint Louis/St Louis_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Saint Louis/St Louis_day.cropped.jpeg",
                                            "width": 7158
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Saint Louis/St Louis_day.jpeg",
                                            "width": 7158
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "St Louis_night",
                                    "city": "Saint Louis",
                                    "original": "North America/USA/Saint Louis/St Louis_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Saint Louis/St Louis_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Saint Louis/St Louis_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Saint Louis/St Louis_night.cropped.jpeg",
                                            "width": 6720
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Saint Louis/St Louis_night.jpeg",
                                            "width": 6720
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Salt Lake City",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Salt Lake City_day",
                                    "city": "Salt Lake City",
                                    "original": "North America/USA/Salt Lake City/Salt Lake City_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Salt Lake City/Salt Lake City_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Salt Lake City/Salt Lake City_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Salt Lake City/Salt Lake City_day.cropped.jpeg",
                                            "width": 4503
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Salt Lake City/Salt Lake City_day.jpeg",
                                            "width": 4509
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Salt Lake City_night",
                                    "city": "Salt Lake City",
                                    "original": "North America/USA/Salt Lake City/Salt Lake City_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Salt Lake City/Salt Lake City_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Salt Lake City/Salt Lake City_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Salt Lake City/Salt Lake City_night.cropped.jpeg",
                                            "width": 7360
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Salt Lake City/Salt Lake City_night.jpeg",
                                            "width": 7360
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "San Diego",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "San Diego_day",
                                    "city": "San Diego",
                                    "original": "North America/USA/San Diego/San Diego_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/San Diego/San Diego_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/San Diego/San Diego_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/San Diego/San Diego_day.cropped.jpeg",
                                            "width": 4500
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/San Diego/San Diego_day.jpeg",
                                            "width": 4500
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "San Diego_night",
                                    "city": "San Diego",
                                    "original": "North America/USA/San Diego/San Diego_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/San Diego/San Diego_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/San Diego/San Diego_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/San Diego/San Diego_night.cropped.jpeg",
                                            "width": 4900
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/San Diego/San Diego_night.jpeg",
                                            "width": 4900
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "San Francisco",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "San Francisco",
                                    "city": "San Francisco",
                                    "original": "North America/USA/San Francisco/San Francisco.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/San Francisco/San Francisco.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/San Francisco/San Francisco.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/San Francisco/San Francisco.cropped.jpeg",
                                            "width": 5681
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/San Francisco/San Francisco.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "San Francisco_day",
                                    "city": "San Francisco",
                                    "original": "North America/USA/San Francisco/San Francisco_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/San Francisco/San Francisco_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/San Francisco/San Francisco_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/San Francisco/San Francisco_day.cropped.jpeg",
                                            "width": 5616
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/San Francisco/San Francisco_day.jpeg",
                                            "width": 5616
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "San Francisco_night",
                                    "city": "San Francisco",
                                    "original": "North America/USA/San Francisco/San Francisco_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/San Francisco/San Francisco_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/San Francisco/San Francisco_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/San Francisco/San Francisco_night.cropped.jpeg",
                                            "width": 7360
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/San Francisco/San Francisco_night.jpeg",
                                            "width": 7360
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "San Francisco_night1",
                                    "city": "San Francisco",
                                    "original": "North America/USA/San Francisco/San Francisco_night1.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/San Francisco/San Francisco_night1.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/San Francisco/San Francisco_night1.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/San Francisco/San Francisco_night1.cropped.jpeg",
                                            "width": 5250
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/San Francisco/San Francisco_night1.jpeg",
                                            "width": 5250
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "San Jose",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "San Jose_cathedral",
                                    "city": "San Jose",
                                    "original": "North America/USA/San Jose/San Jose_cathedral.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/San Jose/San Jose_cathedral.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/San Jose/San Jose_cathedral.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/San Jose/San Jose_cathedral.cropped.jpeg",
                                            "width": 5496
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/San Jose/San Jose_cathedral.jpeg",
                                            "width": 5496
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "San Jose_near Cityhall",
                                    "city": "San Jose",
                                    "original": "North America/USA/San Jose/San Jose_near Cityhall.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/San Jose/San Jose_near Cityhall.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/San Jose/San Jose_near Cityhall.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/San Jose/San Jose_near Cityhall.cropped.jpeg",
                                            "width": 5490
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/San Jose/San Jose_near Cityhall.jpeg",
                                            "width": 5490
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "San Jose_office build",
                                    "city": "San Jose",
                                    "original": "North America/USA/San Jose/San Jose_office build.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/San Jose/San Jose_office build.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/San Jose/San Jose_office build.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/San Jose/San Jose_office build.cropped.jpeg",
                                            "width": 5486
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/San Jose/San Jose_office build.jpeg",
                                            "width": 5486
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Seattle",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Seattle_day",
                                    "city": "Seattle",
                                    "original": "North America/USA/Seattle/Seattle_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Seattle/Seattle_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Seattle/Seattle_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Seattle/Seattle_day.cropped.jpeg",
                                            "width": 5063
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Seattle/Seattle_day.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Seattle_night",
                                    "city": "Seattle",
                                    "original": "North America/USA/Seattle/Seattle_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "North America/USA/Seattle/Seattle_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "North America/USA/Seattle/Seattle_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "North America/USA/Seattle/Seattle_night.cropped.jpeg",
                                            "width": 4751
                                        },
                                        {
                                            "variant": "original",
                                            "path": "North America/USA/Seattle/Seattle_night.jpeg",
                                            "width": 8751
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "name": "Oceania",
            "type": "folder",
            "contents": [
                {
                    "name": "Australia",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Brisbane",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Brisbane_day",
                                    "city": "Brisbane",
                                    "original": "Oceania/Australia/Brisbane/Brisbane_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Oceania/Australia/Brisbane/Brisbane_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Oceania/Australia/Brisbane/Brisbane_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Oceania/Australia/Brisbane/Brisbane_day.cropped.jpeg",
                                            "width": 5472
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Oceania/Australia/Brisbane/Brisbane_day.jpeg",
                                            "width": 5472
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Brisbane_night",
                                    "city": "Brisbane",
                                    "original": "Oceania/Australia/Brisbane/Brisbane_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Oceania/Australia/Brisbane/Brisbane_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Oceania/Australia/Brisbane/Brisbane_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Oceania/Australia/Brisbane/Brisbane_night.cropped.jpeg",
                                            "width": 4500
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Oceania/Australia/Brisbane/Brisbane_night.jpeg",
                                            "width": 4500
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Melbourne",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Melbourne",
                                    "city": "Melbourne",
                                    "original": "Oceania/Australia/Melbourne/Melbourne.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Oceania/Australia/Melbourne/Melbourne.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Oceania/Australia/Melbourne/Melbourne.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Oceania/Australia/Melbourne/Melbourne.cropped.jpeg",
                                            "width": 4604
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Oceania/Australia/Melbourne/Melbourne.jpeg",
                                            "width": 4888
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Melbourne_night",
                                    "city": "Melbourne",
                                    "original": "Oceania/Australia/Melbourne/Melbourne_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Oceania/Australia/Melbourne/Melbourne_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Oceania/Australia/Melbourne/Melbourne_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Oceania/Australia/Melbourne/Melbourne_night.cropped.jpeg",
                                            "width": 5534
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Oceania/Australia/Melbourne/Melbourne_night.jpeg",
                                            "width": 5616
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Perth",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Perth_day",
                                    "city": "Perth",
                                    "original": "Oceania/Australia/Perth/Perth_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Oceania/Australia/Perth/Perth_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Oceania/Australia/Perth/Perth_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Oceania/Australia/Perth/Perth_day.cropped.jpeg",
                                            "width": 4500
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Oceania/Australia/Perth/Perth_day.jpeg",
                                            "width": 4500
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Perth_night",
                                    "city": "Perth",
                                    "original": "Oceania/Australia/Perth/Perth_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Oceania/Australia/Perth/Perth_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Oceania/Australia/Perth/Perth_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Oceania/Australia/Perth/Perth_night.cropped.jpeg",
                                            "width": 8688
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Oceania/Australia/Perth/Perth_night.jpeg",
                                            "width": 8688
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Sydney",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Sydney_day",
                                    "city": "Sydney",
                                    "original": "Oceania/Australia/Sydney/Sydney_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Oceania/Australia/Sydney/Sydney_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Oceania/Australia/Sydney/Sydney_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Oceania/Australia/Sydney/Sydney_day.cropped.jpeg",
                                            "width": 5559
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Oceania/Australia/Sydney/Sydney_day.jpeg",
                                            "width": 6226
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Sydney_night",
                                    "city": "Sydney",
                                    "original": "Oceania/Australia/Sydney/Sydney_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Oceania/Australia/Sydney/Sydney_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Oceania/Australia/Sydney/Sydney_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Oceania/Australia/Sydney/Sydney_night.cropped.jpeg",
                                            "width": 6953
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Oceania/Australia/Sydney/Sydney_night.jpeg",
                                            "width": 7276
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "New Zealand",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Auckland",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Auckland_day",
                                    "city": "Auckland",
                                    "original": "Oceania/New Zealand/Auckland/Auckland_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Oceania/New Zealand/Auckland/Auckland_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Oceania/New Zealand/Auckland/Auckland_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Oceania/New Zealand/Auckland/Auckland_day.cropped.jpeg",
                                            "width": 6016
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Oceania/New Zealand/Auckland/Auckland_day.jpeg",
                                            "width": 6016
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Auckland_night",
                                    "city": "Auckland",
                                    "original": "Oceania/New Zealand/Auckland/Auckland_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "Oceania/New Zealand/Auckland/Auckland_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "Oceania/New Zealand/Auckland/Auckland_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "Oceania/New Zealand/Auckland/Auckland_night.cropped.jpeg",
                                            "width": 10439
                                        },
                                        {
                                            "variant": "original",
                                            "path": "Oceania/New Zealand/Auckland/Auckland_night.jpeg",
                                            "width": 12387
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "name": "South America",
            "type": "folder",
            "contents": [
                {
                    "name": "Argentina",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Buenos Aires",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Bueanos Aires_night",
                                    "city": "Buenos Aires",
                                    "original": "South America/Argentina/Buenos Aires/Bueanos Aires_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "South America/Argentina/Buenos Aires/Bueanos Aires_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "South America/Argentina/Buenos Aires/Bueanos Aires_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "South America/Argentina/Buenos Aires/Bueanos Aires_night.cropped.jpeg",
                                            "width": 5305
                                        },
                                        {
                                            "variant": "original",
                                            "path": "South America/Argentina/Buenos Aires/Bueanos Aires_night.jpeg",
                                            "width": 5305
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Buenos Aires_day",
                                    "city": "Buenos Aires",
                                    "original": "South America/Argentina/Buenos Aires/Buenos Aires_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "South America/Argentina/Buenos Aires/Buenos Aires_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "South America/Argentina/Buenos Aires/Buenos Aires_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "South America/Argentina/Buenos Aires/Buenos Aires_day.cropped.jpeg",
                                            "width": 5472
                                        },
                                        {
                                            "variant": "original",
                                            "path": "South America/Argentina/Buenos Aires/Buenos Aires_day.jpeg",
                                            "width": 5472
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Brazil",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Rio de Janeiro",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Rio_day",
                                    "city": "Rio de Janeiro",
                                    "original": "South America/Brazil/Rio de Janeiro/Rio_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "South America/Brazil/Rio de Janeiro/Rio_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "South America/Brazil/Rio de Janeiro/Rio_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "South America/Brazil/Rio de Janeiro/Rio_day.cropped.jpeg",
                                            "width": 5863
                                        },
                                        {
                                            "variant": "original",
                                            "path": "South America/Brazil/Rio de Janeiro/Rio_day.jpeg",
                                            "width": 5863
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Rio_sunset",
                                    "city": "Rio de Janeiro",
                                    "original": "South America/Brazil/Rio de Janeiro/Rio_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "South America/Brazil/Rio de Janeiro/Rio_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "South America/Brazil/Rio de Janeiro/Rio_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "South America/Brazil/Rio de Janeiro/Rio_sunset.cropped.jpeg",
                                            "width": 6693
                                        },
                                        {
                                            "variant": "original",
                                            "path": "South America/Brazil/Rio de Janeiro/Rio_sunset.jpeg",
                                            "width": 6693
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "name": "Sao Paolo",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Sao Paolo_day",
                                    "city": "Sao Paolo",
                                    "original": "South America/Brazil/Sao Paolo/Sao Paolo_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "South America/Brazil/Sao Paolo/Sao Paolo_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "South America/Brazil/Sao Paolo/Sao Paolo_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "South America/Brazil/Sao Paolo/Sao Paolo_day.cropped.jpeg",
                                            "width": 5111
                                        },
                                        {
                                            "variant": "original",
                                            "path": "South America/Brazil/Sao Paolo/Sao Paolo_day.jpeg",
                                            "width": 5114
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Sao Paolo_night",
                                    "city": "Sao Paolo",
                                    "original": "South America/Brazil/Sao Paolo/Sao Paolo_night.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "South America/Brazil/Sao Paolo/Sao Paolo_night.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "South America/Brazil/Sao Paolo/Sao Paolo_night.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "South America/Brazil/Sao Paolo/Sao Paolo_night.cropped.jpeg",
                                            "width": 4352
                                        },
                                        {
                                            "variant": "original",
                                            "path": "South America/Brazil/Sao Paolo/Sao Paolo_night.jpeg",
                                            "width": 4352
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "Chile",
                    "type": "folder",
                    "contents": [
                        {
                            "name": "Santiago",
                            "type": "folder",
                            "contents": [
                                {
                                    "type": "file",
                                    "name": "Santiago_day",
                                    "city": "Santiago",
                                    "original": "South America/Chile/Santiago/Santiago_day.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "South America/Chile/Santiago/Santiago_day.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "South America/Chile/Santiago/Santiago_day.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "South America/Chile/Santiago/Santiago_day.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "South America/Chile/Santiago/Santiago_day.jpeg",
                                            "width": 6016
                                        }
                                    ]
                                },
                                {
                                    "type": "file",
                                    "name": "Santiago_sunset",
                                    "city": "Santiago",
                                    "original": "South America/Chile/Santiago/Santiago_sunset.jpeg",
                                    "variants": [
                                        {
                                            "variant": "2400",
                                            "path": "South America/Chile/Santiago/Santiago_sunset.2400.jpeg",
                                            "width": 2400
                                        },
                                        {
                                            "variant": "600",
                                            "path": "South America/Chile/Santiago/Santiago_sunset.600.jpeg",
                                            "width": 600
                                        },
                                        {
                                            "variant": "cropped",
                                            "path": "South America/Chile/Santiago/Santiago_sunset.cropped.jpeg",
                                            "width": 6000
                                        },
                                        {
                                            "variant": "original",
                                            "path": "South America/Chile/Santiago/Santiago_sunset.jpeg",
                                            "width": 6000
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}
