import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import * as fromRoot from '../reducers';
import { MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'dir-teams-scopes',
    templateUrl: './teams-scopes.component.html',
    styleUrls: ['./teams-scopes.component.scss'],
    standalone: true
})
export class TeamsScopesComponent implements OnInit {
    loginHint = '';
    scopes: string[] = [];

    constructor(
        private store: Store,
        private authService: MsalService
    ) {
        console.log('TeamsScopesComponent constructor');
        this.store.pipe(select(fromRoot.selectQueryParam('loginHint'))).subscribe((loginHint) => {
            this.loginHint = loginHint;
        });
        this.store
            .pipe(
                select(fromRoot.selectQueryParam('scopes')),
                filter((scopes) => scopes != null)
            )
            .subscribe((scopes) => {
                try {
                    console.log('TeamsScopesComponent: scopes', scopes);
                    this.scopes = scopes.split(',');
                } catch (e) {
                    console.warn('TeamsScopesComponent: error parsing scopes', e);
                    this.scopes = [];
                }
            });
    }

    ngOnInit(): void {
        console.log('TeamsScopesComponent ngOnInit');
        this.authService.initialize().subscribe(() => {
            console.log('TeamsScopesComponent authService.initialize');
            if (environment.debugDelays) {
                window.setTimeout(() => {
                    //debugger;
                    this.store.dispatch(AuthActions.msalLogin({ loginHint: this.loginHint, scopes: this.scopes }));
                }, 15000);
            } else {
                this.store.dispatch(AuthActions.msalLogin({ loginHint: this.loginHint, scopes: this.scopes }));
            }
        });
    }
}
