import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { uiFeature } from '../ui/ui.reducer';
import { Subject, takeUntil } from 'rxjs';
import { RouterLink } from '@angular/router';
import { SubscriptionInformationModalComponent } from './modals/subscription-information-modal.component';
import { FooterComponent } from '../shared/components/footer.component';
@Component({
    selector: 'dir-pricing-page',
    templateUrl: './pricing-page.component.html',
    styleUrls: ['./pricing-page.component.scss'],
    imports: [RouterLink, SubscriptionInformationModalComponent, FooterComponent]
})
export class PricingPageComponent implements OnInit, OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();

    appVersion = environment.appVersion;
    hostedInTOC = false;
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store
            .select(uiFeature.selectHostedInTOC)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((hostedInTOC) => {
                this.hostedInTOC = hostedInTOC;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
