import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as SettingsActions from './settings.actions';
import { TocService } from '../services/toc.service';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import * as appActions from '../app.actions';

@Injectable()
export class TocEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private tocService: TocService
    ) {}

    fetchCharts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.fetchCharts),
            switchMap(() =>
                this.tocService.fetchCharts().pipe(
                    map((charts) => SettingsActions.fetchChartsSuccess({ charts })),
                    catchError((error) => {
                        console.error(error);
                        return of(
                            SettingsActions.fetchChartsFailure({
                                message: error.message,
                                status: error.status,
                                error: error.error
                            })
                        );
                    })
                )
            )
        )
    );

    fetchChartsFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.fetchChartsFailure),
            filter((action) => action.status !== 404),
            map(() => appActions.toastFailure({ message: 'Could not connect to TeamOrgChart' }))
        )
    );

    checkTenantExists$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.checkTenantExists),
            switchMap((action) =>
                this.tocService.checkTenantExists(action.tenantId).pipe(
                    map((exists) => SettingsActions.checkTenantExistsSuccess({ exists })),
                    catchError((error) => {
                        console.error(error);
                        return of(SettingsActions.checkTenantExistsFailure({ error: error.message }));
                    })
                )
            )
        )
    );
}
