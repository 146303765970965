import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColumnDefinition, ColumnType } from 'src/app/data/categories';
import { ICustomField } from './category-item-editor-modal.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dir-editor-field',
    templateUrl: './editor-field.component.html',
    styleUrls: ['./editor-field.component.scss'],
    imports: [NgIf, FormsModule, ReactiveFormsModule]
})
export class EditorFieldComponent {
    @Input() colDef: ColumnDefinition;
    @Input() group: FormGroup<ICustomField>;
    columnType = ColumnType;
}
