<ti-orgchart-chart-view
    [chartNodes]="chartNodes()"
    [chartMeta]="chartMeta"
    [style.height.px]="800"
    [maxCalculatedLevel]="3"
    linkColor="var(--bs-primary)"
    [startingPositionId]="startingPositionId"
    [pictureComponentProvider]="pictureComponentProvider"
    [separationSettings]="separationSettings"
    (navigate)="navigate($event)"
    (information)="info($event)"></ti-orgchart-chart-view>
