import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, mergeMap } from 'rxjs';
import * as appActions from './app.actions';

@Injectable()
export class AppEffects {
    constructor(
        private actions$: Actions,
        private toastr: ToastrService
    ) {}

    toastSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(appActions.toastSuccess),
                /** An EMPTY observable only emits completion. Replace with your own observable stream */
                mergeMap((action) => {
                    this.toastr.success(action.message);
                    return EMPTY;
                })
            );
        },
        { dispatch: false }
    );

    toastFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(appActions.toastFailure),
                /** An EMPTY observable only emits completion. Replace with your own observable stream */
                mergeMap((action) => {
                    console.log('toast failure');
                    this.toastr.error(action.message);
                    return EMPTY;
                })
            );
        },
        { dispatch: false }
    );
}
