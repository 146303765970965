<ng-template #demoMode>
    <div class="card mb-3 feature-consent">
        <div class="card-header bg-light-dark">
            <h3 class="card-title">Use Demo Data</h3>
        </div>
        <div class="card-body body-height2">
            <div class="card-text">
                <p>Evaluate TeamDirectory using demonstration data.</p>
                <p>
                    This will allow you to explore the features of TeamDirectory without having to connect to your own
                    data.
                </p>
            </div>
        </div>
        <div class="card-footer d-flex align-items-end">
            <div class="w-100">
                <div class="text-center">
                    <button (click)="onGoDemo()" class="btn btn-lg btn-light fw-bold me-2">
                        <span *ngIf="!demoHelper.IsDemoMode()">Use demo data</span>
                        <span *ngIf="demoHelper.IsDemoMode()">Turn off demo</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #logout>
    <div class="card mb-3 feature-consent flex-grow-1">
        <div class="card-header bg-light-dark">
            <h3 class="card-title">Logout</h3>
        </div>
        <div class="card-body body-height">
            <div class="card-text">Sign out from TeamDirectory.</div>
        </div>
        <div class="card-footer d-flex align-items-end">
            <div class="w-100">
                <div class="text-center">
                    <button (click)="onLogout()" class="btn btn-lg btn-light fw-bold">Logout</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="d-flex flex-column flex-root" id="kt_app_root">
    <!--begin::Authentication - Signup Welcome Message -->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Content-->
        <div class="d-flex flex-row-fluid flex-column flex-column-fluid text-center p-5 pt-lg-8">
            <div *ngIf="!isInline">
                <!--begin::Logo-->
                <a routerLink="/directory" class="pt-lg-8 mb-8">
                    <img alt="Logo" src="assets/img/logo96x96.svg" class="h-50px" />
                </a>
                <!--end::Logo-->
                <!--begin::Logo-->
                <h1 class="fw-bold fs-2qx text-gray-800 mb-7">Consent required</h1>
                <!--end::Logo-->
            </div>
            <div class="container">
                <div class="card mb-10">
                    <div class="fw-semibold fs-3 text-muted mb-12">
                        <p class="mt-5">Complete the sign-up to TeamDirectory.</p>
                        <p>To access your organization's directory TeamDirectory requires additional permissions.</p>
                        <p>Please grant consent below, or proceed using demonstration data.</p>
                        <p>
                            Learn more about
                            <a target="_blank" href="https://team-directory.com/provide-consent">providing consent.</a>
                        </p>
                    </div>
                </div>
                <div class="row justify-content-around">
                    <div class="col-12 col-sm-6 col-md-4 col-xxl-3 d-flex">
                        <dir-feature-consent
                            [name]="features.Directory"
                            (featureConsentSuccess)="onConsentSuccess($event)"></dir-feature-consent>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-xxl-3 d-flex">
                        <dir-feature-consent
                            [name]="features.BasicAccess"
                            (featureConsentSuccess)="onConsentSuccess($event)"></dir-feature-consent>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-xxl-3 d-flex">
                        <ng-container *ngTemplateOutlet="demoMode"></ng-container>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-xxl-3 d-flex">
                        <ng-container *ngTemplateOutlet="logout"></ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!--end::Content-->
    </div>
    <!--end::Authentication - Signup Welcome Message-->
</div>
