import { Component, computed, input, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Category, UserColumnSetting } from 'src/app/data/categories';
import { DirectoryUser } from 'src/app/data/data.models';
import * as dataActions from '../../data/data.actions';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TextComponent } from '../../users/components/text.component';

@Component({
    selector: 'dir-details-field',
    templateUrl: './details-field.component.html',
    styleUrls: ['./details-field.component.scss'],
    imports: [NgIf, NgSwitch, NgSwitchCase, RouterLink, NgSwitchDefault, TextComponent]
})
export class DetailsFieldComponent {
    @Input() user: DirectoryUser;
    @Input() manager: DirectoryUser;
    userColumnSetting = input<UserColumnSetting | null>(null);
    categories = input<Category[]>([]);
    @Input() hyperLinkCategories = true;

    isCategory = computed<boolean>(() => {
        return this.categories().find((x) => x.fieldName === this.userColumnSetting()?.originalName) != null;
    });

    categoryName = computed(() => {
        const category = this.categories().find((x) => x.fieldName === this.userColumnSetting()?.originalName);
        return category ? category.slug : null;
    });

    categoryIcon = computed(() => {
        const category = this.categories().find((x) => x.fieldName === this.userColumnSetting()?.originalName);
        if (category) {
            return `bi-${category.icon}`;
        } else {
            if (this.userColumnSetting()) {
                if (this.userColumnSetting().originalName === 'mail') {
                    return 'bi-envelope';
                }
            }
            return '';
        }
    });

    constructor(private store: Store) {}

    closeModal() {
        this.store.dispatch(dataActions.dialogHide());
    }
}
