<div class="d-flex flex-row-fluid flex-column-fluid text-center p-10 py-lg-20 container">
    <div class="flex-grow-1">
        <div class="d-flex flex-column flex-root">
            <div class="d-flex flex-column flex-xl-row flex-column-fluid">
                <div class="d-flex flex-column flex-lg-row-fluid">
                    <div class="d-flex flex-row-fluid flex-center p-10">
                        <div class="d-flex flex-column">
                            <a href="https://team-directory.com" class="mb-15">
                                <img alt="Logo" src="assets/img/logo96x96.svg" class="logo-large" />
                            </a>
                            <h1 class="text-dark fs-3x mb-5">Connected to TeamDirectory!</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container text-center flex-row px-4">
            <div class="row">
                <div class="col bg-body shadow-sm rounded mx-5">
                    <div class="fs-3 m-3">Return to TeamOrgChart</div>
                    <p>You have successfully connected TeamDirectory with your TeamOrgChart account.</p>
                    <p>
                        You can close this window and return to TeamOrgChart and start using your new staff directory.
                    </p>
                    <div class="text-center pb-lg-0 pb-8 mb-5">
                        <button
                            type="button"
                            (click)="closeWindow()"
                            class="border border-dark btn btn-lg btn-outline-light fw-bolder">
                            <span class="ms-1 indicator-label">Close this window</span>
                        </button>
                    </div>
                </div>
                <div class="col bg-body shadow-sm rounded mx-5 d-flex flex-column justify-content-between">
                    <div>
                        <div class="fs-3 m-3">Explore TeamDirectory</div>
                        <p>
                            Click the button below to use TeamDirectory as a standalone application. You will need to
                            provide additional consent.
                        </p>
                    </div>
                    <div class="text-center pb-lg-0 pb-8 mb-5">
                        <button
                            type="button"
                            (click)="goDirectory()"
                            class="border border-dark btn btn-lg btn-outline-light fw-bolder">
                            <span class="ms-1 indicator-label">Explore TeamDirectory</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="flex-column d-flex flex-colum-fluid container">
    <!-- <dir-footer></dir-footer> -->
</div>
