import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import * as microsoftTeams from '@microsoft/teams-js';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as settingsActions from '../settings/settings.actions';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Category } from '../data/categories';
import * as dataActions from '../data/data.actions';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'dir-teams',
    templateUrl: './teams-tab.component.html',
    styleUrls: ['./teams-tab.component.scss'],
    imports: [NgIf, FormsModule, NgFor]
})
export class TeamsTabComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    categories$: Observable<Category[]>;
    categories: Category[];
    selectedCategory = '';
    categoryItem = '';
    suggestedName = '';
    configurationUrl = '';
    constructor(
        private store: Store,
        private cdr: ChangeDetectorRef
    ) {
        this.categories$ = this.store.pipe(select(fromRoot.selectCategories), takeUntil(this.unsubscribe$));
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        console.log('teams-tab.component : OnDestroy');
    }

    ngOnInit(): void {
        console.log('teams-tab.component : OnInit');

        this.onLoad();

        this.categories$.subscribe((categories) => {
            this.categories = categories;
            //initialise the configurationUrl
            this.selectedCategory = categories.length > 0 ? categories[0].slug : '';
            this.onSelected();
            this.cdr.detectChanges();
            console.log('teams-tab.component : Loaded Categories : ' + this.categories.length);
        });

        microsoftTeams.app.initialize().then(() => {
            console.log('teams-tab.component : Intialising Teams');
            microsoftTeams.app.notifySuccess(); //Called to tell teams it has loaded succesfully.
            this.saveSettings();
            console.log('teams-tab.component : Initialised Teams Complete');
        });
    }

    private saveSettings() {
        microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
            const configPromise = this.saveSettingPromise();
            configPromise
                .then((result) => {
                    saveEvent.notifySuccess();
                    console.log('teams-tab : Saved Settings');
                })
                .catch((error) => {
                    saveEvent.notifyFailure('Failed to save settings :-(');
                    console.log('teams-tab : Save Settings FAILED', error);
                });
        });
    }

    private saveSettingPromise(): Promise<void> {
        let configPromise: Promise<void> = null;

        //start from chart or directory view.
        configPromise = microsoftTeams.pages.config.setConfig({
            websiteUrl: window.location.origin,
            contentUrl: this.configurationUrl,
            entityId: 'tdTeamsTab',
            suggestedDisplayName: this.suggestedName
        });

        return configPromise;
    }

    getSelectedCateogry(): Category {
        const cat = this.categories.filter((category) => category.slug === this.selectedCategory)[0];
        return cat;
    }

    onSelected(): void {
        this.suggestedName = '';

        if (this.categoryItem.length > 0) {
            const c = this.getSelectedCateogry().items.filter((item) => item.name === this.categoryItem)[0];
            if (!c) this.categoryItem = '';
        }
        const itemUrl = this.categoryItem.length > 0 ? '/' + this.categoryItem : '';
        const destinationUrl =
            window.location.origin + '/directory/' + this.selectedCategory + itemUrl + '?teamstab=true';
        this.configurationUrl = window.location.origin + '/teams?redirectUrl=' + encodeURIComponent(destinationUrl);

        this.suggestedName = `${this.getSelectedCateogry().name} ${this.categoryItem.length > 0 ? '-' : ''} ${
            this.categoryItem
        }`.trim();

        microsoftTeams.pages.config.setValidityState(this.configurationUrl.length > 0);
    }

    onLoad(): void {
        console.log('teams-tab.component : Reloading Categories');
        this.store.dispatch(settingsActions.loadSettings());
        this.store.dispatch(dataActions.loadDataFromDb());
    }
}
