<div *ngIf="selectedUsers().length > 0" class="card p-3 my-3" @animate>
    <b>Selected : {{ names() }}</b>
    <div class="my-3">
        <button class="btn btn-sm btn-primary me-3" [disabled]="selectedUsers().length === 0" (click)="onGroupChat()">
            <i class="bi bi-chat-left-text"></i>
            Chat with Group
        </button>

        <button class="btn btn-sm btn-primary me-3" [disabled]="selectedUsers().length === 0" (click)="onGroupCall()">
            <i class="bi bi-chat-left-text"></i>
            Call Group
        </button>

        <button
            *ngIf="(isSchedueHidden$ | async) === false"
            class="btn btn-sm btn-primary me-3"
            (click)="onAvailability()"
            [disabled]="!calendarFeatureConsented">
            <i class="bi bi-calendar"></i>
            <span *ngIf="!showAvailability">Show</span>
            <span *ngIf="showAvailability">Hide</span>
            Availability
        </button>

        <button class="btn btn-sm btn-primary" (click)="onClear()">
            <i class="bi bi-calendar"></i>
            Clear Selection
        </button>
    </div>

    <div *ngIf="calendarFeatureConsented && showAvailability" class="table-responsive">
        <table class="table table-row-bordered table-row-dashed table-hover gy-4 align-middle fw-bolder no-footer">
            <thead class="fs-7 text-gray-400 text-uppercase">
                <tr>
                    <th class="px-0"></th>
                    <th class="min-w-250px sorting">Name</th>
                    <th>
                        <button (click)="onNextDay(-1)" class="btn btn-link" [disabled]="dayModifier <= 0">
                            <i class="square bi bi-chevron-left px-1"></i>
                        </button>
                        <span class="calendar-description text-center flex-grow-1">
                            Availability - {{ getAvailabilityDescription() }}
                        </span>
                        <button (click)="onNextDay(1)" class="btn btn-link">
                            <i class="square bi bi-chevron-right px-1"></i>
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody class="fs-6">
                <tr *ngFor="let user of selectedUsers()" [routerLink]="[]" [queryParams]="{ userId: user.id }">
                    <td class="align-baseline" (click)="$event.stopPropagation()">
                        <div class="form-check form-check-sm form-check-custom form-check-solid mt-4 ps-2">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value="1"
                                (click)="onSelect($event, user)"
                                [checked]="isChecked(user)" />
                        </div>
                    </td>
                    <td class="align-baseline">
                        <div class="d-flex align-items-center flex-grow-1">
                            <div class="d-flex flex-column justify-content-center">
                                <a
                                    [routerLink]="[]"
                                    [queryParams]="{ userId: user.id }"
                                    class="mb-1 text-gray-800 text-hover-primary">
                                    <dir-user-presence [id]="user.id" mode="square"></dir-user-presence>
                                    <dir-txt [text]="user.displayName"></dir-txt>
                                </a>
                                <div class="fw-bold fs-6 text-gray-400">
                                    <dir-txt [text]="user.jobTitle"></dir-txt>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <dir-user-availability-strip
                            [userAvailability]="getAvailability(user)"
                            [dayModifier]="dayModifier"
                            [availability]="getUserAvailability(user)"
                            [users]="[user]"></dir-user-availability-strip>
                    </td>
                </tr>
            </tbody>
            <tfoot *ngIf="selectedUsers().length > 1" class="fs-6">
                <tr>
                    <td></td>
                    <td>Comparison</td>
                    <td>
                        <dir-user-availability-strip
                            [userAvailability]="getAvailability(selectedUsers[0])"
                            [availability]="getComparision()"
                            [users]="selectedUsers()"
                            [dayModifier]="dayModifier"></dir-user-availability-strip>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
