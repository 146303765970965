<h1>Configure TeamDirectory</h1>

<div class="container">
    <br />
    <p>
        Choose the
        <b>Category</b>
        and, if required, the
        <b>category item</b>
        that TeamDirectory will start from when opening the tab.
    </p>
    <button *ngIf="categories.length === 0" (click)="onLoad()" class="btn btn-primary btn-lg">Load Categories</button>
    <div class="row">
        <div class="col-6">
            <select [(ngModel)]="selectedCategory" (change)="onSelected()" class="form-select form-select-solid">
                <option *ngFor="let c of categories" [value]="c.slug">{{ c.name }}</option>
            </select>
        </div>
        <div class="col-6">
            <select
                *ngIf="selectedCategory"
                [(ngModel)]="categoryItem"
                (change)="onSelected()"
                class="form-select form-select-solid">
                <option value="">All Items</option>
                <option *ngFor="let i of getSelectedCateogry().items" [value]="i.fieldValue">{{ i.fieldValue }}</option>
            </select>
        </div>
    </div>
</div>
