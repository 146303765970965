<div *ngIf="user[userColumnSetting()?.originalName]" class="row mb-4" [ngSwitch]="userColumnSetting()?.originalName">
    <div class="d-flex flex-row" *ngSwitchCase="'managerDisplayName'">
        <div class="col-4">
            <div class="fw-bold text-muted d-flex flex-row align-items-center">Manager</div>
        </div>
        <div class="col-8 d-flex align-items-center" *ngIf="manager">
            <div class="fw-bolder fs-6 text-gray-800">
                <a routerLink="/directory" queryParamsHandling="merge" [queryParams]="{ userId: manager?.id }">
                    {{ manager?.displayName }}
                </a>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row" *ngSwitchDefault>
        <div class="col-4">
            <div class="fw-bold text-muted d-flex flex-row align-items-center">
                <div *ngIf="categoryIcon()" class="fw-bold text-muted me-4">
                    <i class="bi {{ categoryIcon() }} fs-1"></i>
                </div>
                <div
                    *ngIf="
                        !isCategory() ||
                        (!hyperLinkCategories && !(userColumnSetting()?.originalName !== 'managerDisplayName'))
                    "
                    [innerHtml]="userColumnSetting()?.name"></div>
                <div *ngIf="isCategory()">
                    <a
                        *ngIf="hyperLinkCategories"
                        (click)="closeModal()"
                        routerLink="/directory/{{ categoryName() }}"
                        [innerHtml]="userColumnSetting()?.name"></a>
                </div>
            </div>
        </div>
        <div class="col-8 d-flex align-items-center">
            <div *ngIf="isCategory() && user" class="fw-bolder fs-6 text-gray-800">
                <div class="fw-bolder fs-6 text-gray-800">
                    <a
                        *ngIf="hyperLinkCategories"
                        (click)="closeModal()"
                        routerLink="/directory/{{ categoryName() }}/{{ user[userColumnSetting()?.originalName] }}">
                        <dir-txt [text]="user[userColumnSetting()?.originalName]" [clipboardEnabled]="true"></dir-txt>
                    </a>
                    <dir-txt
                        *ngIf="!hyperLinkCategories"
                        [text]="user[userColumnSetting()?.originalName]"
                        [clipboardEnabled]="true"></dir-txt>
                </div>
            </div>
            <span *ngIf="!isCategory() && user" class="fw-bolder fs-6 text-gray-800">
                <dir-txt [text]="user[userColumnSetting()?.originalName]" [clipboardEnabled]="true"></dir-txt>
            </span>
        </div>
    </div>
</div>
