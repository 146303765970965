import { Pipe, PipeTransform } from '@angular/core';
import { Category, CategoryItem, ColumnDefinition, ColumnType } from '../../data/categories';

@Pipe({
    name: 'categoryItemField',
    standalone: true
})
export class CategoryItemFieldPipe implements PipeTransform {
    transform(value: CategoryItem, category: Category, colDef: ColumnDefinition): unknown {
        switch (colDef.type) {
            case ColumnType.ItemCount: {
                return value.people.length;
            }
            case ColumnType.ManagerCount: {
                //return number of people who other people have as managerId
                //return value.people.filter((person) => person).length;
                return 'Manager count here';
            }
            case ColumnType.String: {
                return value[colDef.name];
            }
            case ColumnType.Link: {
                return value[colDef.name] + ' as a link here';
            }
            default:
                return value[colDef.name];
        }
    }
}
