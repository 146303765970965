import { CategoryItem } from '../data/categories';

export class FieldHelper {
    public static getManagerCount(categoryItem: CategoryItem): number {
        if (!categoryItem?.people) {
            return 0;
        }

        const managerIds = new Set(categoryItem.people.map((p) => p.managerId).filter((id) => id !== undefined));
        let managerCount = 0;

        categoryItem.people.forEach((p) => {
            if (managerIds.has(p.id)) {
                managerCount++;
            }
        });

        return managerCount;
    }
}
