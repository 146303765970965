import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    FormArray,
    FormControl,
    FormGroup,
    NonNullableFormBuilder,
    Validators,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Category, CategoryItem, ColumnType, CustomizedCategoryItem } from 'src/app/data/categories';
import * as dataActions from '../../data/data.actions';
import * as fromRoot from '../../reducers';
import * as imageActions from '../../images/image.actions';
import { EditedImageInfo } from '../../settings/settings.reducer';
import { settingsFeature } from '../../settings/settings.reducer';
import { CategoryImageComponent } from './category-image.component';
import { NgIf, NgFor } from '@angular/common';
import { EditorFieldComponent } from './editor-field.component';

@Component({
    selector: 'dir-category-item-editor-modal',
    templateUrl: './category-item-editor-modal.component.html',
    styleUrls: ['./category-item-editor-modal.component.scss'],
    imports: [CategoryImageComponent, NgIf, FormsModule, ReactiveFormsModule, NgFor, EditorFieldComponent]
})
export class CategoryItemEditorModalComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    category: Category;
    categoryItem: CustomizedCategoryItem;
    editedCategoryItem: CategoryItem;
    savedOffices$: Observable<[]>;
    imageSrc = null;
    patternUrl = null;
    defaultImageSrc = 'assets/img/cities/default.svg';
    backgroundSize: 'cover' | 'contain' | '' = '';
    file: File = null;
    isSavingFromDialog = false;

    editedImageInfo: EditedImageInfo = null;

    form = this.fb.group<ICategoryItemEditor>({
        name: this.fb.control('', [Validators.required]),
        description: this.fb.control(''),
        fields: this.fb.array<FormGroup<ICustomField>>([])
    });

    constructor(
        private store: Store,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: NonNullableFormBuilder
    ) {
        this.store
            .pipe(select(fromRoot.selectIsSavingFromDialog), takeUntil(this.unsubscribe$))
            .subscribe((isSavingFromDialog) => {
                if (this.isSavingFromDialog && !isSavingFromDialog) {
                    this.closeModal();
                    if (this.router.url.toLowerCase().startsWith(`/directory/${this.category.slug}/`)) {
                        this.router.navigate([`/directory/${this.category.slug}`, this.form.value.name]);
                    }
                }
                this.isSavingFromDialog = isSavingFromDialog;
            });
    }

    ngOnInit(): void {
        this.editedCategoryItem = { ...this.categoryItem, people: null };
        this.imageSrc = this.editedCategoryItem.pictureUrl;
        this.patternUrl = this.editedCategoryItem.patternUrl;
        this.formFromCategoryItem();

        this.store
            .pipe(select(settingsFeature.selectEditedImage), takeUntil(this.unsubscribe$))
            .subscribe((imageInfo) => {
                this.editedImageInfo = imageInfo;
            });
        //empty image
        this.store.dispatch(imageActions.editedImageInformation({ editedImage: null }));
    }

    private categoryItemFromForm(): CustomizedCategoryItem {
        const categoryItem = { ...this.categoryItem, columnValues: { ...this.categoryItem.columnValues } };
        categoryItem.columnValues = categoryItem.columnValues ?? {};
        this.form.controls.fields.value.forEach((field) => {
            categoryItem.columnValues[field.name] = field.value;
        });
        categoryItem.name = this.form.controls.name.value;
        categoryItem.description = this.form.controls.description.value;

        if (this.editedImageInfo) {
            if (this.editedImageInfo.isImage || this.editedImageInfo.image?.id) {
                categoryItem.pictureUrl = this.editedImageInfo.image.id;
                categoryItem.pattern = '';
            } else {
                categoryItem.pictureUrl = '';
                categoryItem.pattern = this.editedImageInfo.pattern;
            }
        }

        categoryItem.patternUrl = null; // this.patternUrl;

        return categoryItem;
    }

    private formFromCategoryItem() {
        this.form.controls.name.setValue(this.editedCategoryItem.name);
        this.form.controls.description.setValue(this.editedCategoryItem.description);
        this.category.columnDefinitions?.forEach((colDef) => {
            this.form.controls.fields.push(
                this.fb.group<ICustomField>({
                    name: this.fb.control(colDef.name),
                    value: this.fb.control(this.categoryItem.columnValues?.[colDef.name] ?? ''),
                    type: this.fb.control(colDef.type)
                })
            );
        });
    }

    onSave() {
        if (this.isSavingFromDialog) {
            return;
        }

        if (this.editedImageInfo && this.editedImageInfo.isImage) {
            this.store.dispatch(imageActions.saveImage({ image: this.editedImageInfo.image }));
        }

        this.store.dispatch(dataActions.saveCategoryItem({ categoryItem: this.categoryItemFromForm() }));
    }

    closeModal() {
        this.store.dispatch(dataActions.dialogHide());
    }

    ngOnDestroy(): void {
        this.router.navigate([], {
            queryParams: {
                userId: null
            },
            queryParamsHandling: 'merge'
        });
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

export interface ICategoryItemEditor {
    fields: FormArray<FormGroup<ICustomField>>;
    name: FormControl<string>;
    description: FormControl<string>;
}

export interface ICustomField {
    name: FormControl<string>;
    type: FormControl<ColumnType>;
    value: FormControl<string>;
}
