<div class="d-flex flex-column flex-root" id="kt_app_root">
    <!--begin::Authentication - Signup Welcome Message -->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Content-->
        <div class="d-flex flex-row-fluid flex-column flex-column-fluid text-center p-10 py-lg-20">
            <!--begin::Logo-->
            <a href="../dist/index.html" class="pt-lg-20 mb-12">
                <img alt="Logo" src="assets/img/logo96x96.svg" class="logo-img" />
            </a>
            <!--end::Logo-->
            <!--begin::Logo-->
            <h1 class="fw-bold fs-2qx text-gray-800 mb-7" *ngIf="gotConsent">Consent granted!</h1>
            <h1 class="fw-bold fs-2qx text-gray-800 mb-7" *ngIf="consentDenied">Consent has been denied</h1>
            <!--end::Logo-->
            <!--begin::Message-->
            <div class="fw-semibold fs-3 text-muted mb-15" *ngIf="consentDenied">
                In order to work with your data TeamDirectory requires admin consent to read your Azure Active
                Directory.
            </div>
            <!--end::Message-->
            <!--begin::Action-->
            <div class="text-center">
                <button
                    *ngIf="consentDenied"
                    (click)="goBackToConsentPage()"
                    class="btn btn-lg btn-primary fw-bold me-2">
                    Go back to consent page
                </button>
                <button *ngIf="gotConsent" (click)="goToDirectory()" class="btn btn-lg btn-success fw-bold me-2">
                    Start using directory
                </button>
            </div>
            <!--end::Action-->
        </div>
        <!--end::Content-->
        <!--begin::Illustration-->
        <div
            class="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-lg-350px"
            style="background-image: url(assets/media/illustrations/sketchy-1/7.png)"></div>
        <!--end::Illustration-->
    </div>
    <!--end::Authentication - Signup Welcome Message-->
</div>
