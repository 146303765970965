<dir-category-toolbar
    (sortFieldChange)="setSortField.emit({ category, sortField: $event })"
    (sortOrderChange)="setSortOrder.emit({ category, sortOrder: $event })"
    (viewModeChange)="setViewMode.emit({ category, viewMode: $event })"
    [viewMode]="viewMode"
    [category]="category"
    [sortField]="sortField"
    [sortOrder]="sortOrder ?? category?.defaultSortOrder"></dir-category-toolbar>
<!--begin::Row-->
<div *ngIf="viewMode === viewModes.Cards" class="row g-6 g-xl-9">
    <!--begin::Col-->
    <div *ngFor="let item of categoryItems" class="col-md-6 col-xxl-4">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card body-->
            <div class="card-body d-flex flex-center flex-column pt-12 p-9 align-content-center">
                <a
                    [routerLink]="item.name"
                    class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 d-flex flex-column align-items-center">
                    <dir-category-image
                        [imageId]="item.pictureUrl"
                        [pattern]="item.pattern"
                        [patternUrl]="item.patternUrl"></dir-category-image>
                    <span [innerHTML]="item.name"></span>
                </a>
                <!--begin::Position-->
                <div
                    class="fw-bold text-gray-400 mb-6"
                    *ngIf="item.columnValues && category.columnDefinitions?.length > 0">
                    {{ item.description }}
                </div>
                <!--end::Position-->
                <!--begin::Info-->
                <div *ngIf="category.columnSettings?.grid?.length > 0" class="d-flex flex-center flex-wrap">
                    <dir-category-tag
                        *ngFor="let columnSetting of category.columnSettings.grid"
                        [columnSetting]="columnSetting"
                        [columnDefinitions]="category.columnDefinitions"
                        [categoryItem]="item"></dir-category-tag>
                </div>
                <!--end::Info-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->
    </div>
    <!--end::Col-->
</div>
<!--end::Row-->
<div class="row">
    <div class="col">
        <div class="card card-flush" *ngIf="viewMode === viewModes.Table && category">
            <div class="card-body pt-0">
                <div class="table-responsive">
                    <table
                        *ngIf="category !== null"
                        class="table table-responsive table-row-bordered table-row-dashed gy-4 align-middle fw-bolder no-footer">
                        <thead class="fs-7 text-gray-400 text-uppercase">
                            <tr>
                                <th class="px-0"></th>
                                <th class="min-w-250px sorting">Name</th>
                                <th class="min-w-250px sorting" *ngFor="let colDef of category.columnSettings?.table">
                                    {{ colDef.name }}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="fs-6">
                            <tr *ngFor="let item of categoryItems">
                                <td class="px-0 category-image">
                                    <dir-category-image
                                        [size]="'S'"
                                        [imageId]="item.pictureUrl"
                                        [pattern]="item.patternUrl"
                                        [patternUrl]="item.patternUrl"></dir-category-image>
                                </td>
                                <td class="align-baseline">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex flex-column justify-content-center">
                                            <a [routerLink]="item.name" class="mb-1 text-gray-800 text-hover-primary">
                                                {{ item.name }}
                                            </a>
                                            <div class="fw-bold fs-6 text-gray-400">
                                                {{ item.description }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td *ngFor="let columnSetting of category.columnSettings?.table" class="align-baseline">
                                    <span
                                        markdown
                                        *ngIf="getColumnType(columnSetting) === columnType.String"
                                        [inline]="true"
                                        [data]="
                                            item.columnValues ? item.columnValues[columnSetting.originalName] : ''
                                        "></span>
                                    <span *ngIf="getColumnType(columnSetting) === columnType.ItemCount">
                                        {{ item.people ? item.people.length : 0 }}
                                    </span>
                                    <span *ngIf="getColumnType(columnSetting) === columnType.ManagerCount">
                                        {{ getManagerCount(item) }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!--begin::Pagination-->
<div class="d-flex flex-stack flex-wrap pt-10">
    <pagination
        [totalItems]="[].length"
        [customPreviousTemplate]="prevTemplate"
        [customNextTemplate]="nextTemplate"></pagination>
    <ng-template #prevTemplate>
        <li class="page-item previous">
            <i class="previous"></i>
        </li>
    </ng-template>
    <ng-template #nextTemplate>
        <li class="page-item next">
            <i class="next"></i>
        </li>
    </ng-template>
</div>
<!--end::Pagination-->
