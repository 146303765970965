<div *ngIf="!isInline" class="modal-header">
    <div class="card-title d-flex">
        <h2>TeamDirectory Subscription Information</h2>
    </div>
    <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col" [ngClass]="{ 'fs-5': isInline }">
                <h1>Enterprise subscription</h1>
                <div *ngIf="hostedInTOC" class="text-danger">
                    <p>
                        TeamDirectory is licensed independently from TeamOrgChart. You are viewing the licensing options
                        for TeamDirectory only.
                    </p>
                    <p>
                        Use the
                        <span class="border border-primary rounded p-1 fw-bold">Switch Directory</span>
                        button on TeamOrgChart to revert to the standard TeamOrgChart directory.
                    </p>
                </div>
                <p>
                    The Enterprise subscription is a comprehensive plan, providing access to all users within your M365
                    tenant.
                </p>
                <ul class="mb-2">
                    <li>
                        <span class="d-none d-md-inline">
                            <strong class="m-lg-2">Full Organization Coverage:</strong>
                        </span>
                        Allows every user within your tenant to use all of TeamDirectory's features.
                    </li>
                    <li>
                        <span class="d-none d-md-inline"><strong class="m-lg-2">Supporting Apps:</strong></span>
                        Includes the TeamDirectory app for Microsoft Teams, Office, and Outlook.
                    </li>
                    <li>
                        <span class="d-none d-md-inline">
                            <strong class="m-lg-2">SharePointOnline Integration:</strong>
                        </span>
                        Host your directory using the TeamDirectory web part for SharePoint Online.
                    </li>
                    <li>
                        <span class="d-none d-md-inline"><strong class="m-lg-2">No Extra Costs:</strong></span>
                        There are no additional costs involved.
                    </li>
                </ul>
            </div>
        </div>
        <div class="row pb-5">
            <div class="col d-flex justify-content-center">
                <div class="btn-group" role="group" aria-label="Billing period switch">
                    <button
                        type="button"
                        class="btn"
                        [ngClass]="{
                            'btn-light-primary': billingPeriod === 'yearly',
                            'btn-primary': billingPeriod === 'monthly'
                        }"
                        (click)="billingPeriod = 'monthly'">
                        Monthly
                    </button>
                    <button
                        type="button"
                        class="btn"
                        [ngClass]="{
                            'btn-light-primary': billingPeriod === 'monthly',
                            'btn-primary': billingPeriod === 'yearly'
                        }"
                        (click)="billingPeriod = 'yearly'">
                        Yearly
                        <span
                            class="position-absolute top-0 start-100 translate-middle badge badge-circle badge-success">
                            %
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 d-flex pb-4" *ngFor="let plan of plans">
                <div class="card shadow-sm flex-grow-1">
                    <div class="card-body d-flex flex-column justify-content-between">
                        <h5 class="card-title">{{ plan.size }}</h5>
                        <div class="d-flex flex-column" [ngClass]="{ 'fs-5': isInline }">
                            <li class="d-flex align-items-center py-2">
                                <span class="bullet bg-primary me-5"></span>
                                {{ plan.description }}
                            </li>
                            <li class="d-flex align-items-center py-2">
                                <span class="bullet bg-primary me-5"></span>
                                Works for every user in your company
                            </li>
                        </div>
                        <div>
                            <div class="pricing-price text-center mt-10 d-flex justify-content-center text-accent-2">
                                <div class="currency text-reset fs-2 lh-1 m-0 pt-2 pe-0">$</div>
                                <div
                                    class="value text-reset fs-1 large-heading lh-1 m-0 fw-bold"
                                    *ngIf="billingPeriod === 'yearly'">
                                    {{ plan.yearlyMonthDollars }}
                                </div>
                                <div
                                    class="value text-reset fs-5 lh-1 m-0 fw-bold pt-2"
                                    *ngIf="billingPeriod === 'yearly'">
                                    {{ plan.yearlyMonthCents }}
                                </div>
                                <div
                                    class="value text-reset fs-1 large-heading lh-1 m-0 fw-bold"
                                    *ngIf="billingPeriod === 'monthly'">
                                    {{ plan.monthly }}
                                </div>
                                <div class="period fs-2 fw-medium align-self-end lh-1 pb-2">/mo</div>
                            </div>
                            <div
                                [ngClass]="{ invisible: billingPeriod === 'monthly' }"
                                class="d-flex justify-content-center py-4 fs-5">
                                billed annually at
                            </div>
                            <div
                                [ngClass]="{ invisible: billingPeriod === 'monthly' }"
                                class="pricing-price text-center mt-1 d-flex justify-content-center text-accent-2">
                                <div class="currency text-reset fs-6 lh-1 m-0 pt-half pe-0">$</div>
                                <div class="value text-reset fs-1 lh-1 m-0 fw-bold">{{ plan.yearly }}</div>
                                <div class="period fs-6 fw-medium align-self-end lh-1 pb-half">/y</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-center mb-8">
                            <button
                                role="button"
                                class="btn btn-success"
                                (click)="onBuy(billingPeriod === 'monthly' ? plan.codeMonthly : plan.codeYearly)">
                                Purchase now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isInline" class="modal-footer">
    <button class="btn btn-light" (click)="closeModal()">Close</button>
</div>
