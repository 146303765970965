<div class="main">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-auto col-md-7 col-lg-5 col-xl-4">
                <img src="assets/img/logo96x96.svg" class="logo-img" alt="team directory logo" />
                <h1>Login failed</h1>
                <div id="error" class="alert-danger alert mt-4">
                    We could not log you in.
                    <a routerLink="/">Click here to go to the home page.</a>
                </div>
            </div>
        </div>
    </div>
</div>
