<div class="auth-bg d-flex flex-column min-vh-100">
    <div class="flex-grow-1">
        <div class="d-flex flex-column flex-row p-10 py-lg-20">
            <div class="d-flex flex-column flex-xl-row flex-column-fluid">
                <div class="d-flex flex-column flex-lg-row-fluid">
                    <div class="d-flex flex-row-fluid flex-center p-10">
                        <div class="d-flex flex-column">
                            <a href="https://team-directory.com" class="mb-15">
                                <img
                                    alt="Logo"
                                    src="assets/img/logo96x96.svg"
                                    class="logo-large"
                                    alt="teamdirectory logo" />
                            </a>
                            <h1 class="text-dark fs-3x mb-5">
                                <span *ngIf="!fromToc">Welcome to TeamDirectory!</span>
                                <span *ngIf="fromToc">Connect to TeamDirectory</span>
                            </h1>

                            <div class="fw-bold fs-3 text-gray-400 mb-10">Find People Fast</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!fromToc" class="flex-row-fluid d-flex flex-center justfiy-content-xl-first p-10">
                    <div class="d-flex flex-center p-15 bg-body shadow-sm rounded w-100 w-md-550px mx-auto ms-xl-20">
                        <form class="form" novalidate="novalidate" id="kt_free_trial_form">
                            <div class="fv-row mb-10">
                                <h4>Sign in with your Microsoft work account.</h4>
                            </div>
                            <div class="fv-row mb-10"></div>
                            <div class="text-center pb-lg-0 pb-8">
                                <button
                                    type="button"
                                    (click)="login()"
                                    class="border border-dark btn btn-lg btn-outline-light fw-bolder">
                                    <img src="/assets/img/mslogo.png" alt="microsoft logo" />
                                    <span class="ms-1 indicator-label">Sign in with Microsoft</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="fromToc" class="container text-center flex-row px-4">
            <div class="row">
                <div class="col bg-body shadow-sm rounded mx-5">
                    <div class="fs-3 m-3">Link TeamDirectory</div>
                    <p>Sign in and link TeamDirectory with your TeamOrgChart account.</p>
                    <p>
                        You will be able to use TeamDirectory as a standalone application and as a replacement for the
                        Staff Directory feature within TeamOrgChart.
                    </p>
                    <div class="text-center pb-lg-0 pb-8 mb-5">
                        <button
                            type="button"
                            (click)="login()"
                            class="border border-dark btn btn-lg btn-outline-light fw-bolder">
                            <img src="/assets/img/mslogo.png" alt="microsoft logo" />
                            <span class="ms-1 indicator-label">Sign in with Microsoft</span>
                        </button>
                    </div>
                </div>
                <div class="col bg-body shadow-sm rounded mx-5 d-flex flex-column justify-content-between">
                    <div>
                        <div class="fs-3 m-3">Install from App Source</div>
                        <p>Install the TeamDirectory application from Microsoft App Source.</p>
                    </div>
                    <div class="text-center pb-lg-0 pb-8 mb-5">
                        <a
                            type="button"
                            href="https://appsource.microsoft.com/en/product/office/WA200005930?tab=Overview"
                            target="_blank"
                            class="border border-dark btn btn-lg btn-outline-light fw-bolder">
                            <span class="ms-1 indicator-label">Visit App Source</span>
                        </a>
                    </div>
                </div>

                <div class="col bg-body shadow-sm rounded mx-5 d-flex flex-column justify-content-between">
                    <div class="fs-3 m-3">Find out more</div>
                    <p>
                        For information on features and step-by-step instructions for configuring TeamDirectory, please
                        visit our website.
                    </p>
                    <div class="text-center pb-lg-0 pb-8 mb-5">
                        <a
                            type="button"
                            href="https://www.team-directory.com/"
                            target="_blank"
                            class="border border-dark btn btn-lg btn-outline-light fw-bolder">
                            <span class="ms-1 indicator-label">Learn More</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <dir-footer></dir-footer>
</div>
