import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { GeoPattern } from 'src/lib/geopattern';
import * as imageActions from '../../images/image.actions';
import { CategoryImage } from '../../data/data.models';
import { AppDB } from '../../services/db.service';
import { CityPicturesUrlPrefix, getPictureVariantPath } from 'src/app/data/cities';
import { ImageService } from 'src/app/services/image.service';
import { NgIf } from '@angular/common';
import { DropZoneDirective } from '../../shared/directives/drop-zone.directive';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { CityBrowserComponent } from '../city-browser.component';

@Component({
    selector: 'dir-category-image',
    templateUrl: './category-image.component.html',
    styleUrls: ['./category-image.component.scss'],
    imports: [NgIf, DropZoneDirective, TooltipDirective, CityBrowserComponent]
})
export class CategoryImageComponent implements OnInit, OnDestroy, OnChanges {
    @HostBinding('class.w-100') isLibraryMode = false;
    private unsubscribe$ = new Subject<void>();
    @Input()
    size = 'M';
    @Input()
    imageId = '';
    @Input()
    editMode = false;
    @Input()
    pattern = '';
    @Input()
    patternUrl = '';
    imageSrc = '';
    backgroundSize: 'cover' | 'contain' | '' = '';
    file: File = null;
    isSavingFromDialog = false;
    //isLibraryMode = false;

    categoryImage: CategoryImage = { id: '', imageBytes: '' };

    constructor(
        private store: Store,
        private dbContext: AppDB,
        private cdr: ChangeDetectorRef,
        private imageService: ImageService
    ) {}

    ngOnInit(): void {
        this.onGenerate();

        this.imageService.refreshedImageId$.pipe(takeUntil(this.unsubscribe$)).subscribe((refreshedImageId) => {
            if (refreshedImageId != this.imageId) {
                return;
            }
            if (this.imageId && this.imageId.length > 0) {
                if (this.imageId.startsWith('https://') || this.imageId.startsWith('http://')) {
                    this.imageSrc = this.imageId;
                } else if (this.imageId.startsWith(CityPicturesUrlPrefix)) {
                    this.imageSrc = getPictureVariantPath(this.imageId, this.size);
                } else {
                    this.dbContext
                        .getImage(this.imageId)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe((ci) => {
                            if (ci) {
                                this.imageSrc = ci.imageBytes;
                                this.categoryImage = ci;
                                this.cdr.detectChanges();
                            } else {
                                //Set patter or missing picture?
                            }
                        });
                }
            }
        });
    }

    private loadImage() {
        if (this.imageId && this.imageId.length > 0) {
            if (this.imageId.startsWith('https://') || this.imageId.startsWith('http://')) {
                this.imageSrc = this.imageId;
            } else if (this.imageId.startsWith(CityPicturesUrlPrefix)) {
                this.imageSrc = getPictureVariantPath(this.imageId, this.size);
            } else {
                this.dbContext
                    .getImage(this.imageId)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((ci) => {
                        if (ci) {
                            this.imageSrc = ci.imageBytes;
                            this.categoryImage = ci;
                            this.cdr.detectChanges();
                        } else {
                            this.store.dispatch(imageActions.loadImageFromServer({ imageId: this.imageId }));
                        }
                    });
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.imageId) {
            this.loadImage();
        }
    }

    onSelectCityImage($event: string) {
        this.isLibraryMode = false;
        if ($event == null || $event.length == 0) {
            return;
        }
        this.imageId = CityPicturesUrlPrefix + $event;
        this.loadImage();
        this.store.dispatch(
            imageActions.editedImageInformation({
                editedImage: {
                    image: {
                        id: this.imageId,
                        imageBytes: ''
                    },
                    isImage: false,
                    pattern: this.pattern
                }
            })
        );
    }

    onFileChange(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.files && target.files.length) {
            const file = target.files[0];
            this.onImageDrop(file);
        }
    }

    onImageDrop(file: File) {
        //console.log(file);
        const reader = new FileReader();
        reader.onload = (e) => {
            this.imageSrc = reader.result as string;

            if (
                !this.imageId ||
                this.imageId.length == 0 ||
                this.imageId.startsWith(CityPicturesUrlPrefix) ||
                this.imageId.startsWith('https://')
            ) {
                this.imageId = crypto.randomUUID();
            }

            const ci: CategoryImage = {
                id: this.imageId,
                imageBytes: this.imageSrc
            };

            //Store the selected image in the state incase the user decides to save the configuration change.
            this.store.dispatch(
                imageActions.editedImageInformation({
                    editedImage: {
                        image: ci,
                        isImage: true,
                        pattern: this.pattern
                    }
                })
            );
            const i = new Image();
            i.onload = () => {
                if (i.width < 300 || i.height < 200) {
                    this.backgroundSize = 'contain';
                } else {
                    this.backgroundSize = 'cover';
                }
            };
        };
        reader.readAsDataURL(file);
        this.file = file;
    }

    onClearFile() {
        this.file = null;
        this.imageSrc = '';
        if (this.imageId.length > 0) {
            this.imageId = '';
        }
        this.onGenerate();
    }

    onGenerate() {
        if (this.imageId) return;
        if (!this.pattern || this.pattern.length == 0) {
            const randomNumber: number = Math.floor(Math.random() * 10000) + 1;
            const randomString: string = randomNumber.toString();
            this.pattern = 'VVRocks-' + randomString;
        }

        if (this.patternUrl == null || this.patternUrl.length == 0) {
            this.patternUrl = new GeoPattern(this.pattern).toDataUri();
        }
        if (this.editMode) {
            this.store.dispatch(
                imageActions.editedImageInformation({
                    editedImage: {
                        image: null,
                        isImage: false,
                        pattern: this.pattern
                    }
                })
            );
        }
    }

    onGenerateNewPattern() {
        this.pattern = '';
        this.imageId = '';
        this.patternUrl = null;
        this.onGenerate();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
