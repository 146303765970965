import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as uiActions from '../ui/ui.actions';

export const MESSAGE_OUT_TDREADY = 'TDREADY';
export const MESSAGE_OUT_TDURL = 'TDURL';
export const MESSAGE_OUT_TDPIN = 'TDPIN';
export const MESSAGE_OUT_TDUNPIN = 'TDUNPIN';
export const MESSAGE_OUT_TDREVERT = 'TDREVERT';
export const MESSAGE_IN_TOCREADY = 'TOCREADY';
export const MESSAGE_IN_SPREADY = 'SPREADY';
export const MESSAGE_IN_SPEDIT = 'SPEDIT';
export const MESSAGE_IN_SPURL = 'SPURL';

@Injectable({ providedIn: 'root' })
export class FrameCommunicationService {
    constructor(private store: Store) {}

    readyToReceive(status: number) {
        FrameCommunicationService.sendMessage(`${MESSAGE_OUT_TDREADY}:${status}`);
    }

    static sendUrl(url: string) {
        FrameCommunicationService.sendMessage(MESSAGE_OUT_TDURL + ':' + url);
    }

    static sendPinRequest() {
        FrameCommunicationService.sendMessage(MESSAGE_OUT_TDPIN);
    }

    static sendUnpinRequest() {
        FrameCommunicationService.sendMessage(MESSAGE_OUT_TDUNPIN);
    }

    static sendRevert() {
        FrameCommunicationService.sendMessage(MESSAGE_OUT_TDREVERT);
    }

    //Posts message to TOC
    static sendMessage(message: string) {
        if (window && window.parent) {
            window.parent.postMessage(message, '*');
        }
    }

    /// Process message from TOC and dispatch appropriate actions.
    private receiveMessage(event: MessageEvent): void {
        if (event.data == MESSAGE_IN_TOCREADY) {
            this.store.dispatch(uiActions.hostedInTOC());
        }
        if (event.data == MESSAGE_IN_SPREADY) {
            this.store.dispatch(uiActions.hostedInSharePoint());
        }
        if (event.data == MESSAGE_IN_SPEDIT) {
            this.store.dispatch(uiActions.editModeInSharePoint());
        }
        if (event.data?.startsWith && event.data.startsWith(MESSAGE_IN_SPURL)) {
            this.store.dispatch(uiActions.setSharePointStartingUrl({ url: event.data.split(':')[1] }));
        }
    }

    Register() {
        window.addEventListener('message', this.receiveMessage.bind(this), false);
    }

    Deregister() {
        window.removeEventListener('message', this.receiveMessage.bind(this), false);
    }
}
