<!--begin::Root-->
<div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="app-page d-flex flex-column flex-column-fluid">
        <dir-header></dir-header>
        <!--begin::Wrapper-->
        <div class="app-wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <dir-aside></dir-aside>
            <!--begin::Content-->
            <!--end::Root-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Container-->
                <div class="container-fluid">
                    <div *ngFor="let task of tasks" class="form-check form-check-custom form-check-solid mb-2">
                        <input class="form-check-input" type="checkbox" [ngModel]="task.isDone" id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault" [class.text-secondary]="task.isDone">
                            {{ task.title }}
                            <span *ngIf="task.isV2">- (for V2)</span>
                        </label>
                    </div>
                </div>
                <!--end::Container-->
            </div>
            <!--end::Content-->
            <dir-footer></dir-footer>
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Page-->
</div>
<!--end::Root-->
