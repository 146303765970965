<div class="modal-header">
    <div class="card-title d-flex">
        <dir-chat [mail]="user?.mail" [userId]="user?.id" size="md" (profileClick)="closeModal()"></dir-chat>
    </div>
    <button type="button" class="btn-close d-md-none close-big" (click)="closeModal()" aria-label="Close"></button>
    <button type="button" class="btn-close d-md-block d-none" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <!-- <div class="d-none">
        <button class="btn btn-primary mb-2" [disabled]="prevId === null" (click)="onChangePerson(prevId)">Prev</button>
        <button class="btn btn-primary ms-2 mb-2" [disabled]="nextId === null" (click)="onChangePerson(nextId)">
            Next
        </button>
        <button
            class="btn btn-primary ms-2 mb-2"
            [disabled]="managerId === null || managerId === ''"
            (click)="onChangePerson(managerId)">
            Manager
        </button>
    </div> -->
    <div *ngIf="hasNotBeenFound">The user has not been found.</div>
    <div class="row d-none d-lg-flex" *ngIf="user" [@animate]="user">
        <div class="col-6 col-lg-4">
            <div class="user-avatar" [style.viewTransitionName]="viewTransitionName()">
                <dir-graph-picture class="" [directoryUser]="user" [size]="200" [classes]="'mb-3'"></dir-graph-picture>
            </div>
            <dir-user-calendar
                *ngIf="(isScheduleHidden$ | async) === false"
                [email]="user?.mail"
                size="lg"
                (dismiss)="closeModal()"></dir-user-calendar>
        </div>
        <div class="col-6 col-lg-8">
            <h1 class="mb-0 d-flex flex-row align-items-center">
                <dir-user-presence [id]="user.id" mode="square-xl"></dir-user-presence>
                <dir-txt [text]="user.displayName" [clipboardEnabled]="true"></dir-txt>
            </h1>
            <h4 class="text-gray-700 mb-8">
                <dir-txt [text]="user.jobTitle" [clipboardEnabled]="true"></dir-txt>
            </h4>
            <dir-details-field
                [user]="user"
                [manager]="manager"
                [userColumnSetting]="userColumnSetting"
                [categories]="categories"
                [hyperLinkCategories]="!isTeamsChannel"
                *ngFor="let userColumnSetting of (userColumnSettings$ | async)?.details"></dir-details-field>
            <!--<dir-user-calendar [email]="user.mail" size="lg"></dir-user-calendar>-->
        </div>
    </div>
    <div class="row d-flex d-lg-none mb-2" *ngIf="user">
        <div class="col-4 col-lg-4">
            <dir-graph-picture [directoryUser]="user" [size]="100" [classes]=""></dir-graph-picture>
        </div>
        <div class="col-8 col-lg-8">
            <h1 class="mb-0">
                {{ user.displayName }}
            </h1>
            <h4 class="text-gray-700 mb-8">
                <dir-txt [text]="user.jobTitle"></dir-txt>
            </h4>
        </div>
    </div>
    <div class="row d-flex d-lg-none" *ngIf="user">
        <div class="col">
            <dir-details-field
                [user]="user"
                [manager]="manager"
                [userColumnSetting]="userColumnSetting"
                [categories]="categories"
                [hyperLinkCategories]="!isTeamsChannel"
                *ngFor="let userColumnSetting of (userColumnSettings$ | async)?.details"></dir-details-field>
            <dir-user-calendar
                *ngIf="(isScheduleHidden$ | async) === false"
                [email]="user?.mail"
                size="lg"
                (dismiss)="closeModal()"></dir-user-calendar>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-between">
    <div class="d-block">
        <button class="btn btn-primary" [disabled]="prevId === null" (click)="onChangePerson(prevId)">Prev</button>
        <button class="btn btn-primary ms-2" [disabled]="nextId === null" (click)="onChangePerson(nextId)">Next</button>
        <button
            class="btn btn-primary ms-2"
            *ngIf="!(managerId === null || managerId === '')"
            (click)="onChangePerson(managerId)">
            Manager
        </button>
    </div>
    <button class="btn btn-warning" (click)="closeModal()">Close</button>
</div>
