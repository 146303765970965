import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil, Observable, Subscription, combineLatest } from 'rxjs';
import { SubscriptionStatus, TenantAccount } from '../auth/auth.models';
import { DirModals } from '../data/data.models';
import * as dataActions from '../data/data.actions';
import * as fromRoot from '../reducers';
import { uiFeature } from '../ui/ui.reducer';
import { DemoHelper } from '../utils/demo-helper';
import { BrowserService } from '../services/browser-service';
import { FrameCommunicationService } from '../services/frame-communication.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { NgIf, AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'dir-information-banner',
    templateUrl: './information-banner.component.html',
    styleUrls: ['./information-banner.component.scss'],
    animations: [
        trigger('slideDown', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('0.3s ease-in', style({ height: '*', opacity: 1 }))
            ])
        ])
    ],
    imports: [NgIf, RouterLink, AsyncPipe]
})
export class InformationBannerComponent implements OnInit, OnDestroy {
    @Input()
    isDemo = false;
    @Output()
    numberOfBannersShowing = new EventEmitter<number>();
    private readonly unsubscribe$ = new Subject<void>();
    tenantAccount$: Observable<TenantAccount>;
    tenantAccount: TenantAccount;
    online = true;
    isTeams = false;
    hostedInTOC = false;
    isTrial$ = this.store.pipe(select(fromRoot.selectIsTrial));
    constructor(
        private store: Store,
        private browserService: BrowserService
    ) {
        this.tenantAccount$ = this.store.pipe(select(fromRoot.selectTenantAccount));
    }

    ngOnInit(): void {
        const isTeamsOrSharePoint$ = this.store.pipe(select(fromRoot.selectIsTeamsOrSharePoint));

        combineLatest([this.tenantAccount$, isTeamsOrSharePoint$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([tenantAccount, isTeams]) => {
                if (!tenantAccount) return;
                this.tenantAccount = tenantAccount;

                const promptPurchase =
                    tenantAccount.subscriptionStatus == SubscriptionStatus.Lapsed ||
                    tenantAccount.subscriptionStatus == SubscriptionStatus.Expired;

                this.numberOfBannersShowing.emit(
                    0 + (this.showSubscriptionStatus() ? 1 : 0) + (this.isDemo ? 1 : 0) + (this.online ? 0 : 1)
                );

                this.isTeams = isTeams;
                if (promptPurchase && !isTeams) {
                    this.store.dispatch(dataActions.dialogShow({ dialog: DirModals.SubscriptionInformation }));
                }
            });

        this.browserService.isOnline$.pipe(takeUntil(this.unsubscribe$)).subscribe((online) => {
            this.online = online;
        });

        this.store
            .select(uiFeature.selectHostedInTOC)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((hostedInTOC) => {
                this.hostedInTOC = hostedInTOC;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    showSubscriptionStatus(): boolean {
        return this.tenantAccount && this.tenantAccount.subscriptionStatus !== SubscriptionStatus.Subscribed;
    }

    onInformation() {
        if (this.isTeams) {
            const url = `${window.location.origin}/pricing`;
            const openWindow = () => window.open(url, '_blank', 'noreferrer');
            openWindow();
        } else {
            this.store.dispatch(dataActions.dialogShow({ dialog: DirModals.SubscriptionInformation }));
        }
    }

    onDemo() {
        DemoHelper.ActivateDemoMode();
    }

    offDemo() {
        DemoHelper.DeactivateDemoMode();
    }

    onRevertTOCDirectory(): boolean {
        FrameCommunicationService.sendRevert();
        return false;
    }

    updateOnlineStatus() {
        this.online = navigator.onLine;
    }
}
