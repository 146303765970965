import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators'; // fancy pipe-able operators
import { Observable } from 'rxjs';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // return next.handle(request).pipe(
        //     tap({
        //         next: (response) => console.log('Oh boy we got an answer'),
        //         error: (error) => console.log('Something might be burning back there')
        //     })
        // );
        const startTime: Date = new Date();
        console.log(`Request for ${request.url} started at ${startTime.toISOString()}`);
        return next.handle(request).pipe(
            tap((response: HttpResponse<unknown>): void => {
                console.log(
                    `Request for ${request.url} completed at ${new Date().toISOString()}, it took ${new Date().getTime() - startTime.getTime()}ms`
                );
                //console.log('Request took ' + );
            })
        );
    }
}
