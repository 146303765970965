import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { filter, Subject } from 'rxjs';
import * as AuthActions from './auth.actions';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from '../shared/components/footer.component';

@Component({
    selector: 'dir-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    imports: [NgIf, FormsModule, FooterComponent]
})
export class SignupComponent implements OnInit {
    private readonly unsubscribe$ = new Subject<void>();
    isIframe = false;
    loginDisplay = false;
    successUrl = '/directory';
    fromToc = false;
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalBroadcastService: MsalBroadcastService,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private authService: MsalService
    ) {}

    ngOnInit(): void {
        this.route.url.subscribe((url) => {
            console.log('url', url);
            if (url[0]?.path?.toLowerCase() === 'fromtoc') {
                this.fromToc = true;
                this.successUrl = '/welcometoc';
            }
        });
        this.authService.initialize().subscribe(() => {
            this.route.queryParams.subscribe((params) => {
                const autoLogin = params['login'];
                if (autoLogin) {
                    this.login();
                }
            });
        });

        this.msalBroadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
            .subscribe((result: EventMessage) => {
                this.router.navigate([this.successUrl]);
            });
    }

    login() {
        if (this.successUrl === '/welcometoc') {
            this.store.dispatch(AuthActions.msalLogin({ redirectStartPage: this.successUrl }));
        } else {
            this.store.dispatch(AuthActions.msalLogin({}));
        }
    }
}
