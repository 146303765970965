import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'dir-login-failed',
    templateUrl: './login-failed.component.html',
    styleUrls: ['./login-failed.component.scss'],
    imports: [RouterLink]
})
export class LoginFailedComponent {}
